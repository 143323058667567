import styled from 'styled-components';

import { ToggleButton } from './Availability.style';
import { below, above } from '../../styles/libs';

export const PitchToggleButton = styled(ToggleButton)`
  &:first-child {
    margin-top: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100px;
  gap: 1rem;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  select {
    margin-left: 5px;
    padding-right: 3rem;
  }
  ${({ theme }) => below(theme.TABLET)`
    flex-direction: column;
    align-items: flex-start;

    select {
      margin-left: 0;
    }
  `}
`;

export const PitchPrice = styled.div`
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25rem;
  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 0 0 auto;
    min-width: 0;
  `}
`;

export const Price = styled.div`
  font-size: 0.875rem;
  margin-right: 1rem;

  b {
    font-size: 0.9375rem;
  }
`;
