import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import { format } from 'date-fns';

import { dictionaryItem } from '../../hocs/withDictionary';

import SearchSummary from './SearchSummary';
import SearchButton from '../Search/SearchButton';

import {
  B, Col, Row, Summary, Text, Title,
} from './SearchSummary.style';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import IbePropTypes from '../../IbePropTypes';

import { DATE_FORMAT_DISPLAY, TIME_FORMAT_MILITARY } from '../../config/locale';

function parsePartyMembers(partyMembers, partyMembersTypes) {
  const partyMembersString = partyMembersTypes.map((type) => {
    const amount = partyMembers.filter(member => member.type === type.key).length;
    if (amount) {
      return `${type.value} x ${amount} `;
    }
    return null;
  });

  return partyMembersString.filter(v => v);
}

function parseOutfit(outfit, vehicleTypes, towTypes) {
  const vehicle = vehicleTypes.find(type => outfit.vehicleType === type.key);
  const tow = towTypes.find(type => outfit.towType === type.key);
  const outfitString = `${vehicle.value} ${tow.value === 'None' ? '' : ` & ${tow.value}`}`;
  return outfitString;
}

function SearchSummaryCrossings({
  isReturn,
  loading,
  onChangeSearch,
  query,
  data,
  sticky,
}) {
  if (data.loading) return null;

  return (
    <SearchSummary
      isCrossings
      renderSummary={
        <Summary>
          <Title dictionary={dictionaryItem('SearchSummary')} tag={2} size={4} />
          <Row>
            <Col>
              <div>
                <Text dictionary={dictionaryItem('SearchSummaryCrossings', 'OutboundRoute')} />
                {' '}
                <B>
                  {query.outboundItinerary.departurePortName} to{' '}
                  {query.outboundItinerary.arrivalPortName}
                </B>
              </div>
              {isReturn &&
                <div>
                  <Text dictionary={dictionaryItem('SearchSummaryCrossings', 'ReturnRoute')} />
                  {' '}
                  <B>
                    {query.inboundItinerary.departurePortName} to{' '}
                    {query.inboundItinerary.arrivalPortName}
                  </B>
                </div>
              }
            </Col>
            <Col>
              <div>
                <Text dictionary={dictionaryItem('SearchSummaryCrossings', 'OutboundDate')} />
                {' '}
                <B>
                  {format(query.outboundItinerary.crossingDateTime, DATE_FORMAT_DISPLAY)}
                  {', '}
                  {format(query.outboundItinerary.crossingDateTime, TIME_FORMAT_MILITARY)}
                </B>
              </div>

              {isReturn &&
                <div>
                  <Text dictionary={dictionaryItem('SearchSummaryCrossings', 'ReturnDate')} />
                  {' '}
                  <B>
                    {format(query.inboundItinerary.crossingDateTime, DATE_FORMAT_DISPLAY)}
                    {', '}
                    {format(query.inboundItinerary.crossingDateTime, TIME_FORMAT_MILITARY)}
                  </B>
                </div>
              }
            </Col>
            <Col>
              <div>
                <Text dictionary={dictionaryItem('SearchSummaryCrossings', 'Party')} />
                {' '}
                <B>
                  {parsePartyMembers(query.partyMembers, data.configuration.partyMemberTypes)}
                </B>
              </div>
              <div>
                <Text dictionary={dictionaryItem('SearchSummaryCrossings', 'Outfit')} />
                {' '}
                <B>
                  {parseOutfit(
                    query.outfit,
                    data.configuration.vehicleTypes,
                    data.configuration.towTypes,
                  )}
                </B>
              </div>

            </Col>
          </Row>
        </Summary>
      }
      renderActions={
        <SearchButton
          disabled={loading}
          onClick={onChangeSearch}
        />
      }
      sticky={sticky}
    />
  );
}

SearchSummaryCrossings.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    configuration: PropTypes.shape(IbePropTypes.configuration),
  }),
  isReturn: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onChangeSearch: PropTypes.func,
  query: PropTypes.shape({
    inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
    outfit: PropTypes.shape(IbePropTypes.outfit),
  }),
  sticky: PropTypes.bool,
};

SearchSummaryCrossings.defaultProps = {
  data: {
    loading: false,
    configuration: {},
  },
  isReturn: false,
  onChangeSearch() { },
  query: {},
  sticky: false,
};

export default graphql(GET_CONFIGURATION)(SearchSummaryCrossings);
