import React, { useCallback, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme } from 'styled-components';

import StyledInput from '../ui/Form/Input/Input.style';
import { InputIcon } from '../ui/Form/InputWithIcon/InputWithIcon.style';
import Icon from '../ui/Icon/Icon';
import replaceStringAt from '../../lib/replaceStringAt';
import { DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT } from '../../config/locale';
import svgError from '../../static/images/icons/ErrorFilled.svg';

import { isDateValid } from '../Guests/guestHelpers';
import { dateIsInRange } from '../../lib/dates';

const InputWithDateMask = (props) => {
  const inputRef = useRef(null);
  const maskRef = useRef(null);
  // formatChars used in these positions mask="01/34/6789"
  // pre constraint for the input mask
  const formatChars = {
    0: '[0-9D]',
    1: '[0-9D]',
    3: '[0-9M]',
    4: '[0-9M]',
    6: '[2Y]',
    7: '[0Y]',
    8: '[2-3Y]',
    9: '[0-9Y]',
  };

  const isDateInRange = (date) => {
    const { min, max } = props;
    if (!min || !max) return true;
    return dateIsInRange(moment(date), [min, max]);
  };

  const onBeforeMaskedValueChange = (newState, oldState, userInput, options) => {
    let { value } = newState;
    const { selection } = newState;

    // constrain the user to enter 30, and 31 for the 3rd position if they begin with 3
    if (value.charAt(0) === '3') {
      formatChars['1'] = '[0-1D]';
    } else {
      formatChars['1'] = '[0-9D]';
    }

    /**
     * Logic for dealing with users entering single day/month values.
     * 1 - Handles users entering impossible first day/month characters
     *  e.g. for days > 3 and months > 1
     * 2 - Handles users only entering a value for the second digit
     * In both cases we pad the start with 0
     */
    const dayFirstDigit = value.charAt(0);
    const daySecondDigit = value.charAt(1);
    const monthFirstDigit = value.charAt(3);
    const monthSecondDigit = value.charAt(4);

    // we want to provide a shortcut for the user to enter single digit for dates
    const firstDateValue = parseInt(dayFirstDigit, 10);
    const userHasEnteredOnlySecondDayValue = dayFirstDigit === 'D' && daySecondDigit !== 'D';
    if (firstDateValue > 3 || userHasEnteredOnlySecondDayValue) {
      if (firstDateValue > 3) {
        // if the user enters a day number which can't possibly be the first digit
        // e.g. > 3, then we pad it with a zero
        value = replaceStringAt(0, `0${dayFirstDigit}`, value);
      } else {
        // if the user has only entered the second day number we check if the user has
        // entered e.g. 'D2' and if so we replace the D with '0'
        value = replaceStringAt(0, `0${daySecondDigit}`, value);
      }
      selection.start = 3; // index for the next month position
      selection.end = 3;
    }

    // we want to provide a shortcut for the user to enter single digit for months
    const firstMonthValue = parseInt(monthFirstDigit, 10);
    const userHasEnteredOnlySecondMonthValue = monthFirstDigit === 'M' && monthSecondDigit !== 'M';
    if (firstMonthValue > 1 || userHasEnteredOnlySecondMonthValue) {
      if (firstMonthValue > 1) {
        // if the user enters a month number which can't possibly be the first digit
        // e.g. > 1, then we pad it with a zero
        value = replaceStringAt(3, `0${firstMonthValue}`, value);
      } else {
        // if the user has only entered the second month number we check if the user
        // has entered e.g. 'M2' and if so we replace the M with '0'
        value = replaceStringAt(3, `0${monthSecondDigit}`, value);
      }
      selection.start = 6; // index for the next year position
      selection.end = 6;
    }

    // Overwrite _ to D, M, Y for respective positions
    if (value.charAt(0) === '_') value = replaceStringAt(0, 'D', value);
    if (value.charAt(1) === '_') value = replaceStringAt(1, 'D', value);
    if (value.charAt(3) === '_') value = replaceStringAt(3, 'M', value);
    if (value.charAt(4) === '_') value = replaceStringAt(4, 'M', value);
    if (value.charAt(6) === '_') value = replaceStringAt(6, 'Y', value);
    if (value.charAt(7) === '_') value = replaceStringAt(7, 'Y', value);
    if (value.charAt(8) === '_') value = replaceStringAt(8, 'Y', value);
    if (value.charAt(9) === '_') value = replaceStringAt(9, 'Y', value);

    return { value, selection };
  };

  const getDateObjectFromInputRef = useCallback(() => {
    const dateString = inputRef.current.value;
    const date = moment(dateString, DATE_FORMAT_INPUT, true).format(DATE_FORMAT_DEFAULT);
    return date;
  }, []);

  const onInputMaskChange = (e) => {
    const date = getDateObjectFromInputRef();
    const { value, name } = inputRef.current;

    if (isDateValid(date)) {
      // Checks if the inputted date is within the min/max range
      const dateIsInValidRange = isDateInRange(date) ?? true;
      if (!dateIsInValidRange) return;
      // Timeout used to ensure the UI updates the input value before the onChange event is fired
      setTimeout(() => {
        props.onChange({ target: { name, value } });
      }, 50);
    }
  };

  const onInputMaskBlur = (e) => {
    const date = getDateObjectFromInputRef();
    // Checks if the inputted date is within the min/max range
    const dateIsInValidRange = isDateInRange(date) ?? true;
    // we don't allow the user to leave an invalid date string in the input
    if (!isDateValid(date) || !dateIsInValidRange) {
      maskRef.current.setInputValue(props.value);
    }
  };

  useEffect(function updateMaskOnExternalPropChange() {
    maskRef.current.setInputValue(props.value);
  }, [props.value]);

  return (
    <InputMask
      alwaysShowMask
      beforeMaskedValueChange={onBeforeMaskedValueChange}
      formatChars={formatChars}
      mask="01/34/6789"
      onChange={onInputMaskChange}
      onClick={props.onClick}
      placeholder={DATE_FORMAT_INPUT}
      defaultValue={props.value}
      error={props.error}
      onBlur={onInputMaskBlur}
      ref={maskRef}
    >
      {(inputProps) => (
        <div ref={props.containerRef}>
          <StyledInput
            ref={inputRef}
            block
            size="medium"
            id={props.id}
            name={props.name}
            data-error={props.error ? 'true' : 'false'}
            customStyles={props.inputStyle}
            {...inputProps}
          />
          {!props.icon && props.error &&
            <InputIcon>
              <Icon style={{ marginRight: '0.75rem' }} size="20px" color={props.theme.COLOR_BERRY_RED} icon={svgError} />
            </InputIcon>
          }
          {props.icon &&
            <InputIcon>
              <Icon style={{ marginRight: '0.75rem' }} size="20px" icon={props.icon} />
            </InputIcon>
          }
        </div>
      )}
    </InputMask>
  );
};

InputWithDateMask.propTypes = {
  theme: PropTypes.shape({
    COLOR_BERRY_RED: PropTypes.string,
    COLOR_WHITE: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  icon: PropTypes.string,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  inputStyle: PropTypes.shape({}),
  min: PropTypes.string,
  max: PropTypes.string,
};

InputWithDateMask.defaultProps = {
  icon: '',
  containerRef: () => { },
  inputStyle: {},
  min: '',
  max: '',
};

export default withTheme(InputWithDateMask);
