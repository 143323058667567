import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { graphql, compose } from 'react-apollo';
import { withTheme } from 'styled-components';

import { roundLength } from '../../lib/availabilityOutfitHelpers';
import formatAddress from '../../lib/formatAddress';
import formatPrice from '../../lib/formatPrice';
import format, { formatMMtoM } from '../../lib/format';
import queryHelper from '../../lib/queryHelper';
import getCampsiteType, { checkToShowPricesAndRatings } from '../../lib/campsiteTypes';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { standardPropTypes, standardDefaultProps } from '../../lib/standardProptypes';
import { checkOpen } from '../../lib/helpers/availability';

import Note from '../Note';
import Rating from '../Rating';
import CampsiteFeature from '../CampsiteFeature';
import svgTick from '../../static/images/icons/Tick.svg';
import uniqueObjectsByKey from '../../lib/uniqueObjectsByKey';

import GET_CAMPSITE_COMPARISON from './graphql/getCampsiteComparison';
import UPDATE_CURRENT_CAMPSITE from '../../config/graphql/updateCurrentCampsite';
import UPDATE_CAMPSITE_COMPARISON from './graphql/updateComparisonCampsites';
import UPDATE_COMPARE_MODULE_STATE from './graphql/updateCompareModuleState';

import {
  ComparisonWrapper,
  Header,
  Details,
  ScrollableContent,
  Row,
  RemoveBtn,
  ContentKey,
  ContentSection,
  CompareInformation,
  CampsiteDetails,
  CampsiteInfo,
  CampsiteTitle,
  Price,
  Address,
  CampsiteImage,
  LargeTick,
  Tick,
  Img,
  ViewSiteButton,
} from './CompareCampsites.style';

import withPhase from '../../hocs/withPhase';
import { getCampsiteFeatureByTag } from '../../lib/helpers/campsites';
import FEATURE_TYPES from '../../config/featureTypes';
import { DATE_FORMAT_INPUT } from '../../config/locale';
import IbePropTypes from '../../IbePropTypes';
import routes from '../../constants/routes';
import { FEATURE_FLAGS } from '../../config/featureFlags';
import CAMPSITE_STATUS from '../../constants/campsiteStatus';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';

class CompareCampsite extends Component {
  static propTypes = {
    ...standardPropTypes,
    comparisonCampsites: PropTypes.shape({
      campsites: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.compareCampsite)),
    }).isRequired,
    sitesConfig: PropTypes.shape({
      nearbyFilterableFeatures: PropTypes.arrayOf(PropTypes.string),
    }),
    theme: PropTypes.shape({}),
  }

  static defaultProps = {
    ...standardDefaultProps,
    sitesConfig: {
      nearbyFilterableFeatures: [],
    },
    theme: {},
  };

  constructor() {
    super();
    this.onViewSiteClick = this.onViewSiteClick.bind(this);
    this.displayDates = this.displayDates.bind(this);
    this.campsiteType = this.campsiteType.bind(this);
    this.removeCampsite = this.removeCampsite.bind(this);
  }

  onViewSiteClick = (campsite) => {
    this.props.updateCurrentCampsite({
      id: campsite.id,
      name: campsite.name,
      zoomTo: true,
    });

    updateRouterQuery(routes.sites, {
      campsiteId: campsite.id,
      campsiteName: campsite.name,
      activePin: campsite.id,
    });

    this.props.updateCompareModuleState(false);
  }

  displayDates = () => (
    this.props.comparisonCampsites.campsites.some(campsite => campsite.arrive && campsite.depart)
  )

  campsiteType = type => (
    getCampsiteType(this.props.sitesConfig.campsiteTypes, type)
  )

  removeCampsite = (id) => {
    const campsites = this.props.comparisonCampsites.campsites
      .filter(obj => obj.id !== id);

    this.props.updateComparisonCampsites({ campsites });
  }

  render() {
    const allFeatures = uniqueObjectsByKey(
      this.props.comparisonCampsites.campsites?.map(
        campsite => campsite.features,
      )?.flat() || [],
      'id',
    );
    return (
      <ComparisonWrapper>
        <ScrollableContent>
          <Header>
            <ContentSection>
              <CompareInformation>
                <LargeTick
                  color={this.props.theme.COLOR_TEXT_BODY}
                  icon={svgTick}
                  size="1.8em"
                />
                <strong>
                  Comparing<br />
                  {this.props.comparisonCampsites.campsites.length} sites
                </strong>
              </CompareInformation>
            </ContentSection>
            {this.props.comparisonCampsites.campsites.map((campsite) => {
              const {
                arrive,
                depart,
                selectedEvent,
                memberPrice,
              } = campsite;
              const { openDates } = selectedEvent;
              const campsiteImages = getCampsiteFeatureByTag(
                selectedEvent?.features, FEATURE_TYPES.GALLERY,
              );
              const campsiteImage = campsiteImages?.length ? campsiteImages[0] : null;
              let isOpen = true;

              if (arrive && depart && !!openDates?.length) {
                isOpen = checkOpen(openDates, arrive, depart);
              }

              const noPriceText = isOpen
                ? CAMPSITE_STATUS.FULL
                : CAMPSITE_STATUS.CLOSED;

              const PriceEl = () => (
                <Price>
                  {memberPrice ? (
                    <>
                      From:
                      {' '}
                      <strong>{formatPrice(memberPrice)}</strong>
                    </>
                  ) : (
                    <strong>{noPriceText}</strong>
                  )}
                </Price>
              );

              return (
                <ContentSection extraPadding key={campsite.id}>
                  <CampsiteDetails>
                    <CampsiteInfo>
                      <CampsiteTitle>{campsite.name}</CampsiteTitle>
                      <Address>
                        {formatAddress(campsite.address)}
                      </Address>
                    </CampsiteInfo>

                    <RemoveBtn
                      width="1.75rem"
                      height="1.75rem"
                      crossWidth="50%"
                      crossThickness="0.1rem"
                      onClick={() => { this.removeCampsite(campsite.id); }}
                    >
                      Remove
                    </RemoveBtn>
                  </CampsiteDetails>

                  {campsiteImage ? (
                    <CampsiteImage>
                      <Img url={campsiteImage.value} />
                    </CampsiteImage>
                  ) : null}
                  <Note
                    backgroundColor={this.campsiteType(campsite.type).color}
                    align="center"
                    color={this.props.theme.COLOR_WHITE}
                    text={this.campsiteType(campsite.type).text}
                  />

                  <CampsiteDetails spacingTop>
                    <Rating
                      noOfReviews={campsite.numberOfReviews}
                      rating={campsite.rating}
                      size="0.625rem"
                    />
                    {checkToShowPricesAndRatings(campsite?.type) &&
                      <PriceEl />
                    }
                  </CampsiteDetails>
                </ContentSection>
              );
            })}
          </Header>

          {!!this.props.comparisonCampsites.campsites.length && (
            <Details>
              {this.displayDates() && (
                <Row>
                  <ContentKey>
                    Holiday Dates
                  </ContentKey>
                  {this.props.comparisonCampsites.campsites.map(campsite => (
                    <ContentSection key={campsite.id}>
                      {campsite.arrive && campsite.depart && (
                        <Fragment>
                          Arrive: {format(campsite.arrive, DATE_FORMAT_INPUT)} /
                          Depart: {format(campsite.depart, DATE_FORMAT_INPUT)}
                        </Fragment>
                      )}
                    </ContentSection>
                  ))}
                </Row>
              )}

              <Row sectionTitle>
                <ContentSection fullWidth>
                  ON SITE
                </ContentSection>
              </Row>

              <Row>
                <ContentKey>
                  Max Outfit Length
                </ContentKey>
                {this.props.comparisonCampsites.campsites.map(campsite => (
                  <ContentSection key={campsite.id}>
                    {formatMMtoM(roundLength(campsite.selectedEvent?.maxOutfitLength))} Metres
                  </ContentSection>
                ))}
              </Row>

              {this.props.sitesConfig.onSiteFilterableFeatures
                .filter(filterableFeature => !!allFeatures.find(
                  featureItem => featureItem.id === filterableFeature,
                ))
                .map(feature => (
                  <Row key={`on-site-${feature}`}>
                    <ContentKey>
                      <CampsiteFeature
                        hasIcon
                        id={feature}
                        {...feature}
                        iconUrl={allFeatures.find(
                          (featureItem) => featureItem.id === feature,
                        )?.iconUrl}
                      />
                    </ContentKey>
                    {this.props.comparisonCampsites.campsites.map(campsite => (
                      <ContentSection key={campsite.id}>
                        {getCampsiteFeatureByTag(
                          campsite.features, FEATURE_TYPES.FACILITIES_ON_SITE,
                        )
                          .find(f => f.id === feature) &&
                          <Tick
                            color={this.props.theme.COLOR_TEXT_BODY}
                            icon={svgTick}
                            size="1.125em"
                          />
                        }
                      </ContentSection>
                    ))}
                  </Row>
                ))}

              <Row sectionTitle>
                <ContentSection fullWidth>
                  NEARBY
                </ContentSection>
              </Row>

              {this.props.sitesConfig.nearbyFilterableFeatures
                .filter(filterableFeature => !!allFeatures.find(
                  featureItem => featureItem.id === filterableFeature,
                ))
                .map(feature => (
                  <Row key={`nearby-${feature}`}>
                    <ContentKey>
                      <CampsiteFeature
                        hasIcon
                        id={feature}
                        {...feature}
                        iconUrl={allFeatures.find(
                          featureItem => featureItem.id === feature,
                        )?.iconUrl}
                      />
                    </ContentKey>
                    {this.props.comparisonCampsites.campsites.map(campsite => (
                      <ContentSection key={campsite.id}>
                        {getCampsiteFeatureByTag(campsite.features, FEATURE_TYPES.NEARBY)
                          .find(f => f.id === feature) &&
                          <Tick
                            color={this.props.theme.COLOR_TEXT_BODY}
                            icon={svgTick}
                            size="1.125em"
                          />
                        }
                      </ContentSection>
                    ))}
                  </Row>
                ))}

              <Row>
                <ContentKey />
                {this.props.comparisonCampsites.campsites.map(campsite => (
                  <ContentSection key={campsite.id}>
                    <ViewSiteButton type="button" onClick={() => this.onViewSiteClick(campsite)}>
                      View Site
                    </ViewSiteButton>
                  </ContentSection>
                ))}
              </Row>
            </Details>
          )}
        </ScrollableContent>
      </ComparisonWrapper>
    );
  }
}

export default compose(
  withRouter,
  withTheme,
  queryHelper(GET_CAMPSITE_COMPARISON),
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({ sitesConfig: data.configurationSites }),
  }),
  graphql(UPDATE_CAMPSITE_COMPARISON, {
    props: ({ mutate }) => ({
      updateComparisonCampsites: comparisonCampsites => mutate({
        variables: { comparisonCampsites },
      }),
    }),
  }),
  graphql(UPDATE_COMPARE_MODULE_STATE, {
    props: ({ mutate }) => ({
      updateCompareModuleState: active => mutate({ variables: { active } }),
    }),
  }),
  graphql(UPDATE_CURRENT_CAMPSITE, {
    props: ({ mutate }) => ({
      updateCurrentCampsite: campsiteInfo => mutate({ variables: campsiteInfo }),
    }),
  }),
)(withPhase(CompareCampsite, FEATURE_FLAGS.SHOW_PITCH_TYPES));
