import React from 'react';

import { RichText } from '../../ui';

import { dictionaryItem } from '../../../hocs/withDictionary';

const Membership = () => (
  <>
    <RichText dictionary={dictionaryItem('AvailabilityMemberNonMember', 'Modal')} />
  </>
);

export default Membership;
