import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { compose } from 'recompose';

import { Text } from '../ui';
import { GuestPriceContainer } from './Guests.style';
import { LoadingSpinner } from '../ui/Loading';

import IbePropTypes from '../../IbePropTypes';

import useGuestPrice from './hooks/useGuestPrice';

import { dictionaryItem } from '../../hocs/withDictionary';
import formatPrice from '../../lib/formatPrice';
import { MessageWarning } from '../ui/Message';
import { FormGroupItem } from '../SearchForm/SearchForm.style';

const GuestPrice = ({
  client,
  guestPrice,
  setGuestPrice,
  isValidForm,
  priceQueryVariables,
}) => {
  const { getPrices, loading, error } = useGuestPrice(
    priceQueryVariables,
    client,
    (price) => setGuestPrice(price),
  );

  const stringifiedQuery = JSON.stringify(priceQueryVariables);

  useEffect(() => {
    setGuestPrice(null);
    if (isValidForm) {
      // Fetch updated prices
      getPrices();
    }
  }, [stringifiedQuery, isValidForm]);

  return (
    <>
      <FormGroupItem flexEnd>
        <GuestPriceContainer>
          <Text dictionary={dictionaryItem('Guests', 'SingleCost')} weight="bold" />
          {loading
            ? <LoadingSpinner inline size="0.5rem" />
            : <Text>{formatPrice(guestPrice ?? 0)}</Text>
          }
        </GuestPriceContainer>
      </FormGroupItem>
      {error &&
        <MessageWarning marginBottom dictionary={dictionaryItem('Guests', 'PriceError')} />
      }
    </>
  );
};

export default compose(memo, withApollo)(GuestPrice);

GuestPrice.propTypes = {
  client: PropTypes.shape(IbePropTypes.client),
  guestPrice: PropTypes.number,
  setGuestPrice: PropTypes.func,
  isValidForm: PropTypes.bool,
  priceQueryVariables: PropTypes.shape({}),
};

GuestPrice.defaultProps = {
  client: {},
  guestPrice: 0,
  setGuestPrice: () => {},
  isValidForm: false,
  priceQueryVariables: {},
};
