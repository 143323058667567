import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button as SvgButton, Icon } from '../ui';
import theme from '../../styles/config/theme';

import {
  ListButtons,
  ListData,
  ListRow,
  RowDivider,
} from './Guests.style';
import { DATE_FORMAT_PART_STAY_GUEST } from '../../config/locale';
import formatPrice from '../../lib/formatPrice';
import IbePropTypes from '../../IbePropTypes';

import binSvg from '../../static/images/icons/bin.svg';
import pencilSvg from '../../static/images/icons/Pencil.svg';
import { guestListBreakdown } from './guestHelpers';
import { ADULT, CHILD, INFANT } from '../../lib/partyMemberTypes';
import GuestCount from './GuestCount';

const GuestListItem = ({
  group,
  deleteGroup,
  editGroup,
  index,
}) => {
  const {
    id,
    start,
    end,
    price,
    guests,
  } = group;
  const partyBreakdown = guestListBreakdown(guests);
  return (
    <ListRow align="center" key={`group-${id}`}>
      <ListData>{moment(start).format(DATE_FORMAT_PART_STAY_GUEST)}</ListData>
      <ListData>{moment(end).format(DATE_FORMAT_PART_STAY_GUEST)}</ListData>
      <ListData>
        <GuestCount
          guestList={partyBreakdown[ADULT]}
          label="Guests__Adults"
        />
        <GuestCount
          guestList={partyBreakdown[CHILD]}
          label="Guests__Children"
        />
        <GuestCount
          guestList={partyBreakdown[INFANT]}
          label="Guests__Infants"
        />
      </ListData>
      <ListData>{price || price === 0 ? formatPrice(price) : '--'}</ListData>
      <ListData>
        <ListButtons>
          <SvgButton
            ariaLabel="Edit"
            color={theme.COLOR_GRAY_DARK}
            size="small"
            onClick={() => editGroup(id)}
            noPadding
          >
            <Icon size="1.5rem" icon={pencilSvg} />
          </SvgButton>
          <SvgButton
            ariaLabel="Remove"
            color={theme.COLOR_BERRY_RED}
            size="small"
            onClick={() => deleteGroup(group)}
            noPadding
          >
            <Icon size="1.5rem" icon={binSvg} />
          </SvgButton>
        </ListButtons>
      </ListData>
      {index > 0 && <RowDivider />}
    </ListRow>
  );
};

GuestListItem.propTypes = {
  group: PropTypes.shape(IbePropTypes.partyStayGuestsGroup).isRequired,
  deleteGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default GuestListItem;
