import React, { useRef, useState } from 'react';

import Title from '../ui/Title';
import Text from '../ui/Text';
import RichText from '../ui/RichText';
import PopOver from '../PopOver';
import svgInfo from '../../static/images/icons/Info.svg';

import ButtonInfo from '../ui/Button/ButtonInfo.style';
import { TitleContainer } from './AvailabilityAddGuests.style';

import { dictionaryItem } from '../../hocs/withDictionary';

export default function AvailabilityAddGuests() {
  const [open, setOpen] = useState(false);
  const popOverRef = useRef(null);

  return (
    <PopOver
      closeOnClick
      onClose={() => setOpen(false)}
      open={open}
      popOverRef={popOverRef}
      renderContent={() => (
        <>
          <RichText
            dictionary={dictionaryItem(
              'Guests',
              'Tooltip',
            )}
            compact
          />
        </>
      )}
      renderReference={({ innerRef }) => (
        <>
          <TitleContainer>
            <Title
              marginBottom
              tag={1}
              size={4}
              dictionary={dictionaryItem('Guests')}
            />
            <ButtonInfo
              ariaLabel="Information"
              disabled={false}
              icon={svgInfo}
              marginLeft="0.2rem"
              noPadding
              onClick={() => setOpen(!open)}
              outerRef={innerRef}
              type="button"
              iconSize="24px" // per designs, pending move to modal
            />
          </TitleContainer>
          <Text dictionary={dictionaryItem('Guests__Description')} />
        </>
      )}
    />
  );
}
