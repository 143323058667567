import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalContent, ModalClose } from '../../ui/Modal';
import { RichText, Title } from '../../ui';
import { dictionaryItem } from '../../../hocs/withDictionary';
import { PriceSummaryTextGroup } from '../AvailabilityDetailsCostSummary.style';
import { JOURNEY_TYPES } from '../../../config/products';

const RetainedDepositModal = ({ active, journeyKey, toggleModal }) => (
  <Modal active={active} onClickOutside={toggleModal} overlay>
    <ModalContent>
      <ModalClose onClick={toggleModal} />
      <PriceSummaryTextGroup>
        <Title size={1} dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'RetainedDeposit', journeyKey)} />
        <RichText dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'RetainedDeposit', 'LongDescription', journeyKey)} />
      </PriceSummaryTextGroup>
    </ModalContent>
  </Modal>
);

RetainedDepositModal.propTypes = {
  active: PropTypes.bool,
  journeyKey: PropTypes.string,
  toggleModal: PropTypes.func,
};

RetainedDepositModal.defaultProps = {
  active: false,
  journeyKey: JOURNEY_TYPES.UK,
  toggleModal: () => {},
};

export default RetainedDepositModal;
