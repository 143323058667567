import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Actions } from './SearchSummary.style';
import { SearchText } from '../Search/CampsiteSearchSummary.style';
import { dictionaryItem } from '../../hocs/withDictionary';

function SearchSummary({
  defaultMinimize,
  isOverseas,
  isCrossings,
  ...props
}) {
  return (
    <>
      {props.renderSummary}
      {props.renderActions &&
        <Actions>
          {props.renderActions}
        </Actions>
      }
      {!isCrossings &&
        <SearchText
          dictionary={dictionaryItem(
            'CampsiteSearchSummary',
            'PostSearch',
            isOverseas ? 'BasedOnOverseas' : 'BasedOnUk',
          )}
          marginTop
        />
      }
    </>
  );
}

SearchSummary.propTypes = {
  defaultMinimize: PropTypes.bool,
  renderActions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  renderSummary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isOverseas: PropTypes.bool,
  isCrossings: PropTypes.bool,
};

SearchSummary.defaultProps = {
  defaultMinimize: false,
  isOverseas: false,
  isCrossings: false,
};

export default memo(SearchSummary);
