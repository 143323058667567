import React from 'react';

import { RichText, Text } from '../../ui';
import { InfoModalTitle } from '../InfoIconWithModal.style';

import { dictionaryItem } from '../../../hocs/withDictionary';

const PartyPitchDates = () => (
  <>
    <InfoModalTitle
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__PartyPitchDates__Party',
      )}
      size={1}
    />
    <Text
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__PartyPitchDates__Party',
      )}
    />
    <InfoModalTitle
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__PartyPitchDates__Pitch',
      )}
      size={1}
    />
    <RichText
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__PartyPitchDates__Pitch',
      )}
    />
    <InfoModalTitle
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__PartyPitchDates__Dates',
      )}
      size={1}
    />
    <Text
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__PartyPitchDates__Dates',
      )}
    />
  </>
);

export default PartyPitchDates;
