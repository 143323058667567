import React from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';

import {
  ListBody,
  ListHeader,
  ListHeaderWrapper,
  ListWrapper,
  ListTHead,
  ListTBody,
  ListRow,
} from './Guests.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

import GuestListItem from './GuestListItem';

function GuestList({
  groups, deleteGroup, editGroup,
}) {
  return (
    <ListWrapper>
      <ListBody>
        <ListTHead>
          <ListRow>
            <ListHeaderWrapper>
              <ListHeader
                dictionary={dictionaryItem('GuestsTable', 'Arrive')}
                weight="bold"
              />
            </ListHeaderWrapper>
            <ListHeaderWrapper>
              <ListHeader
                dictionary={dictionaryItem('GuestsTable', 'Depart')}
                weight="bold"
              />
            </ListHeaderWrapper>
            <ListHeaderWrapper>
              <ListHeader
                dictionary={dictionaryItem('GuestsTable', 'Guest')}
                weight="bold"
              />
            </ListHeaderWrapper>
            <ListHeaderWrapper>
              <ListHeader
                dictionary={dictionaryItem('GuestsTable', 'Price')}
                weight="bold"
              />
            </ListHeaderWrapper>
            <ListHeaderWrapper />
          </ListRow>
        </ListTHead>
        <ListTBody>
          {groups.map((group, index) => (
            <GuestListItem
              group={group}
              deleteGroup={deleteGroup}
              editGroup={editGroup}
              index={index}
              key={`guestListItem-${group.id}`}
            />
          ))}
        </ListTBody>
      </ListBody>
    </ListWrapper>
  );
}

GuestList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyStayGuestsGroup)),
  deleteGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
};

GuestList.defaultProps = {
  groups: [],
};

export default compose(
  withApollo,
)(GuestList);
