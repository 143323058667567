import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'next/router';

import updateRouterQuery from '../../../lib/updateRouterQuery';
import { encodeAndParseQuery, handleRoute, handleSameReturnRoute } from '../../Search/SearchCrossings';

import withGoogle from '../../../hocs/withGoogle';

import Map from '../Map';
import { Markers, RouteLines } from '.';

import MapStyled from '../Map.style';

import GET_CONFIGURATION from '../../../config/graphql/getConfiguration';
import GET_CROSSINGS_CONFIG from '../../../config/graphql/getCrossingsConfig';
import { returnPortName } from '../../../lib/helpers/crossings';
import IbePropTypes from '../../../IbePropTypes';
import Routes from '../../../constants/routes';

function isActiveRoute(crossingRoute, prevCrossingRoute) {
  return crossingRoute && crossingRoute === prevCrossingRoute;
}

function getPorts(ports, crossings) {
  return ports.filter(port => (
    crossings.some(({ arrivalPort, departurePort }) => (
      port.portCodes.indexOf(arrivalPort) > -1
      || port.portCodes.indexOf(departurePort) > -1
    ))
  ));
}

/**
 * Returns an active route with route lines as a priority
 */
function getActiveRoutes(ports, routes, ...args) {
  return args.map(({ arrivalPort, departurePort }) => {
    const arrivalPortName = returnPortName(ports, arrivalPort);
    const departurePortName = returnPortName(ports, departurePort);

    const routeWithRouteLine = routes.find((route) => {
      const routeArrivalPortName = returnPortName(ports, route.arrivalPort);
      const routeDeparturePortName = returnPortName(ports, route.departurePort);

      return (
        (arrivalPortName === routeArrivalPortName &&
          departurePortName === routeDeparturePortName &&
          route.routeLine &&
          route.routeLine.length) ||
        (departurePortName === routeArrivalPortName &&
          arrivalPortName === routeDeparturePortName &&
          route.routeLine &&
          route.routeLine.length)
      );
    });

    if (routeWithRouteLine) return routeWithRouteLine;

    return routes.find((route) => {
      const routeArrivalPortName = returnPortName(ports, route.arrivalPort);
      const routeDeparturePortName = returnPortName(ports, route.departurePort);

      return (
        (arrivalPortName === routeArrivalPortName &&
          departurePortName === routeDeparturePortName) ||
        (departurePortName === routeArrivalPortName &&
          arrivalPortName === routeDeparturePortName)
      );
    });
  }).filter(route => !!route);
}

const MAP_PROPS = {
  defaultCenter: {
    lat: 54.559322,
    lng: -4.174804,
  },
};

class MapCrossings extends Component {
  static defaultProps = {
    className: '',
    data: {
      loading: false,
      configuration: {
        ports: [],
        routes: [],
      },
    },
    query: {
      inboundItinerary: null,
      outboundItinerary: null,
    },
    selectedCrossing: {},
    scriptReady: false,
    theme: {},
    zoom: 8,
  };

  static propTypes = {
    className: PropTypes.string,
    crossingsConfig: PropTypes.shape(IbePropTypes.crossingsConfigType).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      configuration: PropTypes.shape(IbePropTypes.configuration),
    }),
    onQueryChange: PropTypes.func.isRequired,
    onQueryChangeByMap: PropTypes.func.isRequired,
    onZoomChanged: PropTypes.func.isRequired,
    query: PropTypes.shape({
      inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      allowAlternativeRoutes: PropTypes.bool,
    }),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    scriptReady: PropTypes.bool,
    selectedCrossing: PropTypes.shape({
      zoom: PropTypes.number,
    }),
    theme: PropTypes.shape({}),
    zoom: PropTypes.number,
  };

  state = {
    showMarker: false,
    map: null,
  }

  async componentDidUpdate(prevProps) {
    if (!this.state.map) return;

    const prevQuery = prevProps.query;
    const { inboundItinerary, outboundItinerary } = this.props.query;

    const outboundIsActive = isActiveRoute(
      outboundItinerary.routeCode,
      prevQuery.outboundItinerary.routeCode,
    );

    // Only zoom to path on outbound crossing route code change
    if (!outboundItinerary.routeCode || outboundIsActive) {
      return;
    }

    const crossings = [inboundItinerary, outboundItinerary].filter(
      itinerary => !!itinerary && !!itinerary.routeCode,
    );

    const bounds = this.handleCrossingBounds(crossings);

    if (!bounds) return;

    await this.state.map.fitBounds(bounds);

    // Zoom out a step to add some padding so points aren't off-screen
    await this.state.map.setZoom(this.state.map.getZoom() - 1);
  }

  handleCrossingBounds = (crossings = []) => {
    const ports = getPorts(this.props.data.configuration.ports, crossings);

    if (!ports.length) return null;

    const bounds = new window.google.maps.LatLngBounds();

    ports.forEach(({ lat, lon }) => {
      if (!lat || !lon) return;
      bounds.extend({ lat, lng: lon });
    });

    return bounds;
  };

  handleMapClick = () => {
    this.setState({ showMarker: false });
    // Reset map query
    this.props.onQueryChangeByMap({});
  };

  handleMarkerClick = async (portName) => {
    const { inboundItinerary, outboundItinerary } = this.props.query;

    const payload = {
      ...this.props.query,
      inboundItinerary: {
        crossingDateTime: inboundItinerary.crossingDateTime,
      },
      outboundItinerary: {
        crossingDateTime: outboundItinerary.crossingDateTime,
        departurePortName: portName,
      },
    };

    const encodedPayload = encodeAndParseQuery(this.props.query);
    const encodedPayloadNew = encodeAndParseQuery(payload);

    if (this.props.router.query.showResults) {
      await updateRouterQuery(Routes.crossings, { ...encodedPayload, showResults: false });
    }

    this.setState({ showMarker: true });
    this.props.onQueryChangeByMap(encodedPayloadNew);
  }

  handleRouteClick = (route) => {
    const { inboundItinerary, outboundItinerary, allowAlternativeRoutes } = this.props.query;

    const payload = {
      ...this.props.query,
      inboundItinerary: {
        crossingDateTime: inboundItinerary.crossingDateTime,
      },
      outboundItinerary: handleRoute(
        outboundItinerary,
        route,
        allowAlternativeRoutes,
      ),
    };

    if (payload.sameReturnRoute) {
      payload.inboundItinerary = handleSameReturnRoute(
        this.props.crossingsConfig.routes,
        payload.inboundItinerary,
        route,
      );
    }

    const encodedPayload = encodeAndParseQuery(payload);
    this.props.onQueryChangeByMap(encodedPayload);

    this.setState({ showMarker: false });

    if (!this.props.router.query.showResults) return;

    updateRouterQuery(Routes.crossings, { ...encodedPayload, showResults: false });
  }

  handleMounted = (map) => {
    if (!map) return;
    this.setState({
      map,
    });
  };

  handleZoomChanged = (zoom) => {
    const roundedZoom = Math.round(zoom);
    this.props.onZoomChanged(roundedZoom);
  }

  render() {
    const isReady =
      !this.props.data.loading &&
      this.props.scriptReady &&
      this.props.query.outboundItinerary !== null;

    if (!isReady) return null;

    const {
      crossingsConfig,
      data,
      query: {
        inboundItinerary,
        outboundItinerary,
      },
    } = this.props;

    const { showMarker } = this.state;

    const crossings = [outboundItinerary, inboundItinerary].filter(
      itinerary => !!itinerary && !!itinerary.routeCode,
    );

    const activePorts = {
      outbound: {
        portCode: outboundItinerary.departurePort,
        portName: outboundItinerary.departurePortName,
        zone: outboundItinerary.departurePortZone,
      },
      inbound: inboundItinerary ? {
        portCode: inboundItinerary.departurePort,
        portName: inboundItinerary.departurePortName,
        zone: inboundItinerary.departurePortZone,
      } : {},
    };

    const activeRoutes = getActiveRoutes(
      this.props.data.configuration.ports,
      crossingsConfig.routes,
      ...crossings,
    );

    return (
      <MapStyled className={this.props.className}>
        <Map
          {...MAP_PROPS}
          onClick={this.handleMapClick}
          query={this.props.router.query}
          onMounted={this.handleMounted}
          onZoomChanged={this.handleZoomChanged}
          zoom={this.props.zoom}
        >
          <Markers
            activePort={activePorts.outbound}
            ports={data.configuration.ports}
            onClick={this.handleMarkerClick}
            showMarker={!!showMarker}
          />
          <RouteLines
            activePorts={activePorts}
            activeRoutes={activeRoutes}
            onClick={this.handleRouteClick}
            ports={data.configuration.ports}
            routes={crossingsConfig.routes}
          />
        </Map>
      </MapStyled>
    );
  }
}

export default compose(
  graphql(GET_CONFIGURATION),
  graphql(GET_CROSSINGS_CONFIG, {
    props: ({ data }) => ({
      crossingsConfig: data.configurationCrossings || {},
    }),
  }),
  withGoogle,
  withRouter,
  withTheme,
)(MapCrossings);
