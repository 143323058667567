import React, { useEffect, useState } from 'react';
import { ButtonBordered } from '../ui/Button';

import GuestsForm from './GuestForm';
import GuestList from './GuestList';
import GuestTotalPrice from './GuestTotalPrice';

import { dictionaryItem } from '../../hocs/withDictionary';

import { usePartStayGuestContext } from './hooks/useGuestContext';
import useGroupManager, { GROUP_ACTIONS } from './hooks/useGroupManager';

import { dataLayerManager } from '../../lib/dataLayer';
import { getErrorElement } from '../../lib/helpers/availability';
import { focusElementAtStart } from '../../lib/dom';

function Guests() {
  // The ID of the group currently being amended/edited
  const [editingGroup, setEditingGroup] = useState(null);
  // Whether we're adding a new guest, tracks if the form is open
  const [isAdding, setIsAdding] = useState(false);

  const {
    guests,
    maxLength,
    payload,
    minDate,
    maxDate,
  } = usePartStayGuestContext();

  // This hook handles the management of guest groups (adding, updating, deleting)
  // as well as keeping the quote in sync
  const [guestGroups, dispatchEvent] = useGroupManager();

  const handleGroupsUpdate = (event) => {
    dispatchEvent(event);
    dataLayerManager.pushPartStayGuests(
      payload,
      event.data,
      event.type,
    );
  };

  /**
   * Used to get DOM refs to inputs we require to be valid before
   * opening the guests section.
   * @returns {HTMLElement}
   */
  const getAvailabilityErrorElement = () => {
    let element;
    if (!minDate) element = getErrorElement({ attribute: 'data-start-date-wrapper' });
    if (!maxDate || minDate === maxDate) element = getErrorElement({ attribute: 'data-end-date-wrapper' });
    return element?.querySelector?.('input') ?? null;
  };

  const openForm = () => {
    /**
     * We require the user to have selected both a main stay arrival and departure
     * date in order to set min/max in the datepickers in this section. Here we
     * get the applicable element if there's no min or max date or they are the same
     * we don't open this section and send the user back up to the calendar.
     */
    const errorElement = getAvailabilityErrorElement();
    if (errorElement) {
      focusElementAtStart(errorElement);
      return;
    }
    setIsAdding(true);
  };

  const closeForm = () => {
    setIsAdding(false);
    setEditingGroup(null);
  };

  const onAddGroup = (group) => {
    handleGroupsUpdate({
      type: GROUP_ACTIONS.ADD,
      data: group,
    });
    setIsAdding(false);
  };

  const onAmendGroup = (updatedGroup) => {
    handleGroupsUpdate({
      type: GROUP_ACTIONS.UPDATE,
      data: updatedGroup,
    });
    setEditingGroup(null);
  };

  const onDeleteGroup = (group) => {
    handleGroupsUpdate({
      type: GROUP_ACTIONS.REMOVE,
      data: group,
    });
    closeForm();
  };

  const onDeleteAllGroups = () => {
    handleGroupsUpdate({
      type: GROUP_ACTIONS.REMOVE_ALL,
    });
  };

  const onEditGroup = (groupId) => {
    setEditingGroup(groupId);
  };

  useEffect(function userHasUpdatedMainDates() {
    // If the user updates the main stay dates, clear
    // all groups and close the form as the new dates
    // may be invalid for the added guests
    if (guestGroups?.length) {
      onDeleteAllGroups();
    }
    closeForm();
  }, [minDate, maxDate]);

  const groupToEdit =
    guestGroups.find((group) => group.id === editingGroup) ?? {};

  // If we are editing a group it shouldn't be included in the group list
  const groupsWithoutEditing = guestGroups.filter(
    (group) => group.id !== editingGroup,
  );

  // Need to dynamically work out the max number of guests we can add
  // to limit the party select so we can't go over the max number
  const currentGuests = guestGroups.map((group) => group.guests).flat();
  const currentGuestCount = currentGuests.length;
  const maxGuestsRemaining = maxLength - currentGuestCount;
  const hasReachedMaxGuests = maxGuestsRemaining === 0;

  // Logic for deciding which sections/buttons to show and/or disable
  const showGuestForm = isAdding || !!editingGroup;
  const showAddGuestsButton = !showGuestForm && guestGroups.length === 0;
  const showAddMoreGuestsSection = !showGuestForm && guestGroups.length > 0;
  const addGuestsButtonDisabled = !!isAdding || guests.length >= maxLength;

  const totalPrice = guestGroups.reduce(
    (acc, currentGroup) => acc + currentGroup.price,
    0,
  );

  return (
    <>
      {showAddGuestsButton && (
        <ButtonBordered
          disabled={addGuestsButtonDisabled}
          onClick={openForm}
          block
          dictionary={dictionaryItem('Guests', 'AddGuest')}
          marginTop="2rem"
        />
      )}
      {showGuestForm && (
        <GuestsForm
          onCancel={closeForm}
          onAddGroup={onAddGroup}
          isAdding={isAdding}
          groupToEdit={groupToEdit}
          onAmendGroup={onAmendGroup}
          maxGuestsRemaining={maxGuestsRemaining}
        />
      )}
      {groupsWithoutEditing.length > 0 && (
        <GuestList
          groups={groupsWithoutEditing}
          deleteGroup={onDeleteGroup}
          editGroup={onEditGroup}
        />
      )}
      {showAddMoreGuestsSection && (
        <>
          <ButtonBordered
            onClick={() => setIsAdding(true)}
            block
            dictionary={dictionaryItem('Guests', 'AddMoreGuests')}
            marginTop="2rem"
            disabled={hasReachedMaxGuests}
          />
          <GuestTotalPrice totalPrice={totalPrice} />
        </>
      )}
    </>
  );
}

export default Guests;
