import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../ui';
import { GuestErrorText } from './Guests.style';

import { dictionaryItem } from '../../hocs/withDictionary';

const GuestError = ({ dictionary }) => (
  <GuestErrorText>
    <Text dictionary={dictionaryItem(dictionary)} />
  </GuestErrorText>
);

GuestError.propTypes = {
  dictionary: PropTypes.string,
};

GuestError.defaultProps = {
  dictionary: '',
};

export default GuestError;
