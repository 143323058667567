import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';

export const GET_ALL_FEATURES = GET_SITES_CONFIG;

class CampsiteFeatureQuery extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }

  static defaultProps = {
    name: '',
  }

  shouldComponentUpdate(nextProps) {
    return this.props.id !== nextProps.id || this.props.name !== nextProps.name;
  }

  render() {
    const { children, id } = this.props;

    return (
      <Query query={GET_ALL_FEATURES}>
        {({ data: { configuration }, error, loading }) => {
          if (error || loading) return null;
          if (!configuration) return null;
          const feature =
            configuration.features.find(campsiteFeature => campsiteFeature.id === id) ||
            { id };

          return children({ ...feature });
        }}
      </Query>
    );
  }
}

export default CampsiteFeatureQuery;
