import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { isValid } from 'date-fns';
import PropTypes from 'prop-types';

import Label from '../ui/Form/Label';
import { ButtonBordered } from '../ui/Button';
import theme from '../../styles/config/theme';
import binSvg from '../../static/images/icons/bin.svg';
import svgCalendar from '../../static/images/icons/Calendar.svg';

import { PartySelect } from '../PartyForm';
import GuestPrice from './GuestPrice';
import GuestError from './GuestError';
import PartyDateOfBirths from '../PartyForm/PartyDateOfBirths';
import {
  Datepicker,
  DateInputWrapper,
} from '../SearchForm/Campsites/CampsitesDatePickerWrapper.style';
import { CancelButton, ButtonGroup } from './Guests.style';
import { FormGroup, FormGroupItem } from '../SearchForm/SearchForm.style';

import { DATE_FORMAT_INPUT } from '../../config/locale';
import { dictionaryItem } from '../../hocs/withDictionary';
import { CHILD } from '../../lib/partyMemberTypes';
import { formatToHyphenFormat } from '../../lib/format';
import {
  getBoundingAgesByMemberType,
  getChildToAge,
  isChildDOBValid,
} from './guestHelpers';
import { getFormattedPartySelectOptions } from '../../lib/partyHelpers';
import IbePropTypes from '../../IbePropTypes';

import { usePartStayGuestContext } from './hooks/useGuestContext';
import useGroupForm from './hooks/useGroupForm';
import InputWithDateMask from '../Availability/InputWithDateMask';

function GuestForm({
  onAddGroup,
  onCancel,
  groupToEdit,
  onAmendGroup,
  maxGuestsRemaining,
}) {
  const {
    configuration,
    maxDate,
    minDate,
    payload,
    pitchConfig,
    isOverseas,
    product,
  } = usePartStayGuestContext();

  const {
    actions,
    formState,
    formData,
    helpers,
  } = useGroupForm(groupToEdit);

  const {
    setSelectedDates,
    setGuestPrice,
    validateForm,
    handleGuestChange,
    handleDatesChange,
    onInputDates,
    handleGuestDOB,
  } = actions;

  const {
    groupMembers,
    selectedDates,
    guestPrice,
  } = formData;

  const {
    hasValidated,
    validation,
    isValidForm,
  } = formState;

  const isEditing = !!groupToEdit.id;

  const { partyMemberTypes } = configuration;
  const stayStartRef = useRef(null);

  const childToAge = getChildToAge(pitchConfig);

  const priceVariables = {
    pitchCode: payload.pitchTypeId,
    productCode: product.productCode,
    guests: groupMembers,
    arrivalDate: selectedDates.start,
    departureDate: selectedDates.end,
  };

  // If we've already validated, update validation as the
  // form (and by extension the query) is updated
  useEffect(() => {
    if (hasValidated || isEditing) {
      validateForm();
    }
  }, [selectedDates.start, selectedDates.end, groupMembers.length, isEditing]);

  const handleAddGroup = (isValidGroup) => {
    if (!isValidGroup) {
      validateForm();
      return;
    }
    // Construct a new group to add
    const group = helpers.generateGroup();
    // Call amend function if we're editing
    if (isEditing) {
      const updatedGroup = {
        ...group,
        id: groupToEdit.id,
      };
      onAmendGroup(updatedGroup);
      return;
    }
    onAddGroup(group);
  };

  const formattedStayStart = formatToHyphenFormat(selectedDates.start);

  const formattedStayEnd = formatToHyphenFormat(selectedDates.end);

  const getDateInputValue = (dateValue) => {
    if (!dateValue || dateValue.length !== 10) {
      return selectedDates?.start;
    }
    return moment(formatToHyphenFormat(dateValue)).format(
      DATE_FORMAT_INPUT,
    );
  };

  const ageRanges = getBoundingAgesByMemberType(configuration, product.productCode);
  const childAgeRange = ageRanges[CHILD];

  const { partySelectOptions, clonedPartyMembers } = getFormattedPartySelectOptions(
    configuration,
    product,
    childAgeRange,
    groupMembers,
  );

  const addButtonLabel = isEditing ? 'EditingGuest' : 'AddToStay';

  // Form validation
  const {
    guestPickerValid,
    arrivalDateValid,
    departureDateValid,
    dobsValid,
    datesAreTheSame,
    areDatesDefined,
  } = validation;

  const hasPrice = guestPrice !== null;

  const addIsDisabled = !(isValidForm && hasPrice);

  const showGuestPickerValidation = hasValidated && !guestPickerValid;
  const showDatesValidationMessage = (hasValidated && (!arrivalDateValid || !departureDateValid));
  const showDatesAreEqualValidation = hasValidated && areDatesDefined && datesAreTheSame;
  const showArrivalDateValidation =
    hasValidated &&
    (
      !arrivalDateValid ||
      showDatesAreEqualValidation
    );
  const showDepartureDateValidation =
    hasValidated &&
    (
      !departureDateValid ||
      showDatesAreEqualValidation
    );
  const showDobsValidation = hasValidated && !dobsValid && isOverseas;

  return (
    <>
      <Datepicker
        anchor={stayStartRef}
        closingIgnoreList={['stayStart', 'stayEnd']}
        fieldFocus=""
        startDate={
          selectedDates?.start && isValid(new Date(formattedStayStart))
            ? formattedStayStart
            : ''
        }
        endDate={
          selectedDates?.end && isValid(new Date(formattedStayEnd))
            ? formattedStayEnd
            : ''
        }
        handleClear={() => setSelectedDates({
          start: '',
          end: '',
        })}
        options={{
          dateRange: true,
          maxDate,
          minDate,
          months: 1,
          name: 'guestForm_dateRange',
        }}
        onChange={handleDatesChange}
      >
        {({ handleOpen }) => (
          <>
            <FormGroup marginLess>
              <FormGroupItem marginLess>
                <Label
                  dictionary={dictionaryItem('GuestForm', 'Party')}
                />
                <PartySelect
                  childToAge={childToAge}
                  disabled={false}
                  isOverseas={isOverseas}
                  partyMemberTypes={partyMemberTypes}
                  partyMembers={clonedPartyMembers}
                  data={partySelectOptions}
                  onChange={handleGuestChange}
                  options={{
                    max: maxGuestsRemaining,
                    min: 0,
                  }}
                  customStyled={{
                    border: `1px solid ${theme.COLOR_GRAY_DARK}`,
                  }}
                  errored={showGuestPickerValidation}
                  showEmptyParty
                />
              </FormGroupItem>
            </FormGroup>
            {showGuestPickerValidation &&
              <GuestError
                dictionary="Guests__Form__Guests__Error"
              />
            }
            <FormGroup marginLess responsive>
              <FormGroupItem marginLess>
                <Label
                  dictionary={dictionaryItem('GuestForm', 'Arrive')}
                />
                <DateInputWrapper>
                  <InputWithDateMask
                    ariaLabel="Arrive date"
                    name="start"
                    id="start"
                    value={getDateInputValue(selectedDates?.start)}
                    onClick={handleOpen}
                    onChange={onInputDates}
                    error={showArrivalDateValidation}
                    icon={svgCalendar}
                    containerRef={stayStartRef}
                    min={minDate}
                    max={maxDate}
                    inputStyle={{
                      border: `1px solid ${showArrivalDateValidation
                        ? theme.COLOR_BERRY_RED
                        : theme.COLOR_GRAY_DARK
                      }`,
                    }}
                  />
                </DateInputWrapper>
              </FormGroupItem>
              <FormGroupItem marginLess>
                <Label
                  dictionary={dictionaryItem('GuestForm', 'Depart')}
                />
                <DateInputWrapper>
                  <InputWithDateMask
                    ariaLabel="Depart date"
                    name="end"
                    id="end"
                    value={getDateInputValue(selectedDates?.end)}
                    onClick={handleOpen}
                    onChange={onInputDates}
                    error={showDepartureDateValidation}
                    icon={svgCalendar}
                    containerRef={stayStartRef}
                    min={minDate}
                    max={maxDate}
                    inputStyle={{
                      border: `1px solid ${showDepartureDateValidation
                        ? theme.COLOR_BERRY_RED
                        : theme.COLOR_GRAY_DARK
                      }`,
                    }}
                  />
                </DateInputWrapper>
              </FormGroupItem>
            </FormGroup>
            {showDatesValidationMessage &&
              <GuestError
                dictionary="Guests__Form__Dates__Error"
              />
            }
            {showDatesAreEqualValidation &&
              <GuestError
                dictionary="Guests__Form__Dates__Equal__Error"
              />
            }
            {isOverseas &&
              <PartyDateOfBirths
                guests={clonedPartyMembers}
                onChange={handleGuestDOB}
                isDobValid={(dob) => isChildDOBValid(dob, isOverseas)}
                isOverseas={isOverseas}
                disabled={false}
                dataError={showDobsValidation}
                arrivalDate={selectedDates?.end}
                lightTheme={false}
                bordered
              />
            }
            {showDobsValidation &&
              <GuestError
                dictionary="Guests__Form__DOB__Error"
              />
            }
          </>
        )}
      </Datepicker>
      <FormGroup flexDir="column" marginLess>
        <GuestPrice
          setGuestPrice={setGuestPrice}
          guestPrice={guestPrice}
          isValidForm={isValidForm}
          priceQueryVariables={priceVariables}
        />
      </FormGroup>
      <ButtonGroup>
        <ButtonBordered
          onClick={() => handleAddGroup(!addIsDisabled)}
          block
          branded
          pseudoDisabled={addIsDisabled}
          dictionary={dictionaryItem('Guests', addButtonLabel)}
        />
        <CancelButton
          icon={binSvg}
          onClick={onCancel}
          color={theme.COLOR_WHITE}
          iconSize="20px"
        />
      </ButtonGroup>
    </>
  );
}

GuestForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  groupToEdit: PropTypes.shape(IbePropTypes.partyStayGuestsGroup),
  onAddGroup: PropTypes.func.isRequired,
  onAmendGroup: PropTypes.func.isRequired,
  maxGuestsRemaining: PropTypes.number,
};

GuestForm.defaultProps = {
  groupToEdit: {},
  maxGuestsRemaining: 10,
};

export default GuestForm;
