import React from 'react';
import styled, { css } from 'styled-components';

import InputComponent from '../ui/Form/Input';
import SelectComponent from '../ui/Form/Select';
import LabelComponent from '../ui/Form/Label';
import TitleComponent from '../ui/Title';

import { Col, Row } from '../ui/Grid';

import { above, below } from '../../styles/libs';
import detectIE from '../../lib/detectIE';

const withProps = (Component, props) => otherProps => <Component {...otherProps} {...props} />;

export default styled.div`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  ${!detectIE() && css`
    height: auto;
  `};

  label {
    color: ${({ theme }) => theme.COLOR_WHITE};
  }
`;

export const Body = styled.div`
  padding: ${({ isCrossings }) => (isCrossings ? '2.25rem 1.25rem 5rem 1.25rem;' : '2.25rem 1.25rem 0.5rem 1.25rem;')}

  ${({ theme, isCrossings }) => above(theme.TABLET)`
    padding: ${isCrossings ? '2.25rem 1.25rem 5rem 1.25rem;' : '2.25rem 1.25rem;'}
  `}

  ${({ theme }) => above(theme.DESKTOP)`
    padding: 2.75rem;
  `}
`;

export const Stick = styled.div`
  ${({ theme }) => above(theme.TABLET)`
    position: sticky;
    top: 0;
  `}
`;

export const cssInput = css`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 -0.375rem;

  ${({ marginTop }) => marginTop && css`
    flex: 1;
    margin-top: 1rem;
  `}
  ${({ marginLess }) => marginLess && css`
    margin: 0;
  `}
  ${({ responsive, theme }) => responsive && below(theme.TABLET)`
    flex-direction: column;
  `}
  ${({ fillHeight }) => fillHeight && css`
    align-items: stretch;
  `}

  ${({ flexDir }) => css`
    flex-direction: ${flexDir};
  `}
`;

export const FormGroupItem = styled.div`
  flex: 1;
  margin-bottom: 1.25rem;
  min-width: 0;
  padding: 0 0.375rem;

  ${({ center }) => center && css`
    display: flex;
    justify-content: center;
    flex: unset;
  `}

  ${({ flex }) => flex && css`
    display: flex;
    justify-content: space-between;
  `}

  ${({ flexEnd }) => flexEnd && css`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  `}

  ${({ fillHeight }) => fillHeight && css`
    align-items: stretch;
  `}

  ${({ flex, theme }) => flex && below(theme.MOBILE_LARGE)`
    flex-wrap: wrap;
  `}

  ${({ marginLess }) => marginLess && css`
    margin: 0;
  `}

  ${({ theme, hideOnMobile }) => hideOnMobile && below(theme.TABLET)`
    display: none;
  `}

  ${({ theme, isBookingWidget }) => isBookingWidget && above(theme.MOBILE_LARGE)`
    flex: initial;
  `}

  ${({ responsive, theme }) => responsive && below(theme.TABLET)`
    flex: 0 0 100%;
  `}
`;

export const Input = styled(InputComponent)`
  ${cssInput}
`;

export const Select = styled(SelectComponent)`
  ${cssInput}
`;

export const Title = styled(TitleComponent)`
  color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Label = styled(LabelComponent)`
  color: ${({ theme }) => theme.COLOR_WHITE};
  padding: 0 0 0.5rem;

  ${({ noPadding }) => noPadding && css`
    padding: 0;
  `}
`;

export const SearchFormCol = styled(withProps(Col, { size: 'small' }))`
  flex: 0 0 100%;

  ${({ theme }) => above(theme.TABLET)`
    flex: 1;
  `}
`;

export const SearchFormRow = styled(withProps(Row, { size: 'small' }))``;
