import React from 'react';

import { Text } from '../../ui';
import { InfoModalTitle } from '../InfoIconWithModal.style';

import { dictionaryItem } from '../../../hocs/withDictionary';

const Outfit = () => (
  <>
    <InfoModalTitle
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__Outfit__VehicleType',
      )}
      size={1}
    />
    <Text
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__Outfit__VehicleType',
      )}
    />
    <InfoModalTitle
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__Outfit__Length',
      )}
      size={1}
    />
    <Text
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__Outfit__Length',
      )}
    />
    <InfoModalTitle
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__Outfit__Towing',
      )}
      size={1}
    />
    <Text
      dictionary={dictionaryItem(
        'AvailabilityInfoModal__Outfit__Towing',
      )}
    />
  </>
);

export default Outfit;
