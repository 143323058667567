import React from 'react';
import PropTypes from 'prop-types';

import { GuestTotalPriceContainer, PriceContainer } from './GuestDetails.style';
import { Text } from '../ui';

import { dictionaryItem } from '../../hocs/withDictionary';
import { formatPriceWithCurrency } from '../../lib/format';

const GuestTotalPrice = ({ totalPrice }) => (
  <GuestTotalPriceContainer>
    <Text weight="bold" dictionary={dictionaryItem('GuestForm', 'GuestTotal')} />
    <PriceContainer>{formatPriceWithCurrency(totalPrice)}</PriceContainer>
  </GuestTotalPriceContainer>
);

GuestTotalPrice.propTypes = {
  totalPrice: PropTypes.number,
};

GuestTotalPrice.defaultProps = {
  totalPrice: 0,
};

export default GuestTotalPrice;
