import styled, { css } from 'styled-components';
import { below } from '../../styles/libs';

export const MAP_FILTER_CONTROLS_SHADOW = '0 4px 4px rgba(0, 0, 0, 0.25)';

export const FilterControlsContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;
  bottom: 1rem;
  padding: 0 0.75rem 0 1rem;
  z-index: 1;

  ${({ theme }) => below(theme.DESKTOP)`
    flex-wrap: wrap;
  `}

  ${({ theme, resizeAdjustmentAmount }) => below(theme.TABLET)`
    bottom: 1.5rem;
    margin-bottom: ${resizeAdjustmentAmount}px;
  `}
`;

export const UseMyLocationButton = styled.button`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: ${({ theme }) => `2px solid ${theme.COLOR_CYAN_DARK}`};
  color: ${({ theme }) => theme.COLOR_CYAN_DARK};
  padding: 0.5rem;
  border-radius: 4px;
  line-height: 0;
  cursor: pointer;
  box-shadow: ${MAP_FILTER_CONTROLS_SHADOW};

  ${({ isOverseas }) => isOverseas && css`
    margin-left: auto;
    padding: 0.5rem;
  `}

  ${({ havePermission }) => !havePermission && css`
    opacity: 0.7;
  `}
`;
