import styled, { css } from 'styled-components';
import InputWithIcon from '../ui/Form/InputWithIcon/InputWithIcon';
import Text from '../ui/Text';
import { Col } from '../ui/Grid';
import { Button } from '../ui';
import { below } from '../../styles/libs';

export const Input = styled(InputWithIcon)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;
  text-transform: uppercase;

  ${({ errored, theme }) => errored && `
    border: 1px solid ${theme.COLOR_DANGER};
  `}
`;

export const ListCol = styled(Col)`
  padding: 0 0.2rem;
  margin-top: 0.5rem;
  text-align: center;
`;

export const ListHeader = styled(Text)`
  color: ${({ theme }) => theme.COLOR_CYAN_DARK};
  font-size: 0.85rem;
  word-wrap: normal;
  text-align: left;
`;

export const ListButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  ${({ theme }) => below(theme.TABLET)`
    flex-direction: column;
  `}
`;

export const ListContent = styled.div``;

export default styled.div`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
    padding-top: 1.5rem;
  }

  margin-bottom: 1.5rem;
`;

export const GroupHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const ListBody = styled.table`
    width: 100%;
    border: none;
    border-spacing: 1rem 0;
    border-radius: 0.25rem;
    font-size: 0.85rem;
`;

export const ListTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
`;

export const BrandedText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.COLOR_CYAN_DARK};
    font-weight: bold;
  `}
  ${({ marginLeft }) => marginLeft && css`
    margin-left: 1.5rem;
  `}
`;

export const CancelButton = styled(Button)`
  width: 50px;
  height: 50px;

  ${({ theme }) => css`
    background: ${theme.COLOR_ERROR_RED};
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ListTHead = styled.thead`
  text-align: center;
`;

export const ListTBody = styled.tbody`
  position: relative;
`;

export const ListHeaderWrapper = styled.td`
  padding-bottom: 0.25rem;
  text-align: left;
`;

export const ListData = styled.td`
  text-align: left;
`;

export const ListRow = styled.tr`
  text-align: center;

  &:not(:first-of-type) {
    & ${ListData} {
      padding-top: 1rem;
    }
  }
`;

export const ListWrapper = styled.div`
  margin: 1.25rem auto 2rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.COLOR_AQUA_ALPHA};

  ${({ theme }) => below(theme.TABLET)`
    margin: 2rem -2rem 0 -2rem;
    padding: 1rem 1.5rem;
  `}
`;

export const GuestErrorText = styled.div`
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  text-align: right;
  color: ${({ theme }) => theme.COLOR_DANGER};
  font-size: 0.8125rem; // 13px, per designs
`;

export const GuestPriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const RowDivider = styled.hr`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
    height: 1px;
    background-color: ${theme.COLOR_GRAY_DARK};
  `}
`;
