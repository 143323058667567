import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  getChildToAge,
  partySelectToMembersArray,
  validateChildDOBs,
  validateDate,
} from '../guestHelpers';

import { usePartStayGuestContext } from './useGuestContext';
import { parsePartySelectToPartyMembers } from '../../../lib/partyHelpers';

import * as _Types from '../../../lib/jsdocTypedefs';
import { CHILD } from '../../../lib/partyMemberTypes';
import { formatToHyphenFormat } from '../../../lib/format';

/**
 * Hook to manage the GuestForm data & validation
 * @param {_Types.GuestGroup} groupToEdit   The group we are currently editing (if any)
 * @returns {_Types.UseGroupFormReturnType}
 */
const useGroupForm = (groupToEdit) => {
  const [groupMembers, setGroupMembers] = useState(
    groupToEdit?.unsanitizedGuests ?? [],
  );
  const [selectedDates, setSelectedDates] = useState({
    start: groupToEdit?.start ?? '',
    end: groupToEdit?.end ?? '',
  });
  const [guestPrice, setGuestPrice] = useState(null);
  const [hasValidated, setHasValidated] = useState(false);
  const [validation, setValidation] = useState({
    guestPickerValid: false,
    arrivalDateValid: false,
    departureDateValid: false,
    dobsValid: false,
    datesAreTheSame: false,
    areDatesDefined: false,
  });

  const {
    isOverseas,
    minDate,
    maxDate,
    pitchConfig,
  } = usePartStayGuestContext();

  useEffect(() => {
    // Revalidate dates when they update
    const areDatesDefined = !!selectedDates.start && !!selectedDates.end;
    const datesAreTheSame = selectedDates.start === selectedDates.end;

    setValidation((prev) => ({
      ...prev,
      areDatesDefined,
      datesAreTheSame,
    }));
  }, [selectedDates.start, selectedDates.end]);

  const childToAge = getChildToAge(pitchConfig);

  const validateForm = () => {
    const {
      allChildrenHaveDOBs,
      areAllDobsValid,
    } = validateChildDOBs(groupMembers, isOverseas, childToAge);
    const areDatesDefined = !!selectedDates.start && !!selectedDates.end;
    const datesAreDefinedAndTheSame =
      areDatesDefined &&
      selectedDates.start === selectedDates.end;
    setValidation({
      guestPickerValid: !!groupMembers.length,
      arrivalDateValid: !!selectedDates.start,
      departureDateValid: !!selectedDates.end,
      dobsValid: allChildrenHaveDOBs && areAllDobsValid,
      datesAreTheSame: datesAreDefinedAndTheSame,
      areDatesDefined,
    });
    if (!hasValidated) {
      setHasValidated(true);
    }
  };

  const generateGroup = () => {
    const groupId = uuid();
    const { start, end } = selectedDates;
    const guests = partySelectToMembersArray(groupMembers);
    return {
      start: formatToHyphenFormat(start),
      end: formatToHyphenFormat(end),
      guests,
      unsanitizedGuests: groupMembers,
      price: guestPrice,
      id: groupId,
    };
  };

  const handleGuestChange = (e) => {
    // handle picker change, we get back all data here
    const members = parsePartySelectToPartyMembers(groupMembers, e, pitchConfig);
    setGroupMembers(members);
    setValidation((prev) => ({
      ...prev,
      guestPickerValid: !!members.length,
    }));
  };

  const handleDatesChange = (dates) => {
    const [stayStart, stayEnd] = dates ?? [];
    setValidation((prev) => ({
      ...prev,
      arrivalDateValid: !!stayStart,
      departureDateValid: !!stayEnd,
    }));
    setSelectedDates({
      start: stayStart || stayEnd,
      end: stayEnd || stayStart,
    });
  };

  const onInputDates = (e) => {
    const dateType = e.target.name;
    const validatedDate = validateDate(e, minDate, maxDate);

    const updatedDates = {
      ...selectedDates,
      [dateType]: validatedDate,
    };

    setSelectedDates(updatedDates);

    const validationKey = dateType === 'start'
      ? 'arrivalDateValid'
      : 'departureDateValid';

    const areDatesDefined = !!updatedDates.start && !!updatedDates.end;

    const datesAreTheSame =
      areDatesDefined &&
      updatedDates.start === updatedDates.end;

    setValidation(prev => ({
      ...prev,
      areDatesDefined,
      datesAreTheSame,
      [validationKey]: !!validatedDate,
    }));
  };

  const handleGuestDOB = (index, dateOfBirth) => {
    const updatedGuests = groupMembers.map((currentGuest, i) => {
      if (i !== index) return currentGuest;
      return {
        ...currentGuest,
        dateOfBirth,
      };
    });
    setGroupMembers(() => {
      const {
        allChildrenHaveDOBs,
        areAllDobsValid,
      } = validateChildDOBs(updatedGuests, isOverseas, childToAge);
      setValidation((prev) => ({
        ...prev,
        dobsValid: allChildrenHaveDOBs && areAllDobsValid,
      }));
      return updatedGuests;
    });
  };

  const {
    guestPickerValid,
    arrivalDateValid,
    departureDateValid,
    dobsValid,
    datesAreTheSame,
  } = validation;

  const hasChildren = groupMembers.filter((member) => member.type === CHILD).length > 0;

  const areDatesDefined = !!selectedDates.start && !!selectedDates.end;
  const areDatesValid =
    arrivalDateValid &&
    departureDateValid &&
    (areDatesDefined && !datesAreTheSame);

  const isUKFormValid = guestPickerValid && areDatesValid;
  // Make sure we only validate child DOBs if there are children present
  const isOSFormValid = guestPickerValid && areDatesValid && (hasChildren && dobsValid);

  const isValidForm = isOverseas ? isOSFormValid : isUKFormValid;

  return {
    actions: {
      setSelectedDates,
      setGuestPrice,
      validateForm,
      handleGuestChange,
      handleDatesChange,
      onInputDates,
      handleGuestDOB,
    },
    formState: {
      hasValidated,
      validation,
      isValidForm,
    },
    formData: {
      groupMembers,
      selectedDates,
      guestPrice,
    },
    helpers: {
      generateGroup,
    },
  };
};

export default useGroupForm;
