import React from 'react';
import PropTypes from 'prop-types';

import IbePropTypes from '../../IbePropTypes';
import Guests from '../Guests';
import { PartyStayGuestContext } from '../Guests/hooks/useGuestContext';

import PRODUCT_TYPES from '../../config/products';
import { getPitchConfig } from '../../lib/partyHelpers';

function AvailabilityGuests(props) {
  const {
    payload, onChange, maxLength, minChildAge, maxChildAge,
    configuration, isOverseas,
  } = props;
  const { end, guests, start } = payload;
  const handleChange = (newGuests) => {
    const newPayload = { ...payload };
    newPayload.guests = [...newGuests];
    onChange(newPayload);
  };

  if (!configuration) {
    return null;
  }

  const { products } = configuration;

  const productCode = isOverseas
    ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT
    : PRODUCT_TYPES.CAMC_UK_PRODUCT;

  const product = products.find(
    productItem => productItem.productCode === productCode,
  );

  const pitchConfig = getPitchConfig(configuration, product.productCode, minChildAge, maxChildAge);

  // Some context to prevent prop-drilling
  // throughout this whole section
  const context = {
    configuration,
    minDate: start,
    maxDate: end,
    maxLength,
    guests,
    onChange: handleChange,
    payload,
    isOverseas,
    product,
    pitchConfig,
  };

  return (
    <PartyStayGuestContext.Provider value={context}>
      <Guests />
    </PartyStayGuestContext.Provider>
  );
}

AvailabilityGuests.propTypes = {
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  minChildAge: PropTypes.number,
  maxChildAge: PropTypes.number,
  payload: PropTypes.shape(IbePropTypes.quotePayload).isRequired,
  configuration: PropTypes.shape(IbePropTypes.configuration),
  isOverseas: PropTypes.bool.isRequired,
};

AvailabilityGuests.defaultProps = {
  configuration: {},
  maxChildAge: undefined,
  minChildAge: undefined,
};

export default AvailabilityGuests;
