import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Calendar from './Calendar';
import Footer from './Footer';
import Header from './Header';
import Popper from '../../../Popper';

import withPicker from '../../hocs/withPicker';

import variables from '../../variables';

import StyledPicker, {
  PickerWrapper,
  PickerBody,
  PickerCalendars,
  PickerLoadMore,
  PickerPagination,
  PickerPaginationAction,
} from './Picker.style';

import Icon from '../../../Icon';
import svgArrowLeft from '../../../../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../../../../static/images/icons/ArrowRight.svg';
import IbePropTypes from '../../../../../IbePropTypes';

const ARROW_SIZE = '1.25em';

class Picker extends Component {
  static propTypes = {
    availabilitySearchLoading: PropTypes.bool,
    anchor: PropTypes.shape({}),
    calculateCalendarsHeight: PropTypes.func.isRequired,
    calendarsHeight: PropTypes.number,
    datepicker: PropTypes.shape(IbePropTypes.datepicker).isRequired,
    disablePrevMonth: PropTypes.bool,
    disableNextMonth: PropTypes.bool,
    endDate: PropTypes.string,
    forwardedRef: PropTypes.shape({}),
    fullscreen: PropTypes.bool.isRequired,
    handleClear: PropTypes.func,
    handleClick: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleLoadMore: PropTypes.func.isRequired,
    handleNextMonth: PropTypes.func.isRequired,
    handlePrevMonth: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    ref: PropTypes.shape({}),
    results: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    startDate: PropTypes.string,
    text: PropTypes.arrayOf(PropTypes.string),
    'data-testid': PropTypes.string,
  }

  static defaultProps = {
    availabilitySearchLoading: false,
    anchor: null,
    calendarsHeight: null,
    handleClear: null,
    endDate: '',
    forwardedRef: {},
    disablePrevMonth: false,
    disableNextMonth: false,
    ref: null,
    startDate: '',
    text: [],
    'data-testid': null,
  }

  ref = React.createRef();

  calendarsRef = React.createRef();

  render() {
    const { datepicker, endDate, startDate } = this.props;

    // // TODO: Change height dynamically
    let bodyStyle = {};
    if (!this.props.fullscreen) {
      bodyStyle = {
        height: `${this.props.calendarsHeight}px`,
        width: `${(datepicker.months * variables.calendarWidth) + variables.bodyPadding}px`,
      };
    }

    return (
      <Popper
        anchor={!this.props.fullscreen && this.props.anchor}
        lazyAnchor={!this.props.fullscreen}
        open={this.props.datepicker.open}
        placement="bottom-start"
      >
        {({ forwardedRef }) => (
          <StyledPicker
            ref={forwardedRef}
            fullscreen={this.props.fullscreen}
          >
            <PickerWrapper
              ref={this.props.forwardedRef}
            >
              {this.props.fullscreen &&
                <Header
                  datepicker={datepicker}
                  handleClear={this.props.handleClear}
                  handleClose={this.props.handleClose}
                />
              }

              <PickerBody
                style={bodyStyle}
              >
                {(
                  this.props.fullscreen &&
                  this.props.results[0] &&
                  !moment(this.props.results[0].calendarDate).isBefore(datepicker.minDate)
                ) &&
                  <PickerLoadMore
                    type="button"
                    onClick={() => this.props.handleLoadMore(-1)}
                  >
                    Load More
                  </PickerLoadMore>
                }

                {!this.props.fullscreen &&
                  <PickerPagination>
                    <PickerPaginationAction
                      type="button"
                      onClick={this.props.handlePrevMonth}
                      disabled={this.props.disablePrevMonth}
                      data-testid={
                        this.props['data-testid'] ? `${this.props['data-testid']}__prev` : null
                      }
                    >
                      <Icon
                        icon={svgArrowLeft}
                        size={ARROW_SIZE}
                      />
                    </PickerPaginationAction>
                    <PickerPaginationAction
                      type="button"
                      onClick={this.props.handleNextMonth}
                      disabled={this.props.disableNextMonth}
                      data-testid={
                        this.props['data-testid'] ? `${this.props['data-testid']}__next` : null
                      }
                    >
                      <Icon
                        icon={svgArrowRight}
                        size={ARROW_SIZE}
                      />
                    </PickerPaginationAction>
                  </PickerPagination>
                }

                <PickerCalendars
                  fullscreen={this.props.fullscreen}
                  ref={this.calendarsRef}
                >
                  {this.props.results.map((result, i) => (
                    <Calendar
                      {...result}
                      {...datepicker}
                      availabilitySearchLoading={this.props.availabilitySearchLoading}
                      calculateCalendarsHeight={this.props.calculateCalendarsHeight}
                      data-testid={`${this.props['data-testid']}-${i}`}
                      endDate={endDate}
                      fullscreen={this.props.fullscreen}
                      handleClick={this.props.handleClick}
                      index={i}
                      key={`${result.year}_${result.month}`}
                      setCalendarRefs={this.setCalendarRefs}
                      startDate={startDate}
                      text={this.props.text}
                      isSearchFormPicker
                    />
                  ))}
                </PickerCalendars>

                {this.props.fullscreen &&
                  <PickerLoadMore
                    type="button"
                    onClick={() => this.props.handleLoadMore(this.props.fullscreen ? 4 : 1)}
                  >
                    Load More
                  </PickerLoadMore>
                }
              </PickerBody>

              <Footer
                fullscreen={this.props.fullscreen}
                isAutoApply={datepicker.autoApply}
                isDateRange={datepicker.dateRange}
                handleClear={this.props.handleClear}
                handleSubmit={this.props.handleSubmit}
                data-testid={this.props['data-testid']}
              />
            </PickerWrapper>
          </StyledPicker>
        )}
      </Popper>
    );
  }
}

export default withPicker(Picker);
