import React, { useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import Title from '../ui/Title';
import RichText from '../ui/RichText';
import { Label, Select } from '../ui/Form';
import PopOver from '../PopOver';
import { ButtonInfo } from '../ui/Button';

import svgInfo from '../../static/images/icons/Info.svg';

import { TitleContainer } from './Availability.style';

import { dictionaryItem } from '../../hocs/withDictionary';
import { createArrivalTimeOptions } from '../../lib/helpers/availability';

function AvailabilityArrivalTimeForm({
  onChange, value, options, error,
}) {
  return (
    <Fragment>
      <Label
        htmlFor="availabilityArrivalTime"
        dictionary={dictionaryItem('AvailabilityArrivalTime')}
      />
      <Select
        block
        error={error}
        id="availabilityArrivalTime"
        onChange={onChange}
        type="number"
        value={value}
      >
        <option value="">Please select</option>
        {options.map(type => (
          <option key={type.key} value={type.key}>{type.value}</option>
        ))}
      </Select>
    </Fragment>
  );
}

AvailabilityArrivalTimeForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number, value: PropTypes.string,
  })).isRequired,
  error: PropTypes.bool.isRequired,
};

export default function AvailabilityArrivalTime({
  start, end, onChange, arrivalTimeErrors, value,
}) {
  const [open, setOpen] = useState(false);
  const popOverRef = useRef(null);
  // Temp
  const options = createArrivalTimeOptions(start, end);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <PopOver
      closeOnClick
      onClose={() => setOpen(false)}
      open={open}
      popOverRef={popOverRef}
      renderContent={() => (
        <>
          <RichText dictionary={dictionaryItem('AvailabilityArrivalTime')} compact />
        </>
      )}
      renderReference={({ innerRef }) => (
        <>
          <TitleContainer>
            <Title
              marginBottom
              tag={1}
              size={4}
              dictionary={dictionaryItem('AvailabilityArrivalTime')}
            />
            <ButtonInfo
              ariaLabel="Information"
              disabled={false}
              icon={svgInfo}
              marginLeft="auto"
              noPadding
              onClick={() => setOpen((prevValue) => !prevValue)}
              outerRef={innerRef}
              type="button"
              iconSize="24px" // per designs, pending move to modal
            />
          </TitleContainer>
          <AvailabilityArrivalTimeForm
            options={options}
            onChange={handleChange}
            error={arrivalTimeErrors?.length > 0}
            value={value}
          />
        </>
      )}
    />
  );
}

AvailabilityArrivalTime.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  arrivalTimeErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AvailabilityArrivalTime.defaultProps = {
  value: '',
};
