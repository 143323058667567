import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

const GuestCount = ({ guestList, label, client }) => {
  const dictionaryText = getDictionaryItem(client, label);
  if (guestList.length === 0) return <></>;
  return <div>{dictionaryText} ({guestList.length})</div>;
};

GuestCount.propTypes = {
  guestList: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  client: PropTypes.shape(IbePropTypes.client),
};

GuestCount.defaultProps = {
  guestList: [],
  label: '',
  client: {},
};

export default withApollo(GuestCount);
