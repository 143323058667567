import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { add, cloneDeep } from 'lodash';

import { Label } from '../ui/Form';
import PartyAges from './PartyAges';
import PartySelect from './PartySelect';
import PartyDateOfBirths from './PartyDateOfBirths';

import {
  validatePartyAges,
  parsePartySelectToPartyMembers,
  getPitchConfig,
} from '../../lib/partyHelpers';

import { validateDateOfBirth, guestOptions, createPartyOptions } from '../Guests/guestHelpers';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import IbePropTypes from '../../IbePropTypes';

import { dictionaryItem } from '../../hocs/withDictionary';
import { CHILD, INFANT, ADULT } from '../../lib/partyMemberTypes';
import { Flex } from '../ui/Grid/Row.style';
import InfoIconWithModal from '../Availability/InfoIconWithModal';

class PartyForm extends PureComponent {
  static propTypes = {
    arrivalDate: PropTypes.string,
    customStyled: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ])),
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    data: PropTypes.shape({
      loading: PropTypes.bool,
      configuration: PropTypes.shape(IbePropTypes.configuration),
    }),
    dataError: PropTypes.bool,
    disabled: PropTypes.bool,
    lightTheme: PropTypes.bool,
    loading: PropTypes.bool,
    max: PropTypes.number,
    maxChildAge: PropTypes.number,
    minChildAge: PropTypes.number,
    onChange: PropTypes.func,
    partyMembers: PropTypes.arrayOf(PropTypes.shape({})),
    theme: PropTypes.shape({}),
    withPortal: PropTypes.bool,
    productCode: PropTypes.number,
    isOverseas: PropTypes.bool,
    useDob: PropTypes.bool,
    countLead: PropTypes.bool,
    showInfoIcon: PropTypes.bool,
    infoModalContent: PropTypes.element,
  };

  static defaultProps = {
    arrivalDate: '',
    customStyled: '',
    data: {
      loading: false,
      configuration: null,
    },
    dataError: false,
    disabled: false,
    lightTheme: false,
    loading: false,
    max: null,
    maxChildAge: null,
    minChildAge: null,
    onChange() {},
    partyMembers: [],
    theme: {},
    withPortal: false,
    productCode: null,
    isOverseas: false,
    useDob: false,
    countLead: false,
    showInfoIcon: false,
    infoModalContent: <></>,
  };

  /**
   * Parses the PartySelect response to quote parameters format
   * @param {Object[]} data The PartySelect onChange response
   */
  handlePartyChange = (data) => {
    const { partyMembers, onChange } = this.props;
    const pitchConfig = this.getPitchConfig();

    // Update quote parameters
    onChange(parsePartySelectToPartyMembers(partyMembers, data, pitchConfig), true);
  }

  /**
   * @param {number} index
   * @param {string} age
   */
  handlePartyAgeChange = (index, age) => {
    const { onChange } = this.props;
    const partyMembers = [...this.props.partyMembers];

    partyMembers[index] = {
      ...partyMembers[index],
      age: age !== '' ? Number(age) : null,
    };

    onChange(partyMembers, true);
  }

  getPitchConfig = () => {
    const {
      data, productCode, maxChildAge, minChildAge,
    } = this.props;

    if (!data?.configuration?.products) {
      return null;
    }

    const { configuration } = data;

    const pitchConfig = getPitchConfig(configuration, productCode, minChildAge, maxChildAge);

    return pitchConfig;
  }

  getValidAge = (dob, memberType) => {
    const {
      data, isOverseas, arrivalDate,
    } = this.props;
    const pitchConfig = this.getPitchConfig();

    const partyOptions = guestOptions(data?.configuration, isOverseas);
    const { isValidAge, age } = validateDateOfBirth(
      dob, memberType ?? CHILD, partyOptions, arrivalDate, pitchConfig,
    );
    return isValidAge ? age : null;
  }

  handlePartyDobChange = (index, dob, type, setError) => {
    const { onChange } = this.props;
    const partyMembers = [...this.props.partyMembers];
    const age = this.getValidAge(dob, type ?? CHILD);

    let defaultAge = null;
    const pitchConfig = this.getPitchConfig();

    if (pitchConfig) {
      const { childFromAge, infantFromAge } = pitchConfig;
      if (type === CHILD) {
        defaultAge = childFromAge;
      }
      if (type === INFANT) {
        defaultAge = infantFromAge;
      }
    }

    partyMembers[index] = {
      ...partyMembers[index],
      dateOfBirth: dob,
      age: type === ADULT ? null : (age ?? defaultAge),
    };
    onChange(partyMembers, true);
    setError(age === null);
  }

  render() {
    const {
      arrivalDate,
      dataError,
      disabled,
      lightTheme,
      loading,
      max,
      partyMembers,
      customStyled,
      data,
      maxChildAge,
      minChildAge,
      withPortal,
      productCode,
      useDob,
      isOverseas,
      countLead,
      showInfoIcon,
      infoModalContent,
    } = this.props;

    if (loading || data.loading ||
      !data.configuration || !productCode) return null;

    const { configuration: { partyMemberTypes, products } } = data;

    const product = products.find(
      productItem => productItem.productCode === Number(productCode),
    );
    if (!product?.partyConfig) {
      return null;
    }

    const { partyConfig } = product;

    // Create a map of party member types
    const partyMemberTypesMap = new Map();
    partyMemberTypes.forEach(({ key, value }) => partyMemberTypesMap.set(key, value));

    const childToAge = maxChildAge || partyConfig.find(({ type }) => type === 1).toAge;
    const childFromAge = minChildAge ?? partyConfig.find(({ type }) => type === 1).fromAge;
    const adultFromAge = childToAge !== null ? add(childToAge, 1) : null;

    const pitchConfig = {
      childToAge, childFromAge, adultFromAge, minChildAge,
    };

    const partyOptions = createPartyOptions(partyConfig, partyMemberTypesMap, pitchConfig);

    // Create a map of each party type amount
    const valuesMap = new Map();
    const clonedPartyMembers = cloneDeep(partyMembers);
    clonedPartyMembers.forEach((member) => {
      const value = valuesMap.get(member.type) || 0;
      valuesMap.set(member.type, value + 1);
    });

    // Assign new values
    partyOptions.map((option, index) => option.setValue(valuesMap.get(index) || 0));
    const agesValid = validatePartyAges(partyMembers);
    return (
      <Fragment>
        <Flex>
          <Label dictionary={dictionaryItem('PartyForm', 'PartyMembers')} />
          {showInfoIcon && infoModalContent &&
            <InfoIconWithModal modalContent={infoModalContent} />
          }
        </Flex>
        <PartySelect
          block
          childToAge={childToAge}
          customStyled={customStyled}
          disabled={disabled}
          data={partyOptions}
          isOverseas={isOverseas}
          onChange={this.handlePartyChange}
          partyMembers={clonedPartyMembers}
          partyMemberTypes={partyMemberTypes}
          options={{
            max,
          }}
          withPortal={withPortal}
          countLead={countLead}
        />

        {useDob ? (
          <PartyDateOfBirths
            guests={clonedPartyMembers}
            onChange={this.handlePartyDobChange}
            isDobValid={this.getValidAge}
            isOverseas={isOverseas}
            disabled={disabled}
            dataError={dataError}
            arrivalDate={arrivalDate}
            lightTheme={lightTheme}
          />) : (
            <PartyAges
              disabled={disabled}
              onChange={this.handlePartyAgeChange}
              partyMembers={clonedPartyMembers}
              partyOptions={partyOptions}
              valid={agesValid}
            />)}
      </Fragment>
    );
  }
}

export default graphql(GET_CONFIGURATION)(PartyForm);
