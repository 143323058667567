import { createContext, useContext } from 'react';

import * as _Types from '../../../lib/jsdocTypedefs';

export const PartyStayGuestContext = createContext(undefined);

/**
 * Context hook to prevent prop-drilling in this area, allows us to
 * pass commonly used values around the sub-tree wherever the provider
 * is placed
 * @returns {_Types.PartStayGuestContext}
 */
export const usePartStayGuestContext = () => {
  const partStayGuest = useContext(PartyStayGuestContext);

  // Ensures this can only work when called below a
  // <PartyStayGuestContext.Provider />
  if (!partStayGuest) {
    throw new Error(
      'usePartStayGuestContext must be used within a PartStayGuestContext',
    );
  }

  return partStayGuest;
};
