import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withApollo } from 'react-apollo';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

import RichText from '../ui/RichText';
import PopOver from '../PopOver';
import svgInfo from '../../static/images/icons/Info.svg';

import ButtonInfo from '../ui/Button/ButtonInfo.style';
import {
  TitleContainer,
  ToolTipTextContainer,
} from './PartyDateOfBirth.style';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

import Label from '../ui/Form/Label';
import { MessageWarning } from '../ui/Message';
import { FormGroup, FormGroupItem } from '../SearchForm/SearchForm.style';

import {
  DATE_FORMAT_DROPDOWN,
  DATE_FORMAT_DEFAULT,
} from '../../config/locale';

import IbePropTypes from '../../IbePropTypes';
import usePrevious from '../../lib/hooks/usePrevious';

function PartyDateOfBirthForm({
  guest, label, onChange, index, client, disabled,
  setDobError, setDobTypeError, hasError, bordered,
}) {
  const dateOfBirth =
    guest?.dateOfBirth && moment(guest.dateOfBirth, DATE_FORMAT_DEFAULT);
  const [day, setDay] = useState(dateOfBirth?.date());
  const [month, setMonth] = useState(dateOfBirth?.month());
  const [year, setYear] = useState(dateOfBirth?.year());

  useEffect(() => {
    if (day && year && month !== undefined) {
      setDobError(false);
      const dateInput = moment(
        `${day}-${Number(month) + 1}-${year}`,
        DATE_FORMAT_DROPDOWN,
        true,
      );
      const formattedDateOfBirth = dateInput.format(DATE_FORMAT_DEFAULT);
      if (!dateInput.isValid()) {
        setDobError(true);
      }
      onChange(index, formattedDateOfBirth, guest?.type, setDobTypeError);
    }
  }, [year, month, day]);

  const dobLabel = getDictionaryItem(client, 'AvailabilityParty__DateOfBirth__Label');
  const now = new Date();
  const previousYear = now.getFullYear() - 1;

  const selectClass = `customSelect ${bordered ? 'bordered' : ''} ${hasError ? 'formError' : ''}`;

  return (
    <FormGroup marginLess center>
      <FormGroupItem flex marginLess>
        <Label flex><b>{label} {dobLabel}</b></Label>
        <>
          <DayPicker
            classes={selectClass}
            defaultValue="Day"
            year={year || previousYear}
            month={month || 0}
            required
            value={day}
            onChange={(newDay) => {
              setDay(newDay);
            }}
            disabled={disabled}
          />
          <MonthPicker
            classes={selectClass}
            defaultValue="Month"
            short
            year={year || previousYear}
            required
            value={month}
            onChange={(newMonth) => setMonth(newMonth)}
            disabled={disabled}
          />
          <YearPicker
            classes={selectClass}
            defaultValue="Year"
            reverse
            required
            value={year}
            onChange={(newYear) => {
              setYear(newYear);
            }}
            disabled={disabled}
          />
        </>
      </FormGroupItem>
    </FormGroup>
  );
}

PartyDateOfBirthForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  guest: PropTypes.shape(IbePropTypes.guest).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  client: PropTypes.shape(IbePropTypes.client),
  setDobError: PropTypes.func.isRequired,
  setDobTypeError: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  bordered: PropTypes.bool,
};

PartyDateOfBirthForm.defaultProps = {
  client: {},
  bordered: false,
};

function PartyDateOfBirth({
  arrivalDate,
  lightTheme,
  disabled,
  isDobValid,
  dataError,
  guest,
  label,
  onChange,
  index,
  bordered,
}) {
  const [open, setOpen] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [dobTypeError, setDobTypeError] = useState(false);
  const popOverRef = useRef(null);
  const prevArrivalDate = usePrevious(arrivalDate);

  useEffect(() => {
    if (guest?.dateOfBirth && arrivalDate && arrivalDate !== prevArrivalDate) {
      const validDob = isDobValid(guest.dateOfBirth, guest.type);
      const hasError = validDob === 0 ? false : !validDob;
      setDobTypeError(hasError);
    }
  }, [arrivalDate]);

  return (
    <PopOver
      closeOnClick
      onClose={() => setOpen(false)}
      open={open}
      popOverRef={popOverRef}
      renderContent={() => (
        <>
          <RichText dictionary={dictionaryItem('AvailabilityParty', 'DateOfBirth')} compact />
        </>
      )}
      renderReference={({ innerRef }) => (
        <>
          <TitleContainer center marginTop>
            <PartyDateOfBirthForm
              guest={guest}
              label={label}
              onChange={onChange}
              index={index}
              setDobError={setDobError}
              setDobTypeError={setDobTypeError}
              hasError={dobError || dobTypeError || dataError}
              disabled={disabled}
              bordered={bordered}
            />
            <ToolTipTextContainer hasMobileMargin>
              <ButtonInfo
                ariaLabel="Information"
                buttonStyle={{ visibility: 'visible' }}
                disabled={false}
                icon={svgInfo}
                marginTop="0.2rem"
                marginLeft="0.2rem"
                noPadding
                onClick={() => setOpen(!open)}
                outerRef={innerRef}
                type="button"
                lightTheme={lightTheme}
              />
            </ToolTipTextContainer>
          </TitleContainer>
          {dobError && <MessageWarning
            marginTop
            dictionary={dictionaryItem('GuestForm', 'DateOfBirth', 'Error')}
          />}
          {dobTypeError && <MessageWarning
            marginTop
            dictionary={dictionaryItem('GuestForm', 'DateOfBirth', 'PartyError')}
          />}
        </>
      )}
    />
  );
}

PartyDateOfBirth.propTypes = {
  arrivalDate: PropTypes.string,
  lightTheme: PropTypes.bool,
  disabled: PropTypes.bool,
  isDobValid: PropTypes.func.isRequired,
  dataError: PropTypes.bool,
  guest: PropTypes.shape(IbePropTypes.guest).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  bordered: PropTypes.bool,
};

PartyDateOfBirth.defaultProps = {
  arrivalDate: '',
  lightTheme: false,
  dataError: false,
  disabled: false,
  bordered: false,
};

export default withApollo(PartyDateOfBirth);
