import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import Router, { withRouter } from 'next/router';
import { uniqBy, cloneDeep } from 'lodash';
import { startOfDay, addDays, format } from 'date-fns';
import { v4 as uuid } from 'uuid';

import buildPath from '../../lib/helpers/restLink';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { defaults as defaultCampsite } from '../../resolvers/campsite';
import { stringifyPartyMembers } from '../../lib/helpers/party';
import { stringifyVehicleType } from '../../lib/helpers/outfits';
import {
  validateOutfitCampsite,
  validateArrivalTime,
} from '../../lib/validation/availability';
import { getTotalCost, sortPitches } from '../../lib/availability';
import { getBookableDaysFromToday } from '../../lib/campsiteTypes';
import isLoggedIn, { isLoggedInMember } from '../../lib/isLoggedIn';

import Text from '../ui/Text';
import Title from '../ui/Title';
import { MessageWarning } from '../ui/Message';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';

import { QuoteCreate } from '../Quote';
import { partyConfig, personTypes } from '../../config/personType';
import { ids, keys } from '../../config/campsiteTypes';
import { dictionaryItem } from '../../hocs/withDictionary';
import GET_PITCHES from '../Availability/graphql/getPitches.gql';
import GET_USER from '../../config/graphql/getUser';
import GET_AVAILABILITY_AND_USER_CONFIG from '../Availability/graphql/getAvailabilityAndUserConfig';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import RESET_SESSION from '../Quote/graphql/resetSession';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { isMembershipInBasket } from '../../config/quoteMembershipTypes';
import IbePropTypes from '../../IbePropTypes';
import {
  getTypeByDateOfBirth, isDateValid, guestOptions, getAge,
} from '../Guests/guestHelpers';

import {
  AvailabilityDuration,
  AvailabilityPitches,
} from '../Availability';
import { getErrorElement } from '../../lib/helpers/availability';

import AvailabilityArrivalTime from '../Availability/AvailabilityArrivalTime';
import AvailabilityOutfitDetails from '../Availability/AvailabilityOutfitDetails';
import ErrorTypeMessage from '../ErrorTypeMessage';

import StyledAvailability, {
  Body,
} from '../Availability/Availability.style';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DISPLAY } from '../../config/locale';
import FetchPolicy from '../../constants/FetchPolicy';

import FormModal from '../ui/Modal/FormModal/FormModal';
import NoticeModal from '../ui/Modal/NoticeModal/NoticeModal';
import AmendView from './AmendView';
import AmendParty from './Forms/AmendParty';
import AmendPartyMember from './Forms/AmendPartyMember';
import AmendRestriction from './AmendRestriction';
import GET_POPUP from '../PopUp/graphql/getPopUp';
import AMEND_BOOKING_OUTFIT from './graphql/amendBookingOutfit';
import {
  AMEND_MODALS, getModalProps, handleToggleModal,
  cleanUpOutfitPayload, quoteHasPitchStartingSoon,
} from '../../lib/helpers/amend';
import routes from '../../constants/routes';
import formatDate from '../../lib/format';
import { bookingHasOverlaps } from './helpers';
import { AMEND_RESTRICTIONS } from '../../config/amendRestrictions';
import AvailabilityDurationInfo from '../Availability/AvailabilityDurationInfo';
import { ButtonBordered } from '../ui/Button';
import { Col, Row } from '../ui/Grid';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';
import AvailabilityAmendImportantInfo from './AvailabilityAmendImportantInfo';
import { AmendsContext } from '../Availability/AvailabilitySearchQuery';

const getMember = (member) => ({
  ...member,
  fullName: member.personName,
});

// Modified copy of Availability.jsx to avoid increasing it's complexity
class AvailabilityAmend extends Component {
  static propTypes = {
    amendBookingOutfit: PropTypes.func.isRequired,
    availabilitySearch: PropTypes.shape({
      calendarFirstCellDate: PropTypes.string,
      data: PropTypes.shape({
        availability: PropTypes.shape({
          pitchTypes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
      error: PropTypes.shape(IbePropTypes.error),
      loading: PropTypes.bool,
      networkStatus: PropTypes.number,
    }),
    availabilitySearchLoading: PropTypes.bool,
    availabilityTotal: PropTypes.shape({
      error: PropTypes.shape(IbePropTypes.apolloError),
      loading: PropTypes.bool,
      data: PropTypes.shape({
        availability: PropTypes.shape({
          pitchTypes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
    campsiteName: PropTypes.string,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    closedDateError: PropTypes.shape({
      message: PropTypes.string,
    }),
    disabledForms: PropTypes.bool.isRequired,
    getPitches: PropTypes.shape({
      campsite: PropTypes.shape(IbePropTypes.campsite),
      error: PropTypes.shape(IbePropTypes.apolloError),
      loading: PropTypes.bool,
    }),
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.shape(IbePropTypes.apolloError),
      dictionary: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    configuration: PropTypes.shape(IbePropTypes.configuration),
    campsiteId: PropTypes.string,
    continued: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    fetchAvailabilitySearch: PropTypes.func.isRequired,
    fetchAvailabilityTotal: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    hasBookings: PropTypes.bool,
    inputDateError: PropTypes.shape({
      message: PropTypes.string,
    }),
    isSharedConfigLoading: PropTypes.bool,
    isSitesConfigLoading: PropTypes.bool,
    loadingAvailability: PropTypes.bool,
    prices: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          error: PropTypes.shape(IbePropTypes.apolloError),
          loading: PropTypes.bool,
        }),
      ),
    }),
    onContinueClick: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    payload: PropTypes.shape(IbePropTypes.quotePayload),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    updatePayload: PropTypes.func.isRequired,
    query: PropTypes.shape(IbePropTypes.query),
    type: PropTypes.number.isRequired,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    handleMemberNonMemberChange: PropTypes.func.isRequired,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    handleUpdateQuote: PropTypes.func.isRequired,
    product: PropTypes.shape(IbePropTypes.product),
    quote: PropTypes.shape(IbePropTypes.quote),
    user: PropTypes.shape(IbePropTypes.user),
    popups: PropTypes.shape(IbePropTypes.popups),
    resetSession: PropTypes.func.isRequired,
  };

  static defaultProps = {
    availabilitySearch: {
      data: {
        availability: {
          pitchTypes: [],
        },
      },
    },
    availabilitySearchLoading: false,
    availabilityTotal: {
      data: {
        availability: {
          pitchTypes: [],
        },
      },
    },
    campsiteName: '',
    closedDateError: undefined,
    getPitches: {
      error: false,
      campsite: defaultCampsite.campsite,
    },
    inputDateError: null,
    data: {},
    campsiteId: null,
    continued: false,
    error: false,
    hasBookings: false,
    isSharedConfigLoading: false,
    isSitesConfigLoading: false,
    loadingAvailability: false,
    prices: {
      count: 0,
      data: [],
    },
    query: {},
    product: {},
    configuration: {},
    payload: {},
    popups: {
      noticePopUp: {
        open: false,
        type: '',
      },
      formPopUp: {
        open: false,
        type: '',
      },
    },
    selectedEvent: null,
    quote: {},
    user: null,
  };

  static contextType = AmendsContext;

  constructor(props) {
    super(props);

    this.state = {
      activePartyMember: null,
      fieldFocus: '',
      outfitFieldsTouched: {
        vehicleLength: !!props.payload.outfit.vehicleLength,
        vehicleType: !!props.payload.outfit.vehicleType,
        towLength: !!props.payload.outfit.towLength,
        towType: !!props.payload.outfit.towType,
      },
      outfitLengthErrors: [],
      outfitErrors: [],
      partyMembers: props.payload?.partyMembers?.map(getMember),
      arrivalTimeErrors: [],
      partyErrors: [],
      isSubmitted: false,
      sortType: undefined,
    };
  }

  state = {
    discardModalOpen: false,
  }

  componentDidMount() {
    // As availability is such a heavy request we need to fetch it manually
    this.props.fetchAvailabilitySearch();
    this.props.fetchAvailabilityTotal();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payload.partyMembers?.length !== this.props.payload.partyMembers?.length) {
      this.updatePartyMembersState();
    }
  }

  handleSortTypeChange = (sortType) => {
    this.setState({ sortType });
  }

  updatePartyMembersState = () => {
    this.setState({
      partyMembers: this.props.payload?.partyMembers,
    });
  }

  maxCampsitePartyLength = (pitches) => {
    if (!pitches.length) return 0;

    const maxPeopleInCampsite = pitches
      .map((pitch) => pitch.maxOccupancy)
      .reduce((acc, cur) => Math.max(acc, cur));

    return maxPeopleInCampsite;
  };

  handleResetDates = async () => {
    const {
      fetchAvailabilityTotal,
      handleChange,
      onQueryChange,
      payload,
      query,
      updatePayload,
    } = this.props;
    const copiedQuery = { ...query };

    const mutatedPayload = cloneDeep(payload);

    mutatedPayload.start = undefined;
    mutatedPayload.end = undefined;

    await updatePayload(mutatedPayload);

    await handleChange({ continued: false });

    await onQueryChange({
      ...copiedQuery,
      start: mutatedPayload.start,
      end: mutatedPayload.end,
    });

    await updateRouterQuery(
      routes.sites,
      {
        start: mutatedPayload.start,
        end: mutatedPayload.end,
      },
      'replace',
    );

    await handleChange(mutatedPayload);

    await fetchAvailabilityTotal(mutatedPayload);
  };

  handleCalendarMonthChange = (response) => {
    this.props.handleChange(response, this.props.fetchAvailabilitySearch);
  };

  handleDurationChange = async (duration) => {
    const {
      client,
      fetchAvailabilityTotal,
      handleChange,
      onQueryChange,
      payload,
      query,
      updatePayload,
      quote,
    } = this.props;
    const copiedQuery = { ...query };
    const [start, end] = duration;

    payload.start = start;
    payload.end = end;

    if (bookingHasOverlaps(payload, quote.campsiteBookings)) {
      handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_DATE_OVERLAP);
    }

    await updatePayload(payload);

    await handleChange({ continued: false });

    await onQueryChange({
      ...copiedQuery,
      start,
      end,
    });

    await updateRouterQuery(
      routes.sites,
      {
        start: payload.start,
        end: payload.end,
      },
      'replace',
    );

    await handleChange(payload);

    await fetchAvailabilityTotal(payload);
  };

  handlePartyChange = async (payload) => {
    await this.props.updatePayload(payload);

    this.props.fetchAvailabilitySearch();
    await this.props.fetchAvailabilityTotal();
  };

  // Merge user outfit with quote payload outfit
  handleUserOutfitChange = async (outfit) => {
    const { payload, updatePayload } = this.props;
    payload.outfit = { ...outfit };
    updatePayload(payload);

    await this.validate(payload);

    this.props.handleChange({ continued: false });
  };

  handleArrivalTimeChange = async (arrivalTime) => {
    const { payload, updatePayload } = this.props;
    payload.arrivalTime = arrivalTime;
    this.setState({
      arrivalTimeErrors: [],
    });
    updatePayload(payload);

    this.props.handleChange({ continued: false });
  };

  handleOutfitBlur = ({ target }) => {
    const { name } = target;
    const { outfitFieldsTouched } = this.state;

    this.setState({
      outfitFieldsTouched: {
        ...outfitFieldsTouched,
        [name]: true,
      },
    });
  };

  // Replace quote payload outfit prop with changed value
  handleOutfitDetailsChange = async (outfit) => {
    const { payload, updatePayload } = this.props;
    payload.outfit = outfit;

    await updatePayload(payload);

    await this.validate(payload);

    this.props.handleChange({ continued: false });
  };

  handlePitchChange = ({ id, maxOccupancy, name }) => {
    const payload = { ...this.props.payload };
    payload.pitchTypeId = id;
    this.props.handleChange({
      continued: false,
      payload,
      maxOccupancy,
      pitchName: name,
    });
  };

  handleOutfitLengthError = (errors) => {
    this.setState({
      outfitLengthErrors: [...errors],
    });
  };

  handleSubmit = async () => {
    this.setState({ isSubmitted: true });
    await this.validate(this.props.payload, true);

    const element = getErrorElement();
    if (element) {
      return element.focus();
    }

    return this.props.onContinueClick(true);
  };

  // Get the most recently focused date input field and set it in state
  handleFieldFocus = (name) => {
    this.setState({ fieldFocus: name });
  };

  validate = (payload, submit = false) => {
    const { router, configuration, client } = this.props;
    const isOverseas = router.query.isOverseas === 'true';
    const outfitErrors = validateOutfitCampsite(
      payload.outfit, isOverseas, configuration, router, client,
    );
    const arrivalTimeErrors = submit
      ? validateArrivalTime(payload.arrivalTime)
      : [];

    this.setState({
      outfitErrors: [...outfitErrors, ...this.state.outfitLengthErrors],
      arrivalTimeErrors,
    });
  };

  resetOutfit = async () => {
    const { payload, updatePayload, quote } = this.props;
    if (!quote?.quoteId && !quote?.bookingReference) {
      return;
    }
    const newPayload = { ...payload };
    newPayload.outfit = quote?.outfit;
    await updatePayload(newPayload);
  }

  handlePopUpClose = (popUpType, callback) => {
    handleToggleModal(this.props.client, popUpType);
    if (callback) {
      callback();
    }
  }

  addPartyErrors = (newError) => {
    const currentPartyErrors = [...this.state.partyErrors];
    if (!currentPartyErrors.some(error => error.type === newError.type)) {
      currentPartyErrors.push(newError);
      this.setState({
        partyErrors: currentPartyErrors,
      });
    }
  };

  removePartyErrors = (type) => {
    const currentPartyErrors = [...this.state.partyErrors];
    if (currentPartyErrors.some(error => error.type === type)) {
      this.setState({
        partyErrors: currentPartyErrors.filter(error => error.type !== type),
      });
    }
  };

  getUpdatedMembers = (updatingMember) => {
    const { partyMembers } = this.state;
    const {
      getPitches, payload, configuration, router,
    } = this.props;
    const isOverseas = router.query.isOverseas === 'true';
    const partyOptions = guestOptions(configuration, isOverseas);
    const selectedPitch = getPitches.campsite.pitches.find(
      ({ id }) => id === payload.pitchTypeId,
    ) || {};
    const pitchConfig = {
      maxChildAge: selectedPitch?.maxChildAge ?? null,
      minChildAge: selectedPitch?.minChildAge ?? null,
    };

    // validate if date of birth is valid
    if (!isDateValid(updatingMember.dateOfBirth)) {
      this.addPartyErrors({
        type: 'InvalidDate',
        message: dictionaryItem('AmendForm', 'Party', 'DateOfBirth', 'Invalid'),
      });
      return null;
    }

    const memberType = getTypeByDateOfBirth(
      updatingMember.dateOfBirth,
      payload.start,
      partyOptions,
      pitchConfig,
    );

    if (updatingMember.isNew) {
      return [...partyMembers, {
        ...updatingMember,
        type: memberType,
        isNew: false,
      }];
    }

    if (memberType !== updatingMember.type) {
      this.addPartyErrors({
        type: 'InvalidType',
        message: dictionaryItem('AmendForm', 'Party', 'DateOfBirth', 'InvalidType'),
      });
      return null;
    }

    const memberIndexToUpdate = partyMembers.findIndex(
      member => (updatingMember.personId ?
        member.personId === updatingMember.personId :
        member.tempId === updatingMember.tempId),
    );
    if (memberIndexToUpdate > -1) {
      const partyMembersCopy = [...partyMembers];
      partyMembersCopy[memberIndexToUpdate] = updatingMember;
      return partyMembersCopy;
    }
    return partyMembers;
  }

  getPopUpActions = (type, popUpType = 'noticePopUp') => {
    const {
      client, resetSession, payload, updatePayload, getPitches,
    } = this.props;
    const defaultActions = {
      onClose: () => this.handlePopUpClose(popUpType, this.resetOutfit),
      onPrimaryAction: () => this.handlePopUpClose(popUpType),
      onSecondaryAction: () => this.handlePopUpClose(popUpType),
    };
    if (type === AMEND_MODALS.AMEND_OUTFIT) {
      return {
        ...defaultActions,
        onSecondaryAction: () => {
          handleToggleModal(client, 'noticePopUp');
          handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_OUTFIT_FORM);
        },
      };
    }
    if (type === AMEND_MODALS.AMEND_OUTFIT_SUCCESS) {
      return {
        ...defaultActions,
        onPrimaryAction: async () => {
          handleToggleModal(client, 'noticePopUp');
          await resetSession();
          updateRouterQuery(routes.myBookings);
        },
      };
    }
    if (type === AMEND_MODALS.AMEND_OUTFIT_FAILURE) {
      return {
        ...defaultActions,
        onPrimaryAction: () => {
          this.handlePopUpClose('noticePopUp');
          handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_OUTFIT_FORM);
        },
      };
    }
    if (type === AMEND_MODALS.AMEND_PARTY) {
      return {
        ...defaultActions,
        onSecondaryAction: () => { // Open party form
          handleToggleModal(client, 'noticePopUp');
          handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_PARTY_FORM);
        },
      };
    }
    if (type === AMEND_MODALS.AMEND_PARTY_FORM) {
      return {
        ...defaultActions,
        onClose: () => {
          this.handlePopUpClose(popUpType);
          this.setState({
            partyMembers: this.props.payload.partyMembers?.map(getMember),
          });
        },
        onPrimaryAction: () => { // Update party members in payload
          if (this.state.partyErrors.length) {
            return;
          }
          updatePayload({
            ...payload,
            partyMembers: this.state.partyMembers,
            partyChanged: true,
          }, () => {
            this.props.fetchAvailabilitySearch();
            this.props.fetchAvailabilityTotal();
          });
          handleToggleModal(client, 'formPopUp');
        },
        onSecondaryAction: () => { // Add new passenger/member
          const selectedPitch = getPitches.campsite.pitches.find(
            ({ id }) => id === payload.pitchTypeId,
          ) || {};
          if (selectedPitch &&
            selectedPitch.maxOccupancy === this.state.partyMembers.length) {
            handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_PARTY_MAX_OCCUPANCY);
            return;
          }
          const newMember = {
            personId: 0,
            tempId: uuid(), // unique identifier for used when deleting
            age: null,
            fullName: {},
            dateOfBirth: '',
            emailAddress: null,
            contactNumber: null,
          };
          this.setState({
            activePartyMember: { ...newMember, isNew: true },
            partyErrors: [],
          }, () => {
            handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_PARTY_ADD_MEMBER_FORM);
          });
        },
      };
    }
    if (type === AMEND_MODALS.AMEND_PARTY_LEAD_FORM ||
      type === AMEND_MODALS.AMEND_PARTY_MEMBER_FORM ||
      type === AMEND_MODALS.AMEND_PARTY_ADD_MEMBER_FORM) {
      return {
        ...defaultActions,
        onPrimaryAction: () => { // Add/edit member
          if (this.state.partyErrors.length) {
            return;
          }
          const memberCopy = { ...this.state.activePartyMember };
          if (memberCopy.fullName) {
            memberCopy.personName = memberCopy.fullName;
          }
          if (type !== AMEND_MODALS.AMEND_PARTY_ADD_MEMBER_FORM) {
            memberCopy.isNew = false;
          }
          if (memberCopy.type !== personTypes.ADULT) {
            memberCopy.age = getAge(memberCopy.dateOfBirth, payload.start);
          }
          if (type === AMEND_MODALS.AMEND_PARTY_LEAD_FORM) {
            const { prefix, firstName, surname } = memberCopy.fullName ?? {};
            if (!prefix || !firstName || !surname) {
              this.addPartyErrors({
                type: 'InvalidName',
                message: dictionaryItem('AmendForm', 'Generic', 'Error'),
              });
              return;
            }
          }

          const updatedMembers = this.getUpdatedMembers(memberCopy);

          if (updatedMembers) {
            handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_PARTY_FORM);
            this.setState({
              activePartyMember: null,
              partyMembers: updatedMembers,
            });
          }
        },
        onSecondaryAction: () => { // Cancel editing
          handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_PARTY_FORM);
          this.setState({
            activePartyMember: null,
          });
        },
      };
    }
    return defaultActions;
  }

  renderPitches = (pitches, campsiteName) => (
    <ul>
      {pitches.map((pitch) => (
        <li>{campsiteName} - Arriving: {formatDate(
          pitch.actualDatesBooked?.fromDate,
          DATE_FORMAT_DISPLAY,
        )}
        </li>
      ))}
    </ul>
  );

  handleAmendOutfit = async () => {
    const {
      client,
      router,
      amendBookingOutfit,
      configuration,
    } = this.props;
    const { isOverseas, eventType } = router.query;
    const eventTypeId = isOverseas === 'true' ? SINGLE_EVENT_TYPE.id : (
      Number(eventType) || PLACE_EVENT_TYPES.TOURING.id
    );
    const outfitErrors = validateOutfitCampsite(
      this.props.payload?.outfit,
      isOverseas,
      configuration,
      router,
      client,
    );
    if (outfitErrors?.length) {
      this.setState({
        outfitErrors: [...outfitErrors, ...this.state.outfitLengthErrors],
        isSubmitted: true,
      });
      return;
    }
    try {
      handleToggleModal(client, 'formPopUp');
      handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_OUTFIT_LOADING);
      await amendBookingOutfit({
        bookingReference: router.query.bookingId,
        outfit: {
          ...cleanUpOutfitPayload(this.props.payload?.outfit),
        },
        pitches: [{ eventType: eventTypeId }],
      });
      handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_OUTFIT_SUCCESS);
    } catch (error) {
      handleToggleModal(client, 'noticePopUp');
      handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_OUTFIT_FAILURE);
    }
  }

  handlePartyMember = (member) => {
    const { client } = this.props;
    this.setState({
      activePartyMember: member,
    });
    if (member.isLead) {
      handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_PARTY_LEAD_FORM);
    } else {
      handleToggleModal(client, 'formPopUp', AMEND_MODALS.AMEND_PARTY_MEMBER_FORM);
    }
  }

  handleAmendPartyMember = (member) => {
    this.setState({
      activePartyMember: member,
      partyErrors: [],
    });
  }

  handleEditPartyClick = () => {
    const { client, quote } = this.props;
    const hasPitchStartingSoon = quoteHasPitchStartingSoon(quote);
    if (hasPitchStartingSoon) {
      handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_NOT_ALLOWED);
      return;
    }
    handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_PARTY);
  }

  handleEditOutfitClick = () => {
    const { client, quote } = this.props;
    const hasPitchStartingSoon = quoteHasPitchStartingSoon(quote);
    if (hasPitchStartingSoon) {
      handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_NOT_ALLOWED);
      return;
    }
    handleToggleModal(client, 'noticePopUp', AMEND_MODALS.AMEND_OUTFIT);
  }

  setDiscardModal = (state) => {
    this.setState({
      discardModalOpen: state,
    });
  }

  discardChanges = () => {
    this.props.resetSession().finally(() => {
      Router.push(`${routes.myBookingsUpcoming}/${this.props.quote.bookingReference}`);
    });
  }

  render() {
    const {
      availabilitySearch,
      availabilitySearchLoading,
      availabilityTotal,
      campsiteId,
      continued,
      disabledForms,
      getPitches,
      data,
      data: { dictionary },
      error,
      configuration,
      loadingAvailability,
      isSharedConfigLoading,
      isSitesConfigLoading,
      payload,
      handleMemberNonMemberChange,
      memberPricesAreSelected,
      product,
      router,
      sitesConfig,
      quote,
      popups,
      user,
    } = this.props;

    const {
      outfitErrors, outfitFieldsTouched, outfitLengthErrors, isSubmitted, sortType,
    } =
      this.state;

    const uniqueOutfitErrors = uniqBy([...outfitLengthErrors, ...outfitErrors]);
    const filteredOutfitErrors = isSubmitted
      ? uniqueOutfitErrors
      : uniqueOutfitErrors.filter(({ type }) => {
        if (type === 'outfit') {
          return outfitFieldsTouched.towType;
        }

        return outfitFieldsTouched[type];
      });

    // If any of the queries are still loading
    if (
      loadingAvailability ||
      (!availabilitySearch.networkStatus && !availabilitySearch.error) ||
      availabilitySearch.networkStatus < 7 ||
      getPitches.loading ||
      data.loading ||
      isSharedConfigLoading ||
      !configuration
      || !sitesConfig
      || isSitesConfigLoading
    ) {
      return <LoadingSpinner marginTop />;
    }

    if (getPitches.error || !getPitches.campsite) {
      return <ErrorTypeMessage error={getPitches.error} />;
    }

    const { availability } = availabilitySearch.data;

    const pitches = getPitches.campsite.pitches
      .map(({ __typename, ...pitch }) => {
        const pitchAvailabilitySearch = availability.pitchTypes.find(
          ({ code }) => code === pitch.id,
        );
        const totalCost = getTotalCost(
          availabilityTotal,
          payload.pitchTypeId,
          memberPricesAreSelected,
        );

        const availabilityTotalPitch =
          availabilityTotal.data?.availability?.pitchTypes?.find(
            (pitchType) => pitchType.code === pitch.id,
          ) ?? {};

        return {
          ...pitch,
          ...pitchAvailabilitySearch,
          calendarFirstCellDate: availabilitySearch.calendarFirstCellDate,
          selected: pitch.id === payload.pitchTypeId,
          restrictions: availabilityTotalPitch?.restrictions
            ? availabilityTotalPitch.restrictions
            : pitchAvailabilitySearch?.restrictions ?? null,
          totalCost,
        };
      })
      .filter((pitchItem) => {
        const eventType =
          Number(router.query?.eventType) || PLACE_EVENT_TYPES.TOURING.id;
        const isOverseasCampsite =
          getPitches.campsite?.type === ids.OVERSEAS_SITE;
        const isOverseas = Number(router.query?.isOverseas === 'true');
        const onlySingleTypePitches = !getPitches.campsite.pitches.some(
          (pitch) => pitch.eventType !== SINGLE_EVENT_TYPE.id,
        );

        return (
          pitchItem.eventType === eventType ||
          (isOverseas && pitchItem.eventType === SINGLE_EVENT_TYPE.id) ||
          (isOverseasCampsite &&
            pitchItem.eventType === SINGLE_EVENT_TYPE.id) ||
          onlySingleTypePitches
        );
      });

    const sortedPitches =
      sortPitches(pitches, memberPricesAreSelected ? 'memberMinPricePerNight' : 'nonMemberMinPricePerNight', sortType);

    const selectedPitch =
      sortedPitches.find(({ id }) => id === payload.pitchTypeId) || {};

    const noAvailability = !selectedPitch.totalCost;

    const partyString = stringifyPartyMembers(
      payload.partyMembers,
      configuration?.partyMemberTypes,
    );

    const outfitString = stringifyVehicleType(
      payload.outfit?.vehicleType,
      configuration?.vehicleTypes,
    );

    const towString = stringifyVehicleType(
      payload.outfit?.towType,
      configuration?.towTypes,
      '',
    );

    // Disabled because its handled by BE
    let invalidDates = false;
    if (
      error &&
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.errorCode === 3
    ) {
      invalidDates = true;
    }

    if (this.props.closedDateError) {
      invalidDates = true;
    }

    // Check outfit vehicle exists and has a length value
    const invalidOutfit =
      !payload.outfit.vehicleType ||
      payload.outfit.vehicleLength === null ||
      Number.isNaN(payload.outfit.vehicleLength);

    // TODO: Find a dynamic way to set the party max amount of type
    partyConfig[0].max = selectedPitch.maxOccupancy;
    partyConfig[1].max = selectedPitch.maxOccupancy - 1;
    partyConfig[2].max = selectedPitch.maxOccupancy - 1;

    const dictionaryMap = Object.assign(
      {},
      ...dictionary.map((obj) => ({ [obj.key]: obj.value })),
    );
    const bookableDaysFromToday = getBookableDaysFromToday(
      getPitches.campsite.type,
      configuration.products,
      router.query?.isOverseas === 'true',
    );

    /**
     * @TODO product code or name should be supplied with campsite data
     * at a later date, here we are manually mapping for now.
     */

    const userIsMember =
      isLoggedInMember(user) || isMembershipInBasket(quote);
    const isOverseasSite = getPitches.campsite.type === ids[keys.OVERSEAS_SITE];
    const isUkClubSite = getPitches.campsite.type === ids[keys.UK_CLUB_SITES];

    const nonMemberBookableDate =
      product?.nonMemberBookableDate || configuration.availabilityMaxDate;
    const memberBookableDate =
      product?.memberBookableDate || configuration.availabilityMaxDate;
    const maxDate = memberBookableDate;
    const minDate = format(
      startOfDay(addDays(new Date(), bookableDaysFromToday)),
      DATE_FORMAT_DEFAULT,
    );

    const eventType = Number(
      router.query?.eventType ?? PLACE_EVENT_TYPES.TOURING.id,
    );

    const bookings = quote?.campsiteBookings;
    const booking = bookings?.[0] ?? {};
    const bookedPitches = booking?.pitches ?? [];

    const { amendingSite } = this.context;

    // there will only be 1 pitch in the array;
    const amendingSitePitch = amendingSite?.pitches[0];

    const modalProps = getModalProps(AMEND_MODALS.AMEND_CANCEL);

    return (
      <StyledAvailability>
        <AvailabilityAmendImportantInfo
          penaltyDate={amendingSitePitch?.penaltyDate}
          isOverseas={isOverseasSite}
        />
        <Body>
          <Title
            marginBottom
            tag={1}
            size={4}
            dictionary={dictionaryItem('AvailabilityAmend', 'Party')}
          />
          <Text size="0.85rem" marginBottom dictionary={dictionaryItem('AvailabilityParty', 'PartyNumbers')} />
          <AmendView
            value={partyString}
            buttonLabelDictionary="EditParty"
            onClick={this.handleEditPartyClick}
            isMemberSection={false}
            isOverseas={isOverseasSite}
          />
        </Body>

        <FormModal
          open={popups.formPopUp?.open}
          type={popups.formPopUp?.type}
          showSecondaryAction={isOverseasSite}
          {...getModalProps(popups.formPopUp?.type)}
          {...this.getPopUpActions(popups?.formPopUp?.type, 'formPopUp')}
        >
          <>
            {popups.formPopUp?.type === AMEND_MODALS.AMEND_PARTY_FORM &&
              <AmendParty
                configuration={configuration}
                partyMembers={this.state.partyMembers}
                onEditPartyMember={this.handlePartyMember}
                setPartyMembers={(partyMembers) => this.setState({
                  partyMembers,
                })}
                max={this.maxCampsitePartyLength(getPitches.campsite.pitches)}
                maxForCurrentPitch={selectedPitch.maxOccupancy}
                maxChildAge={selectedPitch.maxChildAge}
                minChildAge={selectedPitch.minChildAge}
                isOverseas={isOverseasSite}
              />}
            {(popups.formPopUp?.type === AMEND_MODALS.AMEND_PARTY_LEAD_FORM ||
              popups.formPopUp?.type === AMEND_MODALS.AMEND_PARTY_MEMBER_FORM ||
              popups.formPopUp?.type === AMEND_MODALS.AMEND_PARTY_ADD_MEMBER_FORM) &&
              <AmendPartyMember
                addErrors={this.addPartyErrors}
                removeErrors={this.removePartyErrors}
                member={this.state.activePartyMember}
                onEdit={this.handleAmendPartyMember}
                errors={this.state.partyErrors}
                isOverseas={isOverseasSite}
              />}
          </>
        </FormModal>

        <FormModal
          open={this.props.popups?.formPopUp?.type === AMEND_MODALS.AMEND_OUTFIT_FORM}
          onClose={() => this.handlePopUpClose('formPopUp', this.resetOutfit)}
          titleDictionary={dictionaryItem('AvailabilityAmend', 'Outfit')}
          onPrimaryAction={() => this.handleAmendOutfit()}
          primaryActionDictionary={dictionaryItem('AvailabilityAmend', 'Modal', 'UpdateDetails')}
          loadingDictionary={dictionaryItem('AvailabilityAmend', 'Modal', 'CalculatingUpdates')}
          primaryActionDisabled={!!filteredOutfitErrors?.length}
        >
          <>
            <AvailabilityOutfitDetails
              disabled={false} // Editing is always enabled in amend mode
              maxOutfitLength={
                selectedPitch.maxOutfitLength ||
                configuration.defaultMaxVehicleOverallLength
              }
              error={invalidOutfit}
              onError={this.handleOutfitLengthError}
              onUserOutfitChange={this.handleUserOutfitChange}
              onOutfitChange={this.handleOutfitDetailsChange}
              onOutfitBlur={this.handleOutfitBlur}
              outfit={payload.outfit}
              outfitErrors={filteredOutfitErrors}
              eventType={eventType}
              isOverseas={isOverseasSite}
              amend
            />
            {filteredOutfitErrors.map((err) => (
              <MessageWarning
                key={err.message}
                marginTop
              >
                {err.message}
              </MessageWarning>
            ))}
          </>
        </FormModal>

        <Body>
          <AvailabilityPitches
            campsiteId={campsiteId}
            disabled={disabledForms}
            handlePitchChange={this.handlePitchChange}
            loading={loadingAvailability}
            memberPricesAreSelected={memberPricesAreSelected}
            pitches={sortedPitches}
            pitchTypesLink={sitesConfig.pitchTypesLink}
            pitchTypeId={payload.pitchTypeId}
            showPrice={!!payload.start && !!payload.end}
            amend
            sortType={sortType}
            handleSortTypeChange={this.handleSortTypeChange}
          />
        </Body>

        <Body>
          <AvailabilityDuration
            availability={availability}
            availabilitySearchLoading={availabilitySearchLoading}
            disabled={disabledForms}
            error={!selectedPitch}
            endDate={payload.end}
            fetchAvailability={this.handleCalendarMonthChange}
            loading={loadingAvailability}
            memberBookableDate={memberBookableDate}
            memberPricesAreSelected={memberPricesAreSelected}
            noAvailability={noAvailability}
            nonMemberBookableDate={nonMemberBookableDate}
            onGoToMemberPrices={handleMemberNonMemberChange}
            onCancelMemberPrices={this.handleResetDates}
            onRequestText={
              dictionaryMap.AvailabilityDuration__OnRequest__Button
            }
            userIsMember={userIsMember}
            handleFieldFocus={this.handleFieldFocus}
            onChange={this.handleDurationChange}
            options={{
              dateRange: true,
              handleFieldFocus: this.handleFieldFocus,
              maxDate,
              minDate,
              months: 1,
              name: 'campsiteAvailability_dateRange',
              showRemainder: false,
              static: true,
            }}
            fieldFocus={this.state.fieldFocus}
            selectedEvent={this.props.selectedEvent}
            selectedPitch={selectedPitch}
            startDate={payload.start}
            inputDateError={!!this.props.inputDateError}
            apiError={!!this.props.error}
            availabilityTotalError={!!availabilityTotal.error}
            pitches={sortedPitches}
            handlePitchChange={this.handlePitchChange}
            isSubmitted={this.state.isSubmitted}
          />

          {this.props.availabilityTotal.error && (
            <ErrorTypeMessage
              error={this.props.availabilityTotal.error}
              marginBottom
            />
          )}

          {!!this.props.inputDateError && (
            <MessageWarning
              key={this.props.inputDateError.message}
              marginTop
              marginBottom
              dictionary={this.props.inputDateError.message}
            />
          )}

          {!!this.props.closedDateError && (
            <MessageWarning
              key={this.props.closedDateError.message}
              marginTop
              marginBottom
              dictionary={this.props.closedDateError.message}
            />
          )}

          {selectedPitch.code && (
            <AvailabilityDurationInfo
              selectedPitch={selectedPitch}
              partyString={partyString}
              memberPricesAreSelected={memberPricesAreSelected}
              startDate={payload.start}
              endDate={payload.end}
              isAmend
            />
          )}
        </Body>

        <Body>
          <Title
            marginBottom
            tag={1}
            size={4}
            dictionary={dictionaryItem('AvailabilityAmend', 'Outfit')}
          />
          <Text
            size="0.85rem"
            marginBottom
            dictionary={dictionaryItem('AvailabilityOutfitDetails', 'VanNote')}
          />
          <AmendView
            value={`${outfitString} ${towString ? 'and ' : ''}${towString}`}
            buttonLabelDictionary="EditOutfit"
            onClick={this.handleEditOutfitClick}
            isOverseas={isOverseasSite}
          />
        </Body>

        {selectedPitch.fromArrivalTime > -1 && isUkClubSite ? (
          <Body>
            <AvailabilityArrivalTime
              start={selectedPitch.fromArrivalTime}
              end={selectedPitch.toArrivalTime}
              onChange={this.handleArrivalTimeChange}
              arrivalTimeErrors={this.state.arrivalTimeErrors}
              value={payload.arrivalTime}
            />
            {this.state.arrivalTimeErrors.map((err) => (
              <MessageWarning
                key={err.message}
                marginTop
                dictionary={err.message}
              />
            ))}
          </Body>
        ) : null}

        {this.props.availabilityTotal.error && (
          <ErrorTypeMessage
            error={this.props.availabilityTotal.error}
            marginBottom
          />
        )}

        {!continued && (
          <Body>
            <Row size="small">
              <Col>
                <ButtonBordered
                  block
                  type="button"
                  onClick={() => this.setDiscardModal(true)}
                  size="xl"
                  dictionary={dictionaryItem('AvailabilityAmend', 'DiscardChanges')}
                />
              </Col>
              <Col>
                <QuoteCreate
                  disabled={disabledForms || selectedPitch.restrictions || invalidDates}
                  handleContinueClick={this.handleSubmit}
                  loading={
                    isSharedConfigLoading
                    || isSitesConfigLoading
                    || availabilityTotal.loading
                  }
                  payload={payload}
                />
              </Col>
            </Row>

            {data.error && (
              <ErrorTypeMessage errorCode={data.error} marginBottom />
            )}

            {getPitches.error && (
              <ErrorTypeMessage errorCode={getPitches.error} marginBottom />
            )}
          </Body>
        )}

        {popups?.noticePopUp?.type !== AMEND_MODALS.AMEND_CANCEL &&
          <NoticeModal
            open={popups.noticePopUp?.open}
            type={popups.noticePopUp?.type}
            isLoading={popups.noticePopUp?.type === AMEND_MODALS.AMEND_OUTFIT_LOADING}
            {...getModalProps(popups.noticePopUp?.type)}
            {...this.getPopUpActions(popups?.noticePopUp?.type, 'noticePopUp')}
          >
            {popups?.noticePopUp?.type === AMEND_MODALS.AMEND_OUTFIT_FAILURE &&
              this.renderPitches(bookedPitches, this.props.campsiteName)}
            {popups?.noticePopUp?.type === AMEND_MODALS.AMEND_NOT_ALLOWED &&
              <AmendRestriction id={AMEND_RESTRICTIONS.BOOKING_AMENDMENT_NOT_ALLOWED} />}
          </NoticeModal>}

        <NoticeModal
          {...modalProps}
          open={this.state.discardModalOpen}
          onPrimaryAction={this.discardChanges}
          onSecondaryAction={() => this.setDiscardModal(false)}
          onClickOutside={() => this.setDiscardModal(false)}
          onClose={() => this.setDiscardModal(false)}
        />
      </StyledAvailability>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
      isSharedConfigLoading: data.loading,
    }),
  }),
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
      isSitesConfigLoading: data.loading,
    }),
  }),
  graphql(GET_USER, {
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    props: ({ data }) => ({
      user: data.user,
    }),
    skip: (props) => !isLoggedIn(),
  }),
  graphql(GET_AVAILABILITY_AND_USER_CONFIG, {
    options: () => ({
      variables: {
        dictionaryKeys: ['AvailabilityDuration__OnRequest__Button'],
        pathBuilder: buildPath('dictionary/find'),
      },
    }),
  }),
  graphql(GET_PITCHES, {
    name: 'getPitches',
    options: ({ campsiteId }) => ({
      variables: {
        id: campsiteId,
        skip: !campsiteId,
      },
    }),
  }),
  graphql(GET_POPUP, {
    name: 'popups',
  }),
  graphql(AMEND_BOOKING_OUTFIT, {
    props: ({ mutate }) => ({
      amendBookingOutfit: input => mutate({
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(AvailabilityAmend);
