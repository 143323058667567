import React from 'react';
import PropTypes from 'prop-types';
import { sharedConfigurationType } from '../../IbePropTypes';

import { formatPriceWithCurrency } from '../../lib/format';

import {
  RichText,
  LightBoxTable,
  LightBoxBody,
  LightBoxRow,
  LightBoxHeader,
  LightBoxCol,
  LightBoxTitle,
  LightBoxAmount,
  LightBoxContent,
  LightBoxFooter,
  LightBoxFooterContent,
} from './MembershipLightBox.style';

const renderHeader = (title, isMobile) => (
  <LightBoxHeader as={isMobile ? 'div' : undefined}>
    <LightBoxTitle>{title}</LightBoxTitle>
  </LightBoxHeader>
);

const renderPriceContent = (price, validity, description) => (
  <LightBoxContent>
    <LightBoxAmount>{formatPriceWithCurrency(price)}</LightBoxAmount>
    {validity}<br />
    {description}
  </LightBoxContent>
);

const renderRichText = (richText) => (
  <RichText>
    {richText}
  </RichText>
);

const renderFooter = (title, description, isMobile) => (
  <LightBoxFooter as={isMobile ? 'div' : undefined}>
    <LightBoxFooterContent colSpan="2" as={isMobile ? 'div' : undefined}>
      <b>{title}</b><br />
      {description}
    </LightBoxFooterContent>
  </LightBoxFooter>
);

const MembershipLightBoxTable = ({ configuration }) => {
  if (!configuration) return null;
  const { membershipContent, membershipTypes } = configuration;
  const {
    directDebitTitle,
    directDebitValidity,
    directDebitDescription,
    directDebitContent,
    creditCardTitle,
    creditCardValidity,
    creditCardDescription,
    creditCardContent,
    footerTitle,
    footerDescription,
  } = membershipContent;

  return (
    <LightBoxTable cellSpacing="0">
      <LightBoxBody>
        <LightBoxRow>
          {renderHeader(directDebitTitle)}
          {renderHeader(creditCardTitle)}
        </LightBoxRow>
        <LightBoxRow>
          <LightBoxCol>
            {renderPriceContent(
              membershipTypes?.directDebit?.costSingle,
              directDebitValidity,
              directDebitDescription,
            )}
          </LightBoxCol>
          <LightBoxCol>
            {renderPriceContent(
              membershipTypes?.creditCard?.costSingle,
              creditCardValidity,
              creditCardDescription,
            )}
          </LightBoxCol>
        </LightBoxRow>
        <LightBoxRow>
          <LightBoxCol>
            {renderRichText(directDebitContent)}
          </LightBoxCol>
          <LightBoxCol>
            {renderRichText(creditCardContent)}
          </LightBoxCol>
        </LightBoxRow>
        {renderFooter(footerTitle, footerDescription)}
      </LightBoxBody>
    </LightBoxTable>
  );
};

export const MembershipLightBoxTableMobile = ({ configuration }) => {
  if (!sharedConfigurationType) return null;
  const { membershipContent, membershipTypes } = configuration;
  const {
    directDebitTitle,
    directDebitValidity,
    directDebitDescription,
    directDebitContent,
    creditCardTitle,
    creditCardValidity,
    creditCardDescription,
    creditCardContent,
    footerTitle,
    footerDescription,
  } = membershipContent;

  return (
    <LightBoxTable as="div">
      <LightBoxBody as="div" isMobile>
        <LightBoxRow as="div">
          {renderHeader(directDebitTitle, true)}
          {renderPriceContent(
            membershipTypes?.directDebit?.costSingle,
            directDebitValidity,
            directDebitDescription,
          )}
          {renderRichText(directDebitContent)}
        </LightBoxRow>
        <LightBoxRow as="div">
          {renderHeader(creditCardTitle, true)}
          {renderPriceContent(
            membershipTypes?.creditCard?.costSingle,
            creditCardValidity,
            creditCardDescription,
          )}
          {renderRichText(creditCardContent)}
        </LightBoxRow>
        {renderFooter(footerTitle, footerDescription, true)}
      </LightBoxBody>
    </LightBoxTable>
  );
};

MembershipLightBoxTable.propTypes = {
  configuration: PropTypes.shape(sharedConfigurationType).isRequired,
};

MembershipLightBoxTableMobile.propTypes = {
  configuration: PropTypes.shape(sharedConfigurationType).isRequired,
};

export default MembershipLightBoxTable;
