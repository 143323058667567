import React from 'react';

import { RichText } from '../../ui';

import { dictionaryItem } from '../../../hocs/withDictionary';

const Pitches = () => (
  <>
    <RichText
      dictionary={dictionaryItem('AvailabilityPitches', 'Modal')}
    />
  </>
);

export default Pitches;
