import { useState } from 'react';

import FETCH_POLICY from '../../../constants/FetchPolicy';
import GET_GUEST_PRICE from '../graphql/getGuestPrice.gql';

import useDebouncedMutation from '../../../hooks/useDebouncedMutation';

import * as _Types from '../../../lib/jsdocTypedefs';

/**
 * Hook to handle querying the API for a guest price
 * @param {_Types.UseGuestPriceQueryVariables} variables The query variables
 * @param {client} client Standard apollo client
 * @param {Function} onComplete Function to run when query returns
 * @returns {_Types.GuestPriceQueryResult}
 */
const useGuestPrice = (variables, client, onComplete) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [debouncedGetPrices] = useDebouncedMutation(GET_GUEST_PRICE, {
    variables,
    fetchPolicy: FETCH_POLICY.NO_CACHE,
    client,
    onCompleted: (data) => {
      const { price: returnedPrice } = data?.guestPrices ?? {};
      setLoading(false);
      onComplete(returnedPrice);
    },
    onError: (err) => {
      setLoading(false);
      setError(err);
      onComplete(null);
    },
    onStart: () => {
      setLoading(true);
      setError(null);
    },
  }, 500);

  return { getPrices: debouncedGetPrices, loading, error };
};

export default useGuestPrice;
