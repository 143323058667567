import { useReducer } from 'react';
import { usePartStayGuestContext } from './useGuestContext';

import * as _Types from '../../../lib/jsdocTypedefs';
import { formatPartStayGroupsForQuote, formatPartStayGuestsQuoteToGroups } from '../guestHelpers';

export const GROUP_ACTIONS = {
  ADD: 'add',
  UPDATE: 'update',
  REMOVE: 'remove',
  REMOVE_ALL: 'remove_all',
};

/**
 * Reducer to handle different state update types
 * @param {_Types.GuestGroup[]} state
 * @param {_Types.PartStayGuestReducerAction} action
 * @returns {_Types.GuestGroup[]}
 */
const reducer = (state, action) => {
  const { type, data } = action;
  switch (type) {
    case GROUP_ACTIONS.ADD: {
      return [...state, data];
    }
    case GROUP_ACTIONS.REMOVE: {
      return state.filter((group) => group.id !== data.id);
    }
    case GROUP_ACTIONS.UPDATE: {
      return state.map((group) => (group.id === data.id ? data : group));
    }
    case GROUP_ACTIONS.REMOVE_ALL: {
      return [];
    }
    default: {
      return state;
    }
  }
};

/**
 * This function is used to get the most up to date value of the reducer
 * and add it to the quote in one central place. Also needs to be here
 * so we can access context to get the change handler.
 * @param {_Types.GuestGroup[]} state
 * @param {_Types.PartStayGuestReducerAction} action
 * @param {Function} changeHandler
 * @returns {_Types.GuestGroup[]}
 */
const reducerInterceptor = (state, action, changeHandler) => {
  const updatedState = reducer(state, action);
  // Update the quote with the most recent information
  const formattedGuestsForQuote = formatPartStayGroupsForQuote(updatedState);
  changeHandler(formattedGuestsForQuote);
  return updatedState;
};

/**
 * Hook to neatly return the reducer for usage in components
 * @returns {_Types.PartStayGuestReducerReturnType}
 */
const useGroupManager = () => {
  const { guests, onChange } = usePartStayGuestContext();
  const formattedGuests = formatPartStayGuestsQuoteToGroups(guests);
  return useReducer(
    (state, action) => reducerInterceptor(state, action, onChange),
    formattedGuests,
  );
};

export default useGroupManager;
