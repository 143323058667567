import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withTheme } from 'styled-components';

import OutfitForm from '../Outfit/OutfitForm';
import Text from '../ui/Text';

import { dictionaryItem } from '../../hocs/withDictionary';
import { Title } from '../ui';
import { Flex } from '../ui/Grid/Row.style';
import InfoIconWithModal from './InfoIconWithModal';
import Outfit from './modals/Outfit';

const propTypes = {
  amend: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  maxOutfitLength: PropTypes.number,
  onError: PropTypes.func.isRequired,
  onUserOutfitChange: PropTypes.func.isRequired,
  onOutfitBlur: PropTypes.func.isRequired,
  onOutfitChange: PropTypes.func.isRequired,
  outfit: PropTypes.shape({}).isRequired,
  outfitErrors: PropTypes.arrayOf(PropTypes.shape({})),
  eventType: PropTypes.number,
  isOverseas: PropTypes.bool,
};

const defaultProps = {
  amend: false,
  disabled: false,
  error: false,
  maxOutfitLength: null,
  outfitErrors: [],
  eventType: null,
  isOverseas: false,
};

const AvailabilityOutfitDetails = ({
  amend,
  disabled,
  error,
  outfitErrors,
  maxOutfitLength,
  onError,
  onUserOutfitChange,
  onOutfitBlur,
  onOutfitChange,
  outfit,
  eventType,
  isOverseas,
}) => (
  <>
    <Flex align="center" justify="space-between">
      <Title
        marginBottom
        tag={1}
        size={4}
        dictionary={dictionaryItem('AvailabilityOutfitDetails')}
      />
      <InfoIconWithModal modalContent={<Outfit />} />
    </Flex>
    <OutfitForm
      amend={amend}
      disabled={disabled}
      error={error}
      maxOutfitLength={maxOutfitLength}
      onBlur={onOutfitBlur}
      onChange={onOutfitChange}
      onError={onError}
      onUserOutfitChange={onUserOutfitChange}
      outfit={outfit}
      outfitErrors={outfitErrors}
      eventType={eventType}
      isOverseas={isOverseas}
      use="Sites"
    />

    {isOverseas && (
    <Text
      dictionary={dictionaryItem('AvailabilityOutfitDetails', 'VanNote')}
      size="0.875rem"
      marginTop
    />)}
  </>
);

AvailabilityOutfitDetails.propTypes = propTypes;
AvailabilityOutfitDetails.defaultProps = defaultProps;

export default compose(
  memo,
  withTheme,
)(AvailabilityOutfitDetails);
