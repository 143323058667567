import styled, { css } from 'styled-components';

import { Datepicker as DatepickerComponent } from '../../ui/Datepicker';
import InputWithIcon from '../../ui/Form/InputWithIcon/InputWithIcon';

import { above } from '../../../styles/libs';
import { focusState } from '../../ui/Form/Form.style';
import { Input } from '../../Guests/Guests.style';

export const Datepicker = styled(DatepickerComponent)`
  margin-bottom: 0.5rem;

  ${({ theme }) => above(theme.DESKTOP)`
    margin-bottom: 1.25rem;
  `}
`;

export const DateInputWrapper = styled.div`
  position: relative;
`;

export const DateInput = styled(Input)`
  border-radius: 0;

  ${({ theme }) => css`
    border: 1px solid ${theme.COLOR_GRAY_DARK};
  `}

  ${({ errored, theme }) => errored && css`
    border: 1px solid ${theme.COLOR_DANGER};
  `}
`;

export const SearchInput = styled(InputWithIcon)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;

  ${({ hasSideButton }) => hasSideButton && css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${focusState}

  ${({ hideFocus }) => hideFocus && css`
    &:focus {
      outline: none;
      box-shadow: none;
    }
  `}
`;

export const LocationSearchInputWrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

export const UseMyLocation = styled.div`
  position: relative;
  background: ${({ theme }) => theme.COLOR_WHITE};
  border: none;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  outline: none;
  text-align: left;
  font-size: 0.875rem;
  cursor: pointer;

  // Psuedo-element for left divider
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% / 2);
    width: 2px;
    height: 50%;
    background: ${({ theme }) => theme.COLOR_GRAY};
  }
`;

export const MyLocationContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;
  padding: 0 0.75rem;

  ${({ grantedPermission }) => !grantedPermission && css`
    opacity: 0.7;
  `}

  ${({ grantedPermission, myLocationActive, theme }) => grantedPermission && myLocationActive && css`
    color: ${theme.COLOR_CYAN_DARK};
  `}
`;

export const SearchDateInput = styled(InputWithIcon)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;
  text-transform: uppercase;
`;
