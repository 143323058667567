import { useState } from 'react';
import { useMutation } from '@apollo/client';

/*
  Hook to allow for debounced calls to a useMutation hook.

  @param  query   any graphql query
  @param  opts*   the options, e.g. variables that would normally be passed to useMutation
  @param  delay   debounce delay in ms

  * - I've added a custom onStart callback to this so we can trigger code when the query starts
*/
export default function useDebouncedMutation(query, opts, delay) {
  const [timer, setTimer] = useState(undefined);
  const [mutate, mutationStatus] = useMutation(query, opts);

  const debounceMutation = (options) => new Promise((resolve) => {
    if (opts.onStart) opts.onStart();
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => {
      resolve(mutate(options));
    }, delay));
  });

  return [debounceMutation, { ...mutationStatus, timer }];
}
