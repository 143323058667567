import styled, { css } from 'styled-components';

import { Icon, Title } from '../ui';

export const InfoIconContainer = styled.div``;

export const StyledInfoIcon = styled(Icon)`
  width: 24px;
  height: 24px; // per designs
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.COLOR_CYAN_DARK};
  `}
`;

export const InfoModalTitle = styled(Title)`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;

  &:first-of-type {
    margin-top: 0;
  }
`;
