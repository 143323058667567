import React, {
  Children,
  Component,
  cloneElement,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { debounce, isEqual } from 'lodash';
import { format } from 'date-fns';
import { compose, graphql, withApollo } from 'react-apollo';

import { GoogleProvider } from '../../context/GoogleProvider';

import updateRouterQuery, { redirectFromWidget } from '../../lib/updateRouterQuery';
import scrollToTop from '../../lib/scrollToTop';

import { DATE_FORMAT_DEFAULT } from '../../config/locale';

import Basket from '../Basket';
import MapPopper from '../Map/MapPopper';
import SearchLayout from './SearchLayout';

import { MapWrapper, Section } from './SearchWrapper.style';

import theme from '../../styles/config/theme';

import GET_POPUP from '../PopUp/graphql/getPopUp';
import PLACE_EVENT_TYPES,
{ SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { parseTypes } from '../../lib/campsiteTypes';
import IbePropTypes from '../../IbePropTypes';
import routes from '../../constants/routes';
import { checkWebview } from '../../lib/helpers/mobileApp';
import { startTiming, types } from '../../lib/timings';
import { CAMPSITE_SEARCH_TRIGGER_IN_SESSION } from '../../lib/constants';
import GET_SITES_CONFIG from '../../config/graphql/getSitesConfig';
import { getDefaultSortOrder } from '../../lib/helpers/search';

export function isMobileWidth(width) {
  return width <= theme.TABLET;
}

function handleAllQueries(query) {
  return {
    query,
    queryCache: query,
  };
}

function parseAvailabilityQuery(query) {
  return {
    campsiteId: query.campsiteId,
    componentId: query.componentId,
    end: query.end,
    siteCode: query.siteCode,
    start: query.start,
  };
}

class SearchWrapper extends Component {
  static propTypes = {
    sitesConfig: PropTypes.shape(IbePropTypes.sitesConfig).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]).isRequired,
    confirmationModalState: PropTypes.shape({
      active: PropTypes.bool,
    }),
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    defaultQuery: PropTypes.shape(IbePropTypes.query),
    onBasketToggle: PropTypes.func.isRequired,
    popups: PropTypes.shape(IbePropTypes.apolloData),
    renderMap: PropTypes.func,
    router: PropTypes.shape(IbePropTypes.router),
  }

  static defaultProps = {
    confirmationModalState: {
      active: false,
    },
    defaultQuery: {},
    popups: {},
    renderMap() { },
    router: {
      query: {
        basketOpen: false,
      },
    },
  }

  constructor(props) {
    super(props);

    this.ref = createRef();

    this.refMap = createRef();

    this.refSearch = createRef();

    this.mapWrapperRef = createRef();

    this.resizeDebounce = debounce(() => this.handleResize(), 250);

    this.campsiteMapInstanceRef = null;

    const { query, queryCache } = handleAllQueries({
      ...this.props.defaultQuery,
      ...this.props.router.query,
    });

    const {
      campsiteId,
      componentId,
      end,
      siteCode,
      start,
    } = parseAvailabilityQuery({
      ...this.props.defaultQuery,
      ...this.props.router.query,
    });

    this.state = {
      basket: false,
      campaign: null,
      campsiteId,
      componentId,
      confirmation: false,
      end,
      error: null,
      isMobile: true,
      isReady: false,
      map: false,
      query,
      queryCache,
      result: null, // Can be a campsite or crossing
      results: [],
      scrollPosition: null,
      search: true,
      siteCode,
      start,
      zoomLevel: null,
      isOverseas: props.router.query?.isOverseas === 'true',
      isTours: props.router.query?.isTours === 'true',
      eventType: props.router.query?.eventType,
      webviewInitialized: false,
      expandedBasket: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeDebounce, false);
    const openBasket = !!this.props.router.query.basketOpen;

    const isMobile = isMobileWidth(window.innerWidth);

    this.setState({
      basket: !!openBasket,
      isMobile,
      isReady: window.google && navigator.onLine,
    });

    this.handleCampaignCode(this.props.router.query.campaignCode);

    if (isMobile && this.props.router.query.showMap) {
      updateRouterQuery(this.props.router.pathname, { showMap: false });
      scrollToTop();
      this.toggleMobileMapVisibility();
    }
  }

  componentDidUpdate({ router: { query: prevQuery } }, { basket: prevBasket }) {
    const {
      router: {
        query,
      },
    } = this.props;
    const { basket, isMobile } = this.state;

    if (prevQuery.campsiteId && !query.campsiteId) {
      this.scrollToPreviousPosition();
    }

    if (prevBasket !== basket) {
      this.props.onBasketToggle(basket, () => basket);
    }

    if (basket && isMobile) {
      document.body.classList.add('drawer-open');
    } else {
      document.body.classList.remove('drawer-open');
    }

    // Run query changed code only if actually relevant query params have changed
    if (!isEqual(query, prevQuery)) {
      this.handleQueryChange(query);
      if (this.campsiteMapInstanceRef) {
        this.campsiteMapInstanceRef.handleFitToBounds(query);
      }
    }
    this.handleMobileApp();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeDebounce, false);
  }

  handleMobileApp = () => {
    const { router: { query } } = this.props;
    if (checkWebview(query) && query.userLat && query.userLng && !this.state.webviewInitialized) {
      this.setState({
        webviewInitialized: true,
      });
      if (!query.showSearch) {
        this.toggleMobileMapVisibility();
      }
    }
  }

  handleCampaignCode = (codeName) => {
    const { router } = this.props;

    // TODO: Move this into a callback to non-campsite searches
    if (router.pathname !== routes.sites) return;

    // Don't run if there's no coniguration
    if (!this.props.sitesConfig) return;

    const { codes } = this.props.sitesConfig.campaignCodes;
    const campaign = codes.find(({ campaignCode }) => campaignCode === codeName);

    if (!campaign) return;

    this.setState({ campaign });

    // Don't run if the query has more than a campaign code
    if (codeName && Object.keys(router.query).length > 1) return;

    const dates = {};
    if (campaign.searchDates) {
      dates.start = format(campaign.searchDates.fromDate, DATE_FORMAT_DEFAULT);
      dates.end = format(campaign.searchDates.toDate, DATE_FORMAT_DEFAULT);
    }

    const newQuery = {
      ne_lng: null,
      ne_lat: null,
      sw_lng: null,
      sw_lat: null,
      campsiteId: campaign.siteId,
      activePin: campaign.siteId,
      ...dates,
    };

    // Remove invalid query parameters
    delete newQuery.lat;
    delete newQuery.lon;
    delete newQuery.searchDates;

    updateRouterQuery(router.pathname, { ...newQuery }, true);
  }

  toggleVisibility = (prop, toggle) => {
    const value = typeof toggle !== 'undefined' ? toggle : !this.state[prop];
    this.setState({ [prop]: value });
  }

  toggleBasket = (value, expanded) => {
    this.toggleVisibility('basket', value);
    if (expanded !== undefined) {
      this.setState({ expandedBasket: expanded });
    }
  }

  toggleSearch = () => this.toggleVisibility('search');

  toggleMobileMapVisibility = async () => {
    // Map ref is set after state change so we must await and force an update
    await this.toggleVisibility('map');
    this.forceUpdate();
  }

  goToTop = () => {
    if (!this.ref.current) return;
    this.ref.current.scrollIntoView(true);
  }

  handleQueryChange = (query, cb) => {
    const queryEventType = query.eventType || this.state.eventType;
    const eventType = Number(queryEventType) === SINGLE_EVENT_TYPE ? PLACE_EVENT_TYPES.TOURING :
      queryEventType;
    const allQueries = handleAllQueries({
      ...this.state.query,
      ...query,
      eventType,
    });
    const availabilityQuery = parseAvailabilityQuery({
      ...this.state.availabilityQuery,
      ...query,
      eventType,
    });
    this.setState({
      ...allQueries,
      availabilityQuery,
    }, cb && cb);
  }

  handleQueryChangeByMap = (query) => {
    this.setState({ query });
  }

  handleQueryReset = () => {
    this.setState({
      ...handleAllQueries(this.props.defaultQuery),
      availabilityQuery: parseAvailabilityQuery(this.props.defaultQuery),
      result: null,
    });
  }

  handleResultClear = () => {
    this.setState({
      result: null,
      results: [],
    });
  }

  // Pass the campsite or crossing details to be used in quote
  handleResultMount = (result) => {
    this.setState({ result });
  }

  scrollToPreviousPosition = () => {
    const position = this.state.scrollPosition;

    if (typeof position === 'undefined') {
      this.goToTop();
      return null;
    }

    window.scrollTo(0, position);

    this.setState({ scrollPosition: null });

    return null;
  }

  handleClear = async (cb, query = this.props.defaultQuery) => {
    this.setState({ showResults: false });

    const clearedQuery = { ...query, showResults: false };
    const clearedAllQueries = handleAllQueries(clearedQuery);
    const availabilityQuery = parseAvailabilityQuery(clearedQuery);

    await updateRouterQuery(this.props.router.pathname, clearedQuery);
    this.setState({
      error: null,
      loading: false,
      results: [],
      result: null,
      availabilityQuery,
      ...clearedAllQueries,
    }, () => cb && cb());
  }

  /**
   * Handle network error
   */
  handleError = (error, results) => {
    this.setState({
      error,
      loading: false,
      results,
    });
  }

  handleSubmitSearchForm = (cb, query) => {
    this.handleSubmit(cb, query, true);
  }

  /**
   * Only use if fetch is a POST
   */
  handleSubmit = async (cb, query = { ...this.state.query }, isSubmissionFromSearchForm) => {
    const { bookingWidget, ...queryState } = query;
    const requiredSortType = getDefaultSortOrder(query);
    if (bookingWidget === 'true') {
      redirectFromWidget(
        routes.sites,
        {
          ...queryState,
          sortType:
          requiredSortType,
          showResults: true,
          isFromWidget: true,
        },
      );
    } else {
      // Update the default sort type on global state so we don't cause invalid price queries
      // Certain sort types cannot be used when switching 'searchBy'. Here
      // we reset the global sort type to the default for the new searchBy
      // and product combination.
      this.props.client.writeData({
        data: {
          campsiteSortType: requiredSortType,
        },
      });

      // TEMP Performance Hooks, TODO can be safely removed at any time
      if (this.state.query?.location !== query.location) {
        startTiming(types.SEARCH_LOCATION);
        await updateRouterQuery(
          this.props.router.pathname,
          { ...this.props.router.query, showResults: true, location: query.location },
        );
      }
      scrollToTop();
      await updateRouterQuery(
        this.props.router.pathname,
        { ...this.props.router.query, ...queryState, showResults: true },
      );
      this.setState({
        error: null,
        loading: true,
        results: [],
        showResults: true,
        availabilityQuery: parseAvailabilityQuery(query),
        ...handleAllQueries(query),
      }, () => {
        if (cb) cb();
        if (this.campsiteMapInstanceRef && query.location) {
          this.campsiteMapInstanceRef.handleLocationSearch(query);
        } else if (isSubmissionFromSearchForm) {
          // Not ideal to set triggers, but replicating how it was done previous via a query string
          // param, searchBy, This is now being used for other functionality and is designed to
          // persist in the query. We will migrate here to use local storage trigger. The trigger
          // is detected in the mount of the infoWindow, whereby the map is updated and the trigger
          // removed
          window.sessionStorage.setItem(CAMPSITE_SEARCH_TRIGGER_IN_SESSION, true);
        }
      });
    }
  }

  /**
   * Handle no-content error and return results to state
   */
  handleSuccess = (response = [], cb) => {
    const { showResults } = this.state;

    if (!showResults) return;

    // Handle no-content response
    const results = response || [];
    this.setState({ loading: false, results, result: null }, cb && cb);
  }

  handleResize = () => this.setState({ isMobile: isMobileWidth(window.innerWidth) });

  handleZoomChange = (zoomLevel) => {
    if (zoomLevel === this.state.zoomLevel) return;
    this.setState(
      prevState => ({ ...prevState, zoomLevel }),
    );
  }

  checkForOpenModals = () => {
    const {
      popups: {
        error,
        fetchMore,
        loading,
        networkStatus,
        refetch,
        startPolling,
        stopPolling,
        subscribeToMore,
        updateQuery,
        variables,
        ...popupData
      },
    } = this.props;

    return !![...Object.keys(popupData)].map((k) => {
      if (typeof popupData[k] === 'boolean') {
        return popupData[k];
      }

      return popupData[k].open;
    }).filter(x => x).length;
  }

  classInstanceRefFunc = (ref) => {
    this.campsiteMapInstanceRef = ref;
  }

  render() {
    const {
      children,
      defaultQuery,
      router,
    } = this.props;

    const methods = {
      handleResultClear: this.handleResultClear,
      onClear: this.handleClear,
      onError: this.handleError,
      onQueryReset: this.handleQueryReset,
      onQueryChange: this.handleQueryChange,
      onResultClick: this.handleResultMount,
      onResultMount: this.handleResultMount,
      onSubmitSearchForm: this.handleSubmitSearchForm,
      onSuccess: this.handleSuccess,
      toggleVisibility: this.toggleVisibility,
      toggleBasket: this.toggleBasket,
      toggleMobileMap: this.toggleMobileMapVisibility,
      toggleSearch: this.toggleSearch,
      goToTop: this.goToTop,
    };

    const { basket } = this.state;
    const isMobileMapOpen = this.state.map && this.state.isMobile;
    const isBookingWidget = router.query.bookingWidget === 'true';
    const isBasketVisible = !!basket && isBookingWidget;
    const query = {
      ...this.state.queryCache,
      ...this.state.query,
    };
    const defaultTypes = defaultQuery.types;
    const queryEventType = this.state.query.eventType ||
      router.query.eventType;
    query.types = parseTypes(query.types, defaultTypes);
    query.eventType = Number(queryEventType) === SINGLE_EVENT_TYPE.id ?
      PLACE_EVENT_TYPES.TOURING.id : queryEventType;
    query.bookingWidget = router.query.bookingWidget;

    // Basket should not have an overlay if a modal is open
    const basketOverlay = !this.checkForOpenModals();

    const showResults = !!router.query.showResults;

    const mapMovedCount = this.campsiteMapInstanceRef?.mapMoveCountSinceSearchRef?.current ?? 0;

    const childProps = {
      ...this.state,
      ...methods,
      isMobileMapOpen,
      isBasketVisible,
      query,
      refMap: this.refMap,
      refSearch: this.refSearch,
      isMobile: this.state.isMobile,
      showResults,
      mapMovedCount,
    };

    return (
      <GoogleProvider>
        <Section
          ref={this.ref}
          isBookingWidget={isBookingWidget}
          data-remove-height-on-modal
        >
          <SearchLayout
            toggleBasket={childProps.toggleBasket}
          >
            {typeof children === 'function'
              ? children({ ...childProps })
              : Children.map(children, child => cloneElement(child, { ...childProps }))
            }
          </SearchLayout>

          <MapPopper
            isMobile={this.state.isMobile || isBookingWidget}
            refMap={this.refMap}
          >
            {() => (
              <MapWrapper
                data-hide-on-modal
                isBookingWidget={isBookingWidget}
                ref={this.mapWrapperRef}
              >
                {this.props.renderMap({
                  campaign: this.state.campaign,
                  classInstanceRefFunc: this.classInstanceRefFunc,
                  campsiteMapRef: this.campsiteMapInstanceRef,
                  goToTop: this.goToTop,
                  isMobile: this.state.isMobile || isBookingWidget,
                  isMobileMapOpen,
                  isVisible: true,
                  onQueryChange: this.handleQueryChange,
                  onQueryChangeByMap: this.handleQueryChangeByMap,
                  onQueInitialMapSearch: this.handleSubmit,
                  onZoomChanged: this.handleZoomChange,
                  query,
                  searchWrapperRef: this.mapWrapperRef,
                  showResults,
                  zoom: this.state.zoomLevel,
                })}
              </MapWrapper>
            )}
          </MapPopper>
        </Section>

        <Basket
          toggleBasket={this.toggleBasket}
          isMobile={this.state.isMobile}
          isVisible={basket}
          overlay={basketOverlay}
          query={query}
          isExpanded={this.state.expandedBasket}
        />
      </GoogleProvider>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  graphql(GET_SITES_CONFIG, {
    props: ({ data }) => ({
      sitesConfig: data.configurationSites,
      isSitesConfigLoading: data.loading,
    }),
  }),
  graphql(GET_POPUP, {
    props: ({ data }) => ({ popups: data }),
  }),
)(SearchWrapper);
