import React, { Fragment, PureComponent, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { cloneDeep, isEqual, uniqBy } from 'lodash';
import { util } from 'node-forge';
import {
  compose, graphql, Mutation, withApollo,
} from 'react-apollo';

import updateRouterQuery, { redirectFromWidget } from '../../lib/updateRouterQuery';
import { parseCrossingsPayload, handleRouteCodeLogic } from '../../lib/parsePayload';
import { searchCrossings } from '../../resolvers/searchCrossings';
import {
  validateJourney,
  validateDate,
} from '../../lib/validation/ferrySearchForm';

import { validateOutfitCrossing } from '../../lib/validation/availability';
import { validatePartyDobs } from '../../lib/partyHelpers';
import { dictionaryItem } from '../../hocs/withDictionary';

import SearchForm from '../SearchForm/SearchForm';
import SearchFormCrossings from '../SearchForm/SearchFormCrossings';
import SearchResultsCrossings from '../SearchResults/SearchResultsCrossings';
import SiteNightVoucherModal from '../SiteNightVoucher/SiteNightVoucherModal';

import SEARCH_CROSSINGS from '../SearchForm/graphql/searchCrossings.gql';
import GET_POPUP from '../PopUp/graphql/getPopUp';

import { ListingWrapper } from './Search.style';

import { findPossibleRoutes } from '../SearchForm/Crossings/CrossingsJourneyContainer';
import { returnPortName } from '../../lib/helpers/crossings';
import scrollToTop from '../../lib/scrollToTop';
import { dataLayerManager } from '../../lib/dataLayer';
import IbePropTypes from '../../IbePropTypes';
import Routes from '../../constants/routes';
import { getErrorElement } from '../../lib/helpers/availability';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

export function decodeAndParseQuery(payload) {
  const { search } = payload;

  const decoded = search ? util.decode64(search) : '{}';

  return search ? JSON.parse(decoded) : {};
}

export function encodeAndParseQuery(payload, query = {}) {
  return { ...query, search: util.encode64(JSON.stringify(payload)) };
}

export function findRoute(routes, { arrivalPort, departurePort }) {
  return routes.filter(route => (
    route.arrivalPort === arrivalPort && route.departurePort === departurePort
  ))[0];
}

export function handleRoute(itinerary, {
  arrivalPort,
  arrivalPortName,
  arrivalPortZone,
  departurePort,
  departurePortName,
  departurePortZone,
  routeCode,
  routeCodeWeb,
  supplierCode,
} = {}, alternativeRoute = false) {
  return {
    ...itinerary,
    arrivalPort,
    arrivalPortName,
    arrivalPortZone,
    departurePort,
    departurePortName,
    departurePortZone,
    routeCode,
    routeCodeWeb,
    supplierCode,
    crossingRouteCode: alternativeRoute ? routeCodeWeb : routeCode,
  };
}

export function getInboundJourney(routes, outboundItinerary) {
  const selectedRoute = routes.find(
    route => route.departurePortName === outboundItinerary.arrivalPortName &&
      route.arrivalPortName === outboundItinerary.departurePortName &&
      route.supplierCode === outboundItinerary.supplierCode,
  );

  return handleRoute(outboundItinerary, selectedRoute);
}

export function handleSameReturnRoute(routes, itinerary, route) {
  const inboundJourney = getInboundJourney(routes, route) || {};

  return handleRoute(itinerary, inboundJourney);
}

/**
 * SearchCrossings contains the UI for the Crossings search and summary
 */
class SearchCrossings extends PureComponent {
  static propTypes = {
    countBookings: PropTypes.number,
    data: PropTypes.shape({
      configuration: PropTypes.shape(IbePropTypes.configuration),
      siteNightVoucherPopUp: PropTypes.shape({
        open: PropTypes.bool,
      }),
    }),
    defaultMaxCrossingAccomQuantity: PropTypes.number.isRequired,
    error: PropTypes.shape({}),
    formRef: PropTypes.shape({}),
    goToTop: PropTypes.func,
    height: PropTypes.number,
    onClear: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onQueryReset: PropTypes.func.isRequired,
    onResultClick: PropTypes.func.isRequired,
    onResultMount: PropTypes.func.isRequired,
    onSubmitSearchForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onlyExtras: PropTypes.bool,
    ports: PropTypes.arrayOf(PropTypes.shape({})),
    query: PropTypes.shape({
      crossingId: PropTypes.string,
      inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
      outfit: PropTypes.shape(IbePropTypes.outfit),
      search: PropTypes.string,
    }).isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    result: PropTypes.shape({}),
    results: PropTypes.arrayOf(PropTypes.shape({})),
    showResults: PropTypes.bool.isRequired,
    toggleBasket: PropTypes.func.isRequired,
    onProductTypeMismatch: PropTypes.func,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
  };

  static defaultProps = {
    countBookings: null,
    data: {
      siteNightVoucherPopUp: { open: false },
    },
    error: null,
    formRef: {},
    goToTop: () => { window.scrollTo(0, 0); },
    height: 0,
    onlyExtras: false,
    ports: [],
    quote: null,
    result: null,
    results: [],
    routes: [],
    onProductTypeMismatch: null,
  };

  constructor(props) {
    super(props);

    const { inboundItinerary, outboundItinerary } = props.query;

    const oneWay = !outboundItinerary.routeCode
      ? false
      : !inboundItinerary.routeCode;

    const possibleRoutes =
      findPossibleRoutes(
        this.props.routes,
        outboundItinerary.arrivalPortName,
        outboundItinerary.departurePortName,
      );

    const allSuppliers = possibleRoutes.map(({ supplierCode }) => supplierCode);

    this.state = {
      allSuppliers,
      outfitDimensionsErrors: [],
      outfitChangeErrors: [],
      outfitErrors: [],
      partyErrors: [],
      payloadErrors: [],
      oneWay,
      searchError: null,
    };
  }

  handleChangeSearch = () => {
    scrollToTop();
    this.handleClear(this.props.query);
  };

  handleClear = (query = this.props.query) => {
    const encodedQuery = encodeAndParseQuery(query);

    return this.props.onClear(null, { ...encodedQuery, crossingId: null });
  }

  handleDataLayer = results => dataLayerManager.pushImpressions(
    dataLayerManager.category.CROSSINGS, results,
  );

  handleParseCrossingsPayload = payload => compose(
    parseCrossingsPayload,
    this.handleRouteCodes,
  )(payload);

  removePayloadErrorByType = (type) => {
    this.setState(prevState => ({
      ...prevState,
      payloadErrors: prevState.payloadErrors.filter(e => e.type !== type),
    }));
  };

  handleFormErrors = (errors, type) => {
    this.setState(prevState => {
      const updatedState = { ...prevState, [type]: errors };

      if (type === 'outfitDimensionsErrors' || type === 'outfitChangeErrors') {
        const combinedErrors = [
          ...(updatedState.outfitDimensionsErrors || []),
          ...(updatedState.outfitChangeErrors || []),
        ];
        const deduplicatedErrors = [
          ...new Map(
            combinedErrors.map(error => [JSON.stringify(error), error]),
          ).values(),
        ];
        updatedState.outfitErrors = deduplicatedErrors;
      }

      return updatedState;
    });
  };

  /**
   * When changes are made to show alternative route, one way and same return then we
   * want to update the inboundItinerary appropriately
   */
  handleAutogeneratedInbound = (query, isOneWay) => {
    const clonedQuery = cloneDeep(query);

    const selectedRoute = getInboundJourney(
      this.props.routes,
      clonedQuery.outboundItinerary,
    );

    const hasRouteCode =
      clonedQuery.outboundItinerary.crossingRouteCode || clonedQuery.outboundItinerary.routeCode;

    const crossingDateTime = clonedQuery.inboundItinerary
      ? clonedQuery.inboundItinerary.crossingDateTime : undefined;

    if (
      isOneWay ||
      !selectedRoute ||
      !hasRouteCode
    ) {
      return {
        ...clonedQuery,
        inboundItinerary: crossingDateTime ? { crossingDateTime } : {},
      };
    }

    // currently the selectedRoute will be a clone of the outbound, and will
    // use the ourbound date, we need to override with the inbound
    if (selectedRoute) {
      selectedRoute.crossingDateTime = crossingDateTime || '';
    }

    return {
      ...clonedQuery,
      inboundItinerary: {
        ...clonedQuery.inboundItinerary,
        ...selectedRoute,
      },
    };
  };

  handleRadioChange = (event) => {
    const { name } = event.target;
    const isOneWay = name === 'oneWay';
    const query = { ...this.props.query };

    // Handle situation when user is turning on and off return radio btn,
    // while journey is already picked.
    const updatedQuery = this.handleAutogeneratedInbound(query, isOneWay);
    updatedQuery.outboundItinerary.crossingDateTime = '';

    if (updatedQuery.inboundItinerary.crossingDateTime) {
      updatedQuery.inboundItinerary.crossingDateTime = '';
    }

    updatedQuery.sameReturnRoute = !isOneWay; // we default to true when oneWay === false

    this.handlePayloadUpdate(updatedQuery);

    this.setState({
      oneWay: isOneWay,
    });
  };

  handleRouteCodes = (payload) => {
    const { routes } = this.props;
    const payloadWithRouteCodes = handleRouteCodeLogic(routes, payload);
    return payloadWithRouteCodes;
  }

  handleCheckboxChange = (event, key = '') => {
    const { checked } = event.target;
    let query = { ...this.props.query };

    // Handle turning on and off same Route while journey is already picked.
    if (!this.state.oneWay) {
      query = this.handleAutogeneratedInbound(query);
    }
    query[key] = checked;

    this.handlePayloadUpdate(query);
  };

  handleSameReturnRouteChange = (event) => {
    const { checked } = event.target;
    const query = { ...this.props.query };

    const parsedQuery = this.handleAutogeneratedInbound(query);

    parsedQuery.sameReturnRoute = checked;

    this.handlePayloadUpdate(parsedQuery);
  }

  handleCompleted = (response) => {
    const { crossings } = response.results;
    const { crossingId } = this.props.query;

    // succeded but there is not crossingId in query
    if (!crossingId && response.results.errorCode !== 64) {
      this.props.onSuccess(crossings);
      return;
    }

    // succeded but only partialy
    if (!crossingId && response.results.errorCode === 64) {
      this.props.onError(
        { networkError: { result: { errorCode: response.results.errorCode } } },
        crossings,
      );
      return;
    }

    const decodedId = util.decode64(crossingId);
    const selectedCrossing = crossings.find(item => item.id === decodedId);

    // fully succeded
    this.props.onSuccess(crossings, () => {
      if (!selectedCrossing) return;
      this.props.onResultMount(selectedCrossing);
    });
  };

  handleError = (error) => {
    if (!error.networkError.result) {
      this.props.onError(error);
      return;
    }

    const { crossings } = error.networkError.result;
    const errorWithMessage = { ...error };

    const errorKey = crossings ? 'OneOrMoreProviderFailed' : 'SearchFailed';
    errorWithMessage.message = dictionaryItem(
      'SearchCrossings',
      'Error',
      errorKey,
    );

    this.props.onError(errorWithMessage, crossings);
  };

  handleReset = () => {
    this.setState(
      {
        payloadErrors: [],
        oneWay: true,
        searchError: null,
      },
      this.props.onQueryReset,
    );
  };

  handleResultClick = async (details) => {
    const clickAction = async () => {
      const crossingId = details ? util.encode64(details.id) : null;

      if (details) {
        const { ports, routes } = this.props;
        const outboundArrivalPortName =
          returnPortName(ports, details.outboundItinerary.arrivalPort);
        const outboundDeparturePortName =
          returnPortName(ports, details.outboundItinerary.departurePort);

        let inboundArrivalPortName = '';
        let inboundDeparturePortName = '';

        if (details.inboundItinerary) {
          inboundArrivalPortName =
            returnPortName(ports, details.inboundItinerary.arrivalPort);
          inboundDeparturePortName =
            returnPortName(ports, details.inboundItinerary.departurePort);
        }

        const selectedOutboundRoute = routes.find(route => (
          route.arrivalPortName === outboundArrivalPortName &&
          route.departurePortName === outboundDeparturePortName
        ));

        const selectedInboundRoute = routes.find(route => (
          route.arrivalPortName === inboundArrivalPortName &&
          route.departurePortName === inboundDeparturePortName
        ));

        // Update the query state in case alternative route is selected
        await this.handleOutboundPortChange(this.props.query, selectedOutboundRoute);
        await this.handleInboundPortChange(this.props.query, selectedInboundRoute);
      }

      await this.props.onQueryChange({ crossingId });
      /**
       * For now we have commented out the inclusion of the crossingId into the queryString,
       * This is to alleviate pressure on the query length. Phase 2 will require this
       * information to be added into the query but for now until we find a way of
       * reducing the length of the data we can simply not use this functionality.
       */
      // await updateRouterQuery(Routes.crossings, { crossingId });

      this.props.onResultClick(details);

      this.props.goToTop();
    };

    const showProductMismatchDialog =
      this.props.onProductTypeMismatch?.(this.props.quote)(true, clickAction);

    if (!showProductMismatchDialog) {
      await clickAction();
    }
  };

  handleSearchOnMount = (cb, payload) => {
    const clonedPayload = { ...payload };
    // Encode payload to use for form query
    const encodedPayload = encodeAndParseQuery(clonedPayload);

    updateRouterQuery(Routes.crossings, { ...encodedPayload, showResults: true });

    // Parse payload to use for search input
    const parsedPayload = this.handleParseCrossingsPayload(clonedPayload);

    return this.props.onSubmitSearchForm(
      () => cb({ variables: { input: parsedPayload } }),
      encodedPayload,
    );
  };

  handleOutboundPortChange = (payload, route) => {
    const clonedPayload = { ...payload };

    if (route) {
      clonedPayload.outboundItinerary = handleRoute(
        clonedPayload.outboundItinerary,
        route,
      );

      // If same return route is enabled then we want to set inbound as the same route.
      if (payload.sameReturnRoute) {
        // Set the same return route
        clonedPayload.inboundItinerary = handleSameReturnRoute(
          this.props.routes,
          clonedPayload.inboundItinerary,
          route,
        );
      } else {
        // Clear inbound itinerary and disable allowAlternativeRoutes
        clonedPayload.inboundItinerary = {};
      }
    }

    this.removePayloadErrorByType('outbound');
    this.handlePayloadUpdate(clonedPayload);
  };

  handleInboundPortChange = (payload, route) => {
    const clonedPayload = { ...payload };

    if (route) {
      clonedPayload.inboundItinerary = handleRoute(
        clonedPayload.inboundItinerary,
        route,
      );
    }

    this.removePayloadErrorByType('inbound');
    this.handlePayloadUpdate(clonedPayload);
  };

  handlePayloadUpdate = (data, cb) => this.props.onQueryChange(encodeAndParseQuery(data), cb);

  handleAllSuppliers = (allSuppliers) => {
    this.setState({
      allSuppliers,
    });
  }

  handleSubmit = async (payload) => {
    const partyErrors = [...this.state.partyErrors];

    let clonedPayload = { ...payload };

    await this.setState({
      payloadErrors: [],
      partyErrors: [],
    });

    // Handle return route
    if (clonedPayload.sameReturnRoute) {
      clonedPayload = this.handleAutogeneratedInbound(
        clonedPayload,
        this.state.oneWay,
      );
    }
    const parsedPayload = this.handleParseCrossingsPayload(clonedPayload);
    const { data: { configuration }, router, client } = this.props;

    // outfit business rules against outfit length/height requirements.
    // state.outFit errors contains accumulated errors AND inline form outfit errors.
    const outfitRequirementErrors = validateOutfitCrossing(
      parsedPayload.outfit, true, configuration, router, client,
    );

    const errors = [...this.validation(parsedPayload)];

    const dobsValid = validatePartyDobs(parsedPayload.partyMembers);

    if (!dobsValid) {
      partyErrors.push({ type: 'ages', message: dictionaryItem('SearchFormCrossings', 'PartyDobs', 'Error') });
    }

    // Combine validation errors from separate business logic areas.
    // Ensure unique through field type and message.
    const outfitTotalErrors = [...this.state.outfitErrors, ...outfitRequirementErrors];
    const uniqueOutfitErrors = [...uniqBy(outfitTotalErrors, error => `${error.message}-${error.type}`)];

    if (errors.length || partyErrors.length || uniqueOutfitErrors.length || !!getErrorElement()) {
      this.setState({
        payloadErrors: errors,
        outfitErrors: uniqueOutfitErrors,
        partyErrors: [...uniqBy(partyErrors, 'message')],
      });

      return;
    }

    const encodedPayload = encodeAndParseQuery(parsedPayload);

    const { router: { query } } = this.props;

    if (query.bookingWidget === 'true') {
      redirectFromWidget(Routes.crossings, {
        ...encodeAndParseQuery(clonedPayload),
        showResults: true,
      });
      return;
    }

    scrollToTop();

    updateRouterQuery(Routes.crossings, {
      ...encodedPayload,
      showResults: true,
    });
  };

  validation = payload => [
    ...validateJourney(
      payload.outboundItinerary,
      payload.inboundItinerary,
      !this.state.oneWay,
      payload.sameReturnRoute,
    ),
    ...validateDate(
      payload.outboundItinerary,
      payload.inboundItinerary,
      !this.state.oneWay,
    ),
  ];

  updatePartyMembers = () => {
    const { countBookings, query, quote } = this.props;

    if (!quote || !quote.partyMembers || !quote.partyMembers.length) {
      return query.partyMembers;
    }

    // If they have a booking in the basket
    if (countBookings) {
      // Otherwise return party members from existing quote
      return quote.partyMembers;
    }

    // Check if each party member has a corresponding member in
    // the quote and update with all details if not equal
    return query.partyMembers.map((member, index) => {
      const quoteMember = quote.partyMembers[index];

      if (quoteMember) {
        const equal = isEqual(member, quoteMember);

        if (!equal) {
          return {
            ...quoteMember,
            ...member,
          };
        }
      }

      return member;
    });
  }

  render() {
    const {
      defaultMaxCrossingAccomQuantity,
      error,
      query,
      routes,
      showResults,
      toggleBasket,
    } = this.props;

    const payload = { ...this.props.query };

    let quote = null;

    if (this.props.quote) {
      quote = { ...this.props.quote };
    }

    if (quote && quote.outfit && !!this.props.countBookings) {
      payload.outfit = { ...quote.outfit };
    }

    if (!payload.outfit.vehicleHeight) {
      payload.outfit.vehicleHeight = query.outfit.vehicleHeight;
    }

    if (!payload.outfit.towHeight) {
      payload.outfit.towHeight = query.outfit.towHeight;
    }

    payload.partyMembers = this.updatePartyMembers();

    return (
      <Fragment>
        <Mutation
          awaitRefetchQueries
          mutation={SEARCH_CROSSINGS}
          onCompleted={this.handleCompleted}
          onError={this.handleError}
          update={(...args) => {
            if (this.state.oneWay) return null;
            return searchCrossings(...args);
          }}
        >
          {(search, { loading }) => {
            if (showResults) {
              return (
                <ListingWrapper formContentHeight={this.props.height}>
                  <SearchResultsCrossings
                    defaultMaxCrossingAccomQuantity={defaultMaxCrossingAccomQuantity}
                    error={error}
                    handleClearAndExpand={this.handleClear}
                    isReturn={!this.state.oneWay}
                    loading={loading}
                    onChangeSearch={this.handleChangeSearch}
                    onImpression={this.handleDataLayer}
                    onMount={() => this.handleSearchOnMount(search, payload)}
                    onReset={this.handleReset}
                    onResultClick={this.handleResultClick}
                    query={payload}
                    quote={quote}
                    result={this.props.result}
                    results={this.props.results}
                    toggleBasket={toggleBasket}
                    type="crossings"
                    onProductTypeMismatch={this.props.onProductTypeMismatch}
                  />
                </ListingWrapper>
              );
            }

            return (
              <SearchForm formRef={this.props.formRef} router={this.props.router}>
                <SearchFormCrossings
                  countBookings={this.props.countBookings}
                  error={error}
                  getErrorsFromSearchForm={this.getErrorsFromSearchForm}
                  getInboundJourney={this.getInboundJourney}
                  handleAllSuppliers={this.handleAllSuppliers}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleFormErrors={this.handleFormErrors}
                  handleRadioChange={this.handleRadioChange}
                  handleJourneyDetails={this.handleJourneyDetails}
                  onError={this.handleError}
                  onInboundChange={this.handleInboundPortChange}
                  onOutboundChange={this.handleOutboundPortChange}
                  onSameReturnRouteChange={this.handleSameReturnRouteChange}
                  onSubmit={this.handleSubmit}
                  oneWay={this.state.oneWay}
                  onlyExtras={this.props.onlyExtras}
                  partyErrors={this.state.partyErrors}
                  payload={payload}
                  payloadErrors={this.state.payloadErrors}
                  quote={this.props.quote}
                  removePayloadErrorByType={this.removePayloadErrorByType}
                  routes={routes}
                  sameReturnRoute={payload.sameReturnRoute}
                  allSuppliers={this.state.allSuppliers}
                  outfitErrors={this.state.outfitErrors}
                  updatePayload={this.handlePayloadUpdate}
                />
              </SearchForm>
            );
          }}
        </Mutation>
        <SiteNightVoucherModal
          active={this.props.data.siteNightVoucherPopUp?.open}
          toggleBasket={toggleBasket}
        />
      </Fragment>
    );
  }
}

const SearchCrossingsContainer = ({ query, ...props }) => {
  const memoizedQuery = useMemo(() => decodeAndParseQuery(query), [query.search]);
  return <SearchCrossings {...props} query={memoizedQuery} />;
};

SearchCrossingsContainer.propTypes = {
  query: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default compose(
  withRouter,
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      data,
    }),
  }),
  graphql(GET_POPUP),
)(SearchCrossingsContainer);
