import React, { useEffect } from 'react';
import * as Portals from 'react-reverse-portal';
import { ThemeProvider } from 'styled-components';

import { GoogleProvider } from '../context/GoogleProvider';

import theme from '../styles/config/theme';

const DEFAULT_OPTIONS = {
  // The name of the portal node prop which is passed to the wrapped component
  portalNodeName: 'portalNode',
  // A function which runs to determine the props for the portal
  props: () => {},
  // A function which runs to determine whether we should render the portal
  skip: () => {},
};

const withReversePortal = (PortaledComponent, options) => (WrappedComponent) => {
  const optionsWithDefaults = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  const WrappedPortalComponent = (props) => {
    /**
     * We use reverse portals to render maps in this project to avoid a known
     * memory leak in the Google Maps JavaScript API. Google recommend re-using
     * the same map instance rather than mounting/unmounting and creating new
     * instances for this reason. A reverse portal allows us to move around
     * the same instance of the Map to avoid this issue.
     */
    const portalNode = React.useMemo(() => Portals.createHtmlPortalNode({
      // Attributes for the portal container
      attributes: {
        style: 'width: 100%',
        id: 'map-portal-container',
      },
    }), []);

    useEffect(() => () => {
      // Dispose of the portal when we unmount this component
      portalNode.unmount();
    }, []);

    const { portalNodeName } = optionsWithDefaults;

    // Run the props function provided to get the portal props
    const portalProps = optionsWithDefaults?.props?.(props) ?? {};

    /**
     * If we should skip rendering the portal, then just return the wrapped component
     * with a null portal. Example use-case for this is not rendering a map portal
     * on the booking widget.
     */
    const shouldSkipRender = optionsWithDefaults?.skip?.(props) ?? false;
    if (shouldSkipRender) return <WrappedComponent {...props} {...{ [portalNodeName]: null }} />;

    return (
      <>
        <ThemeProvider theme={theme}>
          <GoogleProvider>
            <Portals.InPortal node={portalNode}>
              <PortaledComponent {...portalProps} {...props} />
            </Portals.InPortal>
          </GoogleProvider>
        </ThemeProvider>
        {/* Attaches a reference to the portal node under the prop name provided */}
        <WrappedComponent {...props} {...{ [portalNodeName]: portalNode }} />
      </>
    );
  };

  return WrappedPortalComponent;
};

export default withReversePortal;
