import styled from 'styled-components';

import LoadingSpinnerComponent from '../ui/Loading/LoadingSpinner';
import StyledButtonBrand from '../ui/Button/ButtonBrand.style';
import { above, below } from '../../styles/libs';
import { Text } from '../SearchSummary/SearchSummary.style';

export default styled.div`
  position: sticky;
  top: 0;
  pointer-events: none;
  height: auto;
  z-index: 1;
`;

export const SearchFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
`;

export const LoadingSpinner = styled(LoadingSpinnerComponent)`
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
  margin-right: 0.75rem;
`;

export const Summary = styled.div`
  color: ${({ theme }) => theme.COLOR_WHITE};
  flex: 0 0 100%;

  ${({ theme }) => above(theme.TABLET)`
    flex: 2;
  `}
`;

export const SummaryTitle = styled.div`
  font-weight: 600;
`;

export const SummaryBody = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.5rem;

  ${({ theme }) => above(theme.DESKTOP)`
    font-size: 0.875rem;
    padding-right: 1rem;
  `}
`;

export const SummaryDates = styled.div`
  display: flex;

  & > div {
    padding-right: 1rem;
  }
`;

export const Actions = styled.div`
  padding-top: 1rem;
  flex: 0 0 100%;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 1;
    padding-top: 0;
  `}
`;

export const ActionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  margin: -0.25rem;
  gap: 16px;

  ${({ theme }) => below(theme.TABLET)`
    flex-direction: row;
    gap: unset;
  `}
`;

export const ActionsCol = styled.div`
  padding: 0 0.25rem;

  &:nth-child(n) {
    flex: 1;
  }
`;

export const Button = styled(StyledButtonBrand)`
  width: 100%;
  border: 0;
  flex: 1;

  &:nth-child(1) {
    margin-bottom: 0.5rem;
  }
`;

export const Count = styled.header`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  min-height: 3.5625rem;
  transform: translate3d(0, 0, 0);
  z-index: 1;
`;

export const Loader = styled.div`
  display: flex;
  height: 2.5rem;
  padding-left: 0.75rem;
  align-items: center;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const AnimationWrapper = styled.div`
  overflow: hidden;
  max-height: none;
  transition: max-height;
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
`;

export const AnimationHandler = styled.div`
  position: relative;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: ${({ visible, animationDirection, offsetTop }) => (
    visible
      ? `translate3d(0, ${offsetTop ? `${offsetTop}px` : 0}, 0)`
      : `translate3d(0, ${animationDirection === 'bottom' ? '0' : '-100%'}, 0)`
  )};
  transition: opacity ease-in-out 0.3s;
  transition-delay: ${({ visible }) => (visible ? '0.3s' : null)};
  pointer-events: auto;
`;

export const SearchText = styled(Text)`
  margin-top: 1.5rem;
  width: 100%;
  text-align: center;
`;
