import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';
import IbePropTypes from '../../../../IbePropTypes';
import { startTiming, types } from '../../../../lib/timings';

export default function withPickerHOC(WrappedComponent) {
  return class withPicker extends Component {
    static propTypes = {
      handleChange: PropTypes.func,
      datepicker: PropTypes.shape(IbePropTypes.datepicker).isRequired,
      fieldFocus: PropTypes.string,
      fullscreen: PropTypes.bool.isRequired,
      handleClose: PropTypes.func,
      handleDatepickerChange: PropTypes.func,
      handleSubmit: PropTypes.func,
      handleInputChange: PropTypes.func,
      open: PropTypes.bool,
      closingIgnoreList: PropTypes.arrayOf(PropTypes.string),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }

    static defaultProps = {
      fieldFocus: '',
      handleChange() { },
      handleClose() { },
      handleDatepickerChange() { },
      handleInputChange() { },
      handleSubmit() { },
      open: false,
      closingIgnoreList: [],
      startDate: '',
      endDate: '',
    }

    pickerRef = React.createRef();

    constructor(props) {
      super(props);

      if (!props.datepicker) return null;

      this.cellStyle = {};

      this.calendarsPosition = 0;

      this.calendarHeights = [];
      this.minHeight = 0;

      const picker = this.initPicker();

      this.state = { ...picker };

      // to be able to remove this, we need to bind in this way not () =>
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      if (!this.pickerRef) return;
      window.document.addEventListener('mousedown', this.handleClickOutside);
      window.document.addEventListener('touchstart', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
      const { datepicker } = this.props;

      // Don't update the picker if we're in two-month view, or dates haven't changed
      const noChange = isEqual(prevProps.datepicker, datepicker);
      if (datepicker.months > 1 || noChange) return;

      const picker = this.initPicker();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...picker });
    }

    componentWillUnmount() {
      window.document.removeEventListener('mousedown', this.handleClickOutside);
      window.document.removeEventListener('touchstart', this.handleClickOutside);
    }

    handleClickOutside(e) {
      if (!this.pickerRef.current) return;
      // Check if actually clicked outside
      const clickedOutside = this.hasClickedOutside(e);
      if (clickedOutside) {
        if (this.props.closingIgnoreList.some(item => item === String(e.target.name))) return;
        this.props.handleClose(e);
      }
    }

    getClientValues = (e) => {
      // handling for touch events
      // which are structured differently
      if (e.type === 'touchstart') return e.touches[0];
      return e;
    }

    hasClickedOutside = (e) => {
      // Check if we've clicked outside datepicker element
      // (factor in scrollbar)
      const { clientX, clientY } = this.getClientValues(e);
      const {
        left,
        right,
        top,
        bottom,
      } = this.pickerRef.current.getBoundingClientRect();
      const container = this.pickerRef.current.parentElement;
      const scrollbarWidth = container.offsetWidth - container.clientWidth;
      if (
        clientX > left &&
        clientX < (right + scrollbarWidth) &&
        clientY > top &&
        clientY < bottom
      ) {
        return false;
      }
      return true;
    }

    initPicker = () => {
      const { datepicker, fullscreen } = this.props;
      // TODO: Acknowlege start/end and min/max date rules
      const startDate = this.props.startDate ? moment(this.props.startDate) : moment();

      const amountOfPickers = fullscreen ? 4 : datepicker.months;

      const date = (
        moment(datepicker.minDate).isValid() &&
        moment(startDate).isBefore(moment(datepicker.minDate))
      ) ?
        moment(datepicker.minDate) :
        moment(startDate);

      const startOfMonth = date.startOf('month');
      const picker = this.handleMonthChange(date);

      picker.results = Array.from(Array(amountOfPickers).keys()).map(n => ({
        ...datepicker.getCalendar(moment(startOfMonth).add(n, 'month')),
      }));

      return picker;
    }

    setMinHeight = (prev, current) => {
      if (prev === current) return;
      this.setState({ calendarsHeight: current });
    }

    getMinHeight = (calendarHeights) => {
      const visibleCalandarsLength = this.props.datepicker.months;

      const reducer = (accumulator, currentValue) => (
        accumulator > currentValue ? accumulator : currentValue
      );

      const prevMinHeight = this.minHeight;
      this.minHeight = calendarHeights.slice(0, visibleCalandarsLength).reduce(reducer);
      this.setMinHeight(prevMinHeight, this.minHeight);
    }

    calculateCalendarsHeight = (height, date) => {
      const resultsLength = this.state.results.length;

      if (this.calendarHeights.length === resultsLength) {
        const isNextMonth = moment(date).isAfter([...this.state.results][0].calendarDate);

        if (isNextMonth) {
          this.calendarHeights.shift();
          this.calendarHeights.push(height);
        } else {
          this.calendarHeights.pop();
          this.calendarHeights.unshift(height);
        }
      } else {
        this.calendarHeights.push(height);
      }

      this.getMinHeight(this.calendarHeights);
    }

    handleChange = () => {
      this.props.handleChange();
    }

    handleClick = (date, isLastDayInOpenRange) => {
      const {
        datepicker,
        fieldFocus,
        handleInputChange,
        handleDatepickerChange,
      } = this.props;

      const { results } = this.state;

      // Update datepicker click value
      const propName = datepicker.onCellClick(date, fieldFocus, isLastDayInOpenRange) ? 'endDate' : 'startDate';
      datepicker[propName] = date;

      startTiming(types.CALENDAR);

      // TODO: Move into a datepicker.setDate function
      handleDatepickerChange([datepicker.startDate, datepicker.endDate]);
      handleInputChange(propName, date);

      const cell = results
        .map(calendar => calendar.cells
          .find(c => moment(date).isSame(c.date)))[0];

      if (cell) {
        cell.selected = true;
      }
    }

    handleLoadMore = (amount = 4) => {
      if (!amount) return;

      const { datepicker } = this.props;
      const { results } = this.state;

      if (amount > 0) {
        // Assign the start date or the last calendar date in the picker
        const month = results.slice(-1)[0].calendarDate || datepicker.startDate;

        Array.from(Array(amount).keys()).forEach((n) => {
          const nextMonth = moment(month).add(n + 1, 'month');
          results.push(datepicker.getCalendar(nextMonth));
        });
      } else if (amount < 0) {
        // Assign the start date or the last calendar date in the picker
        const month = results.slice(0)[0].calendarDate || datepicker.startDate;

        Array.from(Array(Math.abs(amount)).keys()).forEach((n) => {
          const previousMonth = moment(month).subtract(n + 1, 'month');
          results.unshift(datepicker.getCalendar(previousMonth));
        });
      }

      this.setState({ results });
    }

    handleMonthChange = (calendarMonth) => {
      const { datepicker } = this.props;
      const results = this.state ? this.state.results : [];

      const disablePrevMonth = moment(calendarMonth).isBefore(datepicker.minDate);
      const disableNextMonth =
        (calendarMonth?.month() >= datepicker.maxDate?.month() &&
          (calendarMonth?.year() === datepicker.maxDate?.year()));

      const response = {
        disablePrevMonth,
        disableNextMonth,
        results,
      };

      return response;
    }

    handlePrevMonth = () => new Promise((resolve) => {
      const { datepicker } = this.props;
      const results = { ...this.state.results };

      const prevMonth = moment(results[0].calendarDate).subtract(1, 'month');

      const response = this.handleMonthChange(prevMonth);

      response.results.unshift(datepicker.getCalendar(prevMonth));
      response.results.pop();

      this.setState({ ...response });

      resolve(response);
    })

    handleShowMonth = (month) => new Promise((resolve) => {
      const { datepicker } = this.props;
      const response = this.handleMonthChange(month);

      response.results.push(datepicker.getCalendar(month));
      response.results.shift();

      this.setState({ ...response });
      resolve(response);
    });

    handleNextMonth = () => new Promise((resolve) => {
      const { datepicker } = this.props;
      const { results } = this.state;

      const nextMonth = moment(results[results.length - 1].calendarDate).add(1, 'month');

      const response = this.handleMonthChange(nextMonth);

      response.results.push(datepicker.getCalendar(nextMonth));
      response.results.shift();

      this.setState({ ...response });
      resolve(response);
    })

    handleSubmit = () => {
      this.handleChange();
    }

    render() {
      const mapEvents = {
        calculateCalendarsHeight: this.calculateCalendarsHeight,
        handleChange: this.handleChange,
        handleClear: this.handleClear,
        handleClick: this.handleClick,
        handleClose: this.handleClose,
        handleLoadMore: this.handleLoadMore,
        handleNextMonth: this.handleNextMonth,
        handlePrevMonth: this.handlePrevMonth,
        handleSubmit: this.handleSubmit,
        handleShowMonth: this.handleShowMonth,
      };

      return (
        <WrappedComponent
          {...mapEvents}
          {...this.props}
          {...this.state}
          forwardedRef={this.pickerRef}
        />
      );
    }
  };
}
