import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../ui';
import { ModalContent, ModalClose } from '../ui/Modal';
import { StyledInfoIcon, InfoIconContainer } from './InfoIconWithModal.style';

import svgInfo from '../../static/images/icons/Info.svg';

export const InfoIcon = (props) => <StyledInfoIcon icon={svgInfo} {...props} />;

const InfoIconWithModal = ({ modalContent }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <InfoIconContainer data-testid="info-icon-container">
        <InfoIcon testingId="info-icon-openButton" onClick={() => setModalIsOpen(true)} />
      </InfoIconContainer>
      <Modal active={modalIsOpen} onClickOutside={() => setModalIsOpen(false)} overlay data-testid="info-icon-modal">
        <ModalContent data-testid="info-icon-modal_content">
          <ModalClose onClick={() => setModalIsOpen(false)} data-testid="info-icon-modal_close" />
          {modalContent}
        </ModalContent>
      </Modal>
    </>
  );
};

InfoIconWithModal.propTypes = {
  modalContent: PropTypes.element,
};

InfoIconWithModal.defaultProps = {
  modalContent: <></>,
};

export default InfoIconWithModal;
