import styled from 'styled-components';
import Text from '../ui/Text';

export { default as SearchInput } from '../ui/Form/Input/Input';

export default styled.div`
  display: block;
`;

export const DateInputWrapper = styled.div`
  position: relative;
`;

export const SearchIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ValidationText = styled(Text)`
  color: ${({ theme }) => theme.COLOR_DANGER};
  font-size: 14px;
  margin-top: 0.25rem;
`;
